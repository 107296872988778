
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "react-spinkit";
import { scrollTop } from "utils/Funciones";
import logo from "../../assets/img/logo-page.png";

import './loading.scss'

export const Loading = ({
    message = null,
    isLoading = false,
    img=logo,
    children

}) => {
    useEffect(() => {
        scrollTop();
    }, [])
    const { t } = useTranslation();
    if (isLoading || !children) {
        return (
            <div className="loading-page d-flex justify-content-center align-items-center">
                <div className="row">
                    <div className="col-12 d-flex justify-content-center mb-3">
                        <img src={img} width={550} alt='AIG' />
                    </div>
                    <div className="col-12 d-flex justify-content-center mt-5">
                        <Spinner fadeIn="none" color="#001871" name="ball-beat" />
                    </div>
                    <div className="col-12 d-flex justify-content-center mt-1">
                        <p className="loading-text">
                            {
                                (message) ? t(message) : (
                                    <>
                                        {t('I_FRONT_59')}
                                        < b > {t('I_FRONT_60')}</b>
                                    </>)
                            }
                        </p>
                    </div>
                </div>
            </div>
        );
    }
    return children
};

