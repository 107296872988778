import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { loadLanguages } from "redux/actions/uiActions";
import { store } from "store";

export const setUpI18next = () => {
  store.dispatch(loadLanguages(i18next));
  i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      detection: {
        order: ["htmlTag", "path", "cookie"],
        caches: ["cookie"],
      },
      initImmediate: false,
      interpolation: {
        escapeValue: false,
      },
    });
};
