/* eslint-disable no-eval */
import _ from "lodash";
import { createHook, createStore, defaults } from "react-sweet-state";
import { processRules } from "../utils/validaciones";
defaults.devtools = true;
/**
 * @typedef {object} InitialStateDynamicForm
 * @property {import('components/DynamicForm/typedefs').Seccion[]} secciones
 * @property {object} campos
 * @property {object} fields
 * @property {object|any} catalogo
 * @type {InitialStateDynamicForm}
 */
export const initialState = {
  fields: {},
  secciones: [],
  campos: {},
  catalogo: {},
  sources: {},
};

const processProps = (stringCode, resources) => {
  if (!stringCode || stringCode?.trim?.() === "") {
    return {};
  }
  try{
    const getProps = eval(stringCode);
    return getProps(resources);
  }catch(err){
    return {}
  }

};

/**
 * @typedef {object} DynamicFormActions
 *
 * @function
 * @param {InitialStateDynamicForm} state
 *
 */
const actions = {
  setState: (/** @type {InitialStateDynamicForm} */ state) => {
    return ({ setState }) => setState(state);
  },
  setSecciones: (
    /** @type {import('components/DynamicForm/typedefs').Seccion[]} */ secciones,
    /** @type {object} */ sources = {},
    /** @type {CallableFunction} */ onFinish = null
  ) => {
    return async ({ setState }) => {
      const fieldsMappeds = {};

      const camposMapper = (campo) => {
        if (Array.isArray(campo?.showOn?.fields)) {
          if (!campo.showOn?.custom) {
            campo.showOn.entries = {};
            campo.showOn.fields.forEach((field, index) => {
              const value = campo?.showOn?.values?.[index];
              campo.showOn.entries[field] = value;
            });
          } else if (
            typeof campo?.showOn?.custom === "string" &&
            campo?.showOn?.custom?.trim?.() !== "" &&
            campo?.showOn?.custom?.length !== 0
          ) {
            const funcion = eval(campo.showOn.custom);
            campo.showOn.custom = funcion(sources);
          }
        }

        if (campo.optionsSource) {
          campo.options = _.get(sources?.catalogo, campo.optionsSource, []);
        }

        const { subCampos, dialogHelpText = null, ...restCampo } = campo;
        const customProps = processProps(campo?.customProps, sources);

        const mapped = {
          ...restCampo,
          containerProps: {
            name: campo?.name,
            label: campo?.label,
            helpText: campo?.helpText,
            dialogHelpText,
          },
          ...customProps,
          rules: processRules(campo?.rules, sources),
          isShowing: true,
        };

        if (Array.isArray(subCampos) && subCampos.map && subCampos.length > 0) {
          const newSubCampos = subCampos?.map(camposMapper);
          mapped.subCampos = newSubCampos;
        }

        if (campo?.rules?.maxLengthFilter) {
          mapped.maxLength = campo?.rules?.maxLengthFilter.value;
        }
        if (campo?.rules?.keyFilter) {
          mapped.keyFilter = campo?.rules?.keyFilter.value;
        }
        fieldsMappeds[campo?.id] = mapped;
        return mapped;
      };

      const seccionMapper = (seccion) => {
        if (Array.isArray(seccion?.showOn?.fields)) {
          if (!seccion.showOn?.custom) {
            seccion.showOn.entries = {};
            seccion.showOn.fields.forEach((field, index) => {
              const value = seccion?.showOn?.values?.[index];
              seccion.showOn.entries[field] = value;
            });
          } else if (
            typeof seccion?.showOn?.custom === "string" &&
            seccion?.showOn?.custom?.trim?.() !== "" &&
            seccion?.showOn?.custom?.length !== 0
          ) {
            const funcion = eval(seccion.showOn.custom);
            seccion.showOn.custom = funcion(sources);
          }
        }
        return {
          ...seccion,
          secciones: seccion?.secciones?.map?.(seccionMapper) || [],
          campos: seccion.campos.map(camposMapper),
        };
      };

      const mappedSecciones = secciones?.map(seccionMapper);

      await setState({
        secciones: mappedSecciones,
        campos: fieldsMappeds,
        fields: fieldsMappeds,
        catalogo: sources?.catalogo,
        // sources: sources,
      });
      onFinish && onFinish();
    };
  },
};
const DynamicFormStore = createStore({
  name: "DynamicFormState",
  initialState: initialState,
  actions: actions,
});

const hook = createHook(DynamicFormStore);

/**
 *
 * @returns {InitialStateDynamicForm}
 */
export const useDynamicForm = () => {
  const [state, actionsHook] = hook();

  return {
    ...actionsHook,
    ...state,
  };
};
