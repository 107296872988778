import { PERIOCIDADES_PAGO_LABELS } from "constants/P2P";
import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
/**
 *
 * @param {{total?:string|number|any, label?:string|any, periocidad?:"unico"|"suscripcion"|undefined}} props
 * @returns
 */
const InfoCompra = (props) => {
  const { label, total, periocidad } = props;
  const { t } = useTranslation();
  return (
    <div className='ctn'>
      <div className='circulo'>
        <div className='ctn-interior'>
          <div className='check'>
            <strong className='text-white' style={{ fontSize: "20px" }}>
              ✓
            </strong>
          </div>
          <div className='center-text'>
            <div className='d-flex flex-column justify-content-around h-100'>
              <h4 className='font-weight-bold' style={{ fontWeight: "bold" }}>
                {label}
              </h4>
              {total && (
                <h5 className='font-weight-bold' style={{ marginTop: "10px" }}>
                  $ {total} USD
                </h5>
              )}
              {periocidad && (
                <h6 className='text-primary' style={{ fontSize: "1rem" }}>
                  {t(PERIOCIDADES_PAGO_LABELS[periocidad])}
                </h6>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

InfoCompra.defaultProps = {
  label: "Total",
};
export default InfoCompra;
