import axios from "axios";
import { encriptarTramaAES } from "utils/Encriptar";
import { decryptResponseAES, libEncrypAES } from "utils/Encriptar/crypto";
import { desencriptarTramaAES } from "utils/Encriptar/Encriptar";
import { empresa, plataforma } from "./urls";

export const apiGet = (url) => {
  try {
    return axios.get(url, {
      headers: { "Accept-Language": localStorage?.getItem?.("lng") || "us" },
    });
  } catch (error) {}
};

export const apiPost = (url, param, json = false) => {
    try {
        const headers = { "Accept-Language": localStorage?.getItem?.('lng') || 'us' }
        if (json) {
            headers['Content-Type'] = 'application/json'
        }
        console.log(headers);
        return axios.post(url, (json) ? param : JSON.stringify(param), {
            params: {
                empresa: 'AIGPR'
            }, headers
        })

    } catch (error) {}
};

// Funcion para encriptar datos

export const realizarEncriptacionAes = (param) => {
  let dataEncriptar = JSON.stringify(param);
  let encrypted64Login = encriptarTramaAES(dataEncriptar);

  encrypted64Login = Buffer.from(JSON.stringify(encrypted64Login)).toString("base64");

  return {
    empresa,
    plataforma,
    origen: "front",
    Data: encrypted64Login,
  };
};

export const realizarDesEncriptacionAes = async (data) => {
  //Recibir Dato Encriptados
  if ((data.iv !== undefined) & (data.Data !== undefined) & (data.key !== undefined)) {
    data = await desencriptarTramaAES(data.Data, data.key, data.iv);
  }
  return data;
};

export const consumirServicioEncirptacion = async (
  url,
  param,
  encrypt = true,
  json = false
) => {
  try {
    if (encrypt) {
      param = realizarEncriptacionAes(param);
    }
    const respApi = await apiPost(url, param, json);
    const resp = encrypt
      ? await realizarDesEncriptacionAes({ ...respApi.data })
      : decryptResponseAES(respApi?.data);
    if (!!resp?.transaccion && resp.transaccion === true) {
      return resp;
    } else {
      return {
        ...resp,
        errorDescripcion:
          resp?.errorDescripcion?.errorDescripcion ||
          resp?.errorDescripcion ||
          "Error al Consultar",
        transaccion: false,
      };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return {
        ...error?.response?.data,
        errorDescripcion:
          error?.response?.data?.errorDescripcion?.errorDescripcion ||
          error?.response?.data?.errorDescripcion ||
          "Error al Consultar",
        transaccion: false,
      };
    }
    return {
      errorDescripcion: "Error al Consultar",
      transaccion: false,
    };
  }
};

export const consumirServicioEncriptacionGCM = async (url, param, json = true, encrypt=true) => {

    try {
        param = await libEncrypAES(param, encrypt);
        const respApi = await apiPost(url, param ,json);
        const resp = decryptResponseAES(respApi?.data);
        if (!!resp?.transaccion && resp.transaccion === true) {
            return resp;
        } else {
            return {
                errorDescripcion: "Error al Consultar",
                transaccion: false,
                ...resp,
            };
        }
    } catch (error) {
        console.log(error);
        return {
            errorDescripcion: "Error al Consultar",
            transaccion: false,
            error,
        };
    }
};
