import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card } from "primereact/card";
import { HeaderLogo } from "components/Header/HeaderLogo";
import { consumirServicioEncriptacionGCM } from "services/api";
import { urlGetProducts } from "services/urls";

import "./body-cotizador.scss";
import {
  loadingProducts,
  loadProducts,
  stopLoadingProducts,
} from "redux/actions/productActions";
import { Loading } from "../Loading/Loading";
import { useTranslation } from "react-i18next";
import { Col, Row, Button } from "react-bootstrap";

export const BodyCotizador = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { products, isLoading } = useSelector((state) => state.product);
  const { dataUsuario } = useSelector((state) => state.login);
  const [categories, setCategories] = useState([]);
  const [categorySelected, setCategorySelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const shadowStyle = {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  };
  const inactiveButtonStyle = {
    backgroundColor: '#dde4eb',
    color: 'black'
  };
  const stylesButton = { width: '260px', height: '64px', marginRight: '10px', marginBottom: '10px', border: 0, borderRadius: 0, fontSize: '14px' }

  useEffect(() => {
    if (dataUsuario?.correo) {
      setLoading(true);
      consumirServicioEncriptacionGCM(urlGetProducts, { cliEmail: dataUsuario?.correo })
        .then((resp) => {

          if(resp.productos && resp.categorias){

            const prods = JSON.parse(resp?.productos);
            const categ = JSON.parse(resp?.categorias);
            dispatch(loadProducts(prods));
            setCategories(categ);
            if (categ[0]) {
              setCategorySelected(categ[0].id);
            }
            setLoading(false);

          }
         
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  const header = (data) => (
    <div className='text-center card-img-cotizador'>
      <img
        alt='Card'
        src={data.urlImg}
        style={{ width: "100%", minHeight: "300px" }}
        loading='eager'
      />
    </div>
  );

  const redirect = (item) => () => {
    if (item.activo && item.urlRedireccion) {
      history(item.urlRedireccion);
    }
  };

  return (
    <>
      <Loading isLoading={loading} message={"Loading..."}>
        <main className='container-fluid px-5 pt-3 pb-0 mb-5 body-home'>
          <HeaderLogo />
          <div className='row justify-content-center mt-3'>

            <Row>
              <Col md={12}>

                {categories.map(tab => (
                  <Button
                    key={tab.id}
                    color={categorySelected === tab.id ? 'primary' : 'secondary'}
                    active={categorySelected === tab.id}

                    size="lg"
                    style={{
                      ...(categorySelected === tab.id ? {} : inactiveButtonStyle),
                      ...shadowStyle, ...stylesButton
                    }}
                    onClick={() => setCategorySelected(tab.id)}
                  >
                    {tab.label}
                  </Button>
                ))}

              </Col>

            </Row>

            {products.filter(p => (p.activo == true && p.categoria == categorySelected)).map((e, index) => (
              <div className='col-11 col-sm-11 col-md-5 col-lg-4 my-3' key={index}>
                <Card
                  style={{ backgroundColor: "#C7DBF4", padding: "0" }}
                  className='align-items-center'
                  header={header(e)}
                  footer={
                    <div className='d-flex justify-content-end'>
                      <button
                        className='btn-body-cotizador text-uppercase'
                        onClick={redirect(e)}>
                        {t(e.nombreBoton)}
                      </button>
                    </div>
                  }>
                  <div
                    className='mx-1 mx-lg-3 text-darkblue'
                    style={{ height: "3.5rem" }}>
                    <h3 style={{ fontSize: "30px" }}>{e.titulo}</h3>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </main>
      </Loading>

    </>
  );
};
