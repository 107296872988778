import classNames from 'classnames'
import { SimpleContainer } from 'components/Inputs/containers'
import { DropDownFilter } from 'components/Inputs/inputs'
import { format } from 'helpers/functions'
import { CabeceraPlanes, CoberturaXL, stripColors } from 'pages/GeneradorPaginas/components/ProductOptions/ProductOptions'
import React, { useEffect, useState } from 'react'
import { ButtonGroup } from 'react-bootstrap'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { sortNumberStrings } from 'utils/functions'


const estructuraPlan =
{
    option1: {
        planNombre: 'Option 1',
        planCodigoCore: 'option1',
        revenue: '',
        premium: 0,
        assessment: 0
    }
}



export const DynamicPlans = ({ plans: planes = estructuraPlan, catalogoService, value = null, nameValues = [], namePlan = 'Option', codePlan = 'option', nameLicense = 'Licensed Pharmacists', numLic, infoLawyers=null }) => {



    const [plans, setPlans] = useState([]);
    const [catalogo, setCatalogo] = useState({});
    const { control, reset, getValues, setValue } = useFormContext();
    const [revenue, setRevenue] = useState(null)

    const { assessmentPercentage } = useSelector((state) => state.ui);
    const { producto } = useSelector((state) => state.product);
    const { t } = useTranslation();

    useEffect(() => {
        let arrPlans = Object.values(planes || estructuraPlan);
        reset(planes || estructuraPlan)
        if (arrPlans.length > 6) {
            arrPlans = Object.values(estructuraPlan);
            reset(estructuraPlan)
        }
        arrPlans = arrPlans.map((plan) => {
            if (plan !== null) {
                return {
                    ...plan,
                    optionsDeductible: catalogoService?.deductible?.filter(deductible => deductible.limitsLiability === plan?.limits?.value)?.filter(deductible => {
                        return !arrPlans.some(plan1 => plan?.planCodigoCore !== plan1?.planCodigoCore && plan1?.limits?.value === deductible?.limitsLiability && deductible?.value === plan1?.deductible?.value)
                    }).map(ded => ({
                        label: ded.label,
                        value: ded
                    })),

                }
            }
            return plan
        });
        const relleno = 5 - arrPlans.length;
        for (let index = 0; index < relleno; index++) {
            arrPlans.push(null);
        }
        console.log('prueba', arrPlans)
        setPlans(arrPlans);
        setRevenue(catalogoService && catalogoService.carritoQuestion && catalogoService.carritoQuestion.revenue && catalogoService.carritoQuestion.revenue.label ? catalogoService.carritoQuestion.revenue.label : null)
    }, [planes, reset, getValues, catalogoService?.deductible, catalogoService?.limitsLiability, catalogo])


    useEffect(() => {
        setCatalogo(catalogoService);
    }, [catalogoService])


    const ordenarLista = (lista)=>{
        return lista.sort((a, b) => {
            const labelA = parseInt(a.label.replace('$', '')); // Convertimos el valor numérico de string a entero
            const labelB = parseInt(b.label.replace('$', ''));
            return labelA - labelB; // Ordenamos de menor a mayor
          });
    }


    const deletePlan = (planCodigoCore) => {
        const planDeleted = plans.find(plan => plan?.planCodigoCore === planCodigoCore);
        setPlans(plans.map((plan, index) => {
            if (planCodigoCore === plan?.planCodigoCore) {
                let valuesForm = { ...getValues() };
                delete valuesForm?.[`${codePlan}${index * 1 + 1}`]
                let arrForm = {};
                Object.keys({ ...valuesForm }).forEach((key, indexKey) => {
                    arrForm[`${codePlan}${indexKey * 1 + 1}`] = {
                        ...valuesForm[key],
                        planNombre: `${namePlan} ${indexKey * 1 + 1}`,
                        planCodigoCore: `${codePlan}${indexKey * 1 + 1}`
                    }
                })
                reset({ ...arrForm })
                return null
            }
            if (plan === null) return null;
            let options = plan?.optionsDeductible;
            if (planDeleted?.limits?.value === plan?.limits?.value && planDeleted?.deductible?.limitsLiability === plan?.limits?.value) {
                options?.push({ label: planDeleted?.deductible?.label, value: planDeleted?.deductible })
            }


            return {
                ...plan,
                optionsDeductible: options
            };
        }).sort().map((plan, index) => {
            if (!plan) {
                return null
            }
            return {
                ...plan,
                planNombre: `${namePlan} ${index * 1 + 1}`,
                planCodigoCore: `${codePlan}${index * 1 + 1}`
            }

        })
        )

    }


    const modifyPlan = (value, type, id) => {

        if (type === 'limits') {
            const modifiedPlan = plans.find(plan => plan?.planCodigoCore === id);
            setPlans(plans.map(plan => {
                const optionsDeductible = catalogo?.deductible.filter(deductible => deductible.limitsLiability === value?.value).filter(deductible => {
                    return !plans.some(plan => plan?.planCodigoCore !== id && plan?.limits?.value === deductible?.limitsLiability && deductible?.value === plan?.deductible?.value)
                }).map(ded => ({
                    label: ded.label,
                    value: ded
                }))
                //if (optionsDeductible?.length === 0) {
                //    setAvailableLimits(availableLimits.filter(lim => lim.value !== value?.value))
                //} else {
                //  if (!availableLimits.some(lim => lim.value === value?.value)) {
                //    setAvailableLimits(...availableLimits, plan.limits)
                // }
                //}
                if (id === plan?.planCodigoCore) {
                    setValue(`${modifiedPlan.planCodigoCore}.deductible`, null);
                    return {
                        ...plan,
                        limits: value,
                        optionsDeductible
                    }
                }
                if (plan !== null && modifiedPlan?.limits?.value !== value?.value && modifiedPlan?.limits?.value && plan?.limits?.value && plan?.optionsDeductible.length > 0) {
                    let optionsChanged = plan?.optionsDeductible;
                    if (optionsChanged.some(optC => optC === { label: modifiedPlan?.deductible?.label, value: modifiedPlan?.deductible })) optionsChanged?.push({ label: modifiedPlan?.deductible?.label, value: modifiedPlan?.deductible })
                    return {
                        ...plan,
                        optionsDeductible: optionsChanged
                    }
                }
                if (plan !== null && plan?.limits?.value === value?.value && plan?.deductible) {
                    return {
                        ...plan,
                        optionsDeductible: [...optionsDeductible, { label: plan.deductible.label, value: plan.deductible }]
                    }
                }

                return plan
            }))
        } else {
            const posicion = plans.findIndex(plan => plan?.planCodigoCore === id);

            var premium = catalogo?.premium?.find(premium => {
                return premium.limitsLiability === getValues(`${codePlan}${posicion + 1}.limits`).value && premium.deductible === value?.value
            })?.premium || 0

            if (revenue) {

                premium = catalogo?.premium?.find(premium => {
                    return premium.limitsLiability === getValues(`${codePlan}${posicion + 1}.limits`).value && premium.deductible === value?.value && premium.revenue == revenue
                })?.premium || 0

            }

            

            if(infoLawyers){

                let lawyersOne = null
                var lawyersOneList = infoLawyers.lawyersOne.map(lawyer=>{
                    return {
                        ...lawyer,
                        fvLimitsOfLiability: lawyer.fvLimitsOfLiability.replace('/','')
                    }
                })
                lawyersOne = lawyersOneList.find(premium => {
                    return premium.fvLimitsOfLiability === getValues(`${codePlan}${posicion + 1}.limits`).value && premium.fvDeductible === value?.value
                })

                if(infoLawyers.bLawyers.number_OC > 0){
                    premium = premium + (lawyersOne.fvPremium*0.4)*infoLawyers.bLawyers.number_OC
                }

                if(infoLawyers.dLawyers.Real_estate > 0){

                    var intREexposure = 0

                    if(infoLawyers.dLawyers.Real_estate >= 11 && infoLawyers.dLawyers.Real_estate <=15){
                        intREexposure =15
                    }
                    if(infoLawyers.dLawyers.Real_estate >= 16 && infoLawyers.dLawyers.Real_estate <=20){
                        intREexposure =20
                    }
                    if(infoLawyers.dLawyers.Real_estate >= 21 && infoLawyers.dLawyers.Real_estate <=25){
                        intREexposure =25
                    }
                    if(infoLawyers.dLawyers.Real_estate >= 26 && infoLawyers.dLawyers.Real_estate <=30){
                        intREexposure =30
                    }
                    if(infoLawyers.dLawyers.Real_estate >= 31 && infoLawyers.dLawyers.Real_estate <=35){
                        intREexposure =35
                    }
                    if(infoLawyers.dLawyers.Real_estate >= 36 && infoLawyers.dLawyers.Real_estate <=40){
                        intREexposure =40
                    }
                    if(infoLawyers.dLawyers.Real_estate >= 41 && infoLawyers.dLawyers.Real_estate <=45){
                        intREexposure =45
                    }

                    if(infoLawyers.dLawyers.Real_estate >= 46 && infoLawyers.dLawyers.Real_estate <=50){
                        intREexposure =50
                    }

                    premium = (Math.round(premium + (premium * (intREexposure / 100)), 0))
                
                }

            }
            let optToShowAgain = null;
            optToShowAgain = plans.find(plan => plan?.planCodigoCore === id)?.deductible;
            if (optToShowAgain?.value === value?.value && getValues(`${codePlan}${posicion + 1}.limits`)?.value === optToShowAgain?.limitsLiability) { return; }
            const arr = plans.map((pl) => {
                if (pl?.planCodigoCore === id) {
                    return {
                        ...pl,
                        premium,
                        deductible: value,
                        revenue: revenue,
                        assessment: Math.round(premium * assessmentPercentage)
                    }

                }
                if (pl?.limits?.value === getValues(`${codePlan}${posicion + 1}.limits`)?.value) {
                    if (getValues(`${codePlan}${posicion + 1}.deductible`)?.value === value?.value) {
                        const options = pl?.optionsDeductible?.filter(ded => { return value?.value !== ded?.value?.value })
                        if (optToShowAgain) { options.push({ label: optToShowAgain.label, value: optToShowAgain }) }
                        return {
                            ...pl,
                            optionsDeductible: options
                        }
                    }


                }
                return pl
            })
            setPlans(arr);
            reset({
                ...getValues(),
                [`${codePlan}${posicion * 1 + 1}`]: {
                    ...getValues(`${codePlan}${posicion * 1 + 1}`),
                    premium,
                    revenue: revenue,
                    assessment: Math.round(premium * assessmentPercentage)
                }
            })
        }
    }

    const addPlan = () => {
        const arr = [...plans];
        const posicion = plans.findIndex(plan => plan === null);
        arr[posicion] = {
            planCodigoCore: `${codePlan}${posicion + 1}`,
            planNombre: `${namePlan} ${posicion + 1}`,
            premium: 0,
            revenue: revenue,
            assessment: 0,

        }
        setPlans([...arr]);
        reset({
            ...getValues(),
            [`${codePlan}${posicion * 1 + 1}`]: {
                planCodigoCore: `${codePlan}${posicion * 1 + 1}`,
                planNombre: `${namePlan} ${posicion * 1 + 1}   `,
                premium: 0,
                revenue: revenue,
                assessment: 0,
            }
        })
    }

    return (
        <>
            <div className="row col-12 d-flex flex-wrap justify-content-center px-2 py-1 d-lg-block">
                <div className={`container-planes-dynamic position-relative`}>
                    <div className='position-absolute text-aigpr font-20px m-2 mt-3'>
                        Coverage
                    </div>
                    <div className="d-flex flex-column justify-content-end font-source-sans-regular text-aigpr font-19px col text-left mb-3 border-bottom py-1" style={{color:'black'}}>
                        Premium
                    </div>
                    {plans?.map((opcion, index) => (
                        <React.Fragment key={`product-plan-${index}`}>{(opcion) ?
                            <div
                                className={classNames('plan-pago-container position-relative', {
                                    'active-plan': value === opcion?.planCodigoCore,
                                    'card-plan': value !== opcion?.planCodigoCore,
                                })}

                            >
                                <CabeceraPlanes
                                    opcion={opcion}
                                    stripColor={stripColors[plans.indexOf(opcion) || 0]}
                                />
                                {index !== 0 &&
                                    <button onClick={() => { deletePlan(opcion.planCodigoCore) }} className='button-delete'>
                                        <b>
                                            <i className="pi pi-times" />
                                        </b>
                                    </button>
                                }

                            </div> : <div >{index > 0 && plans[index - 1] &&
                                <button onClick={() => addPlan()} className='button-add text-aigpr d-flex flex-wrap align-items-center shadow p-3 mb-5 bg-white rounded'>
                                    <div className='add-icon mx-2'><b>+</b></div> ADD OPTION
                                </button>}
                            </div>}

                        </React.Fragment>
                    ))}

                    <div className="d-flex align-items-center font-source-sans-regular h-100  font-19px col text-left py-3 border-bottom">
                        {nameValues[0]}
                    </div>
                    {plans.map((opcion, j) => (
                        (opcion) ? <div
                            key={`opci0${j}`}
                            className={`d-flex justify-content-center flex-column px-3 py-3 ${value === opcion?.planCodigoCore
                                ? 'plan-activo card-border-lateral-activo '
                                : 'card-border-lateral'
                                }`}
                            style={{
                                borderTop: '0px !important',
                                borderBottom: '0px !important',
                            }}
                        //onClick={() => onChange(opcion?.planCodigoCore)}
                        >
                            <div className="col-12 d-flex justify-content between">
                                <SimpleContainer name={`${opcion?.planCodigoCore}.limits`}>
                                    <DropDownFilter
                                        name={`${opcion?.planCodigoCore}.limits`}
                                        isClearable={false}
                                        options={sortNumberStrings(catalogo?.limitsLiability?.map(lim => ({ label: lim.label, value: lim }))) || []}
                                        onChangeFn={(value) => { modifyPlan(value, 'limits', opcion?.planCodigoCore) }}
                                        rules={{ required: 'Please select' }}
                                        fontSizeClass='font-50p'
                                    />
                                </SimpleContainer>
                            </div>
                        </div> : <div key={`divSpace${j}`}></div>
                    ))}
                    <div className="d-flex align-items-center font-source-sans-regular font-19px h-100 col text-left mb-3 border-bottom">
                        <div className='col-12'>
                            {nameValues[1]}
                        </div>
                    </div>
                    {plans.map((opcion, j) => {


                        var optionsDeductible = opcion?.optionsDeductible


                        if (opcion && revenue) {


                            var aux = optionsDeductible?.filter(option => option.value?.revenue == revenue)



                            if (aux) {

                                aux = aux.sort(function (a, b) {
                                    var keyA = a.value.value
                                    var keyB = b.value.value
                                    // Compare the 2 dates
                                    if (keyA < keyB) return -1;
                                    if (keyA > keyB) return 1;
                                    return 0;
                                });
                              
                                optionsDeductible = aux
                            }


                        }


                        return (opcion) ? <div
                            key={`opci1${j}`}
                            className={`d-flex justify-content-center px-3 py-3 ${value === opcion?.planCodigoCore
                                ? 'plan-activo card-border-lateral-activo '
                                : 'card-border-lateral'
                                }`}
                            style={{
                                borderTop: '0px !important',
                                borderBottom: '0px !important',
                            }}
                        >
                            <SimpleContainer name={`${opcion.planCodigoCore}.deductible`}>
                                <DropDownFilter
                                    name={`${opcion.planCodigoCore}.deductible`}
                                    options={optionsDeductible ? ordenarLista(optionsDeductible) : []}
                                    isClearable={false}
                                    onChangeFn={(value) => { modifyPlan(value, 'deductible', opcion?.planCodigoCore) }}
                                    rules={{ required: 'Please select' }}
                                    fontSizeClass='font-50p'
                                />
                            </SimpleContainer>
                        </div> : <div key={`divSpace${j}`}></div>
                    })}
                    {(
                        nameLicense != 'Licensed Accountants' && 
                        nameLicense != 'Licensed Property Manager' && 
                        nameLicense != 'Licensed Insurance Agents' && 
                        nameLicense != 'Licensed Real Estate' &&
                        nameLicense != 'Licensed Travel Agents'  &&
                        nameLicense != 'Licensed Management Consultants' &&
                        nameLicense != 'Licensed lawyers'
                        
                        ) &&
                        <div className="d-flex align-content-center font-source-sans-regular font-19px col text-left py-3 border-bottom">
                            <div className='col-12'>
                                {nameLicense}
                            </div>
                        </div>
                    }

                    {(
                        nameLicense != 'Licensed Accountants' && 
                        nameLicense != 'Licensed Property Manager' && 
                        nameLicense != 'Licensed Insurance Agents' && 
                        nameLicense != 'Licensed Real Estate' &&
                        nameLicense != 'Licensed Travel Agents'&&
                        nameLicense != 'Licensed Management Consultants' &&
                        nameLicense != 'Licensed lawyers'
                        
                        ) && plans.map((opcion, j) => (
                        (opcion) ? <div
                            key={`opci2${j}`}
                            className={`d-flex justify-content-center px-3 py-3 ${value === opcion?.planCodigoCore
                                ? 'plan-activo card-border-lateral-activo '
                                : 'card-border-lateral'
                                }`}
                            style={{
                                borderTop: '0px !important',
                                borderBottom: '0px !important',
                            }}
                        // onClick={() => onChange(opcion?.planCodigoCore)}
                        >
                            <div className="font-source-sans-regular font-19px text-lg-center text-left">{`${numLic}`}</div>
                        </div> : <div key={`divSpace${j}`}></div>
                    ))}

                    {revenue &&
                        <div className="d-flex align-content-center font-source-sans-regular font-19px col text-left py-3 border-bottom">
                            <div className='col-12'>
                                {t('I_FRONT_81')}
                            </div>
                        </div>
                    }

                    {revenue && plans.map((opcion, j) => (
                        (opcion) ? <div
                            key={`opci2${j}`}
                            className={`d-flex justify-content-center px-3 py-3 ${value === opcion?.planCodigoCore
                                ? 'plan-activo card-border-lateral-activo '
                                : 'card-border-lateral'
                                }`}
                            style={{
                                borderTop: '0px !important',
                                borderBottom: '0px !important',
                            }}
                        // onClick={() => onChange(opcion?.planCodigoCore)}
                        >
                            <div className="font-source-sans-regular font-19px text-lg-center text-left">{`${revenue}`}</div>
                        </div> : <div key={`divSpace${j}`}></div>
                    ))}

                    <div className="d-flex align-content-center font-source-sans-regular font-19px col text-left py-3 border-bottom">
                        <div className='col-12'>
                            {nameValues[2]}
                        </div>
                    </div>
                    {plans.map((opcion, j) => (
                        (opcion) ? <div
                            key={`opci2${j}`}
                            className={`d-flex justify-content-center px-3 py-3 ${value === opcion?.planCodigoCore
                                ? 'plan-activo card-border-lateral-activo '
                                : 'card-border-lateral'
                                }`}
                            style={{
                                borderTop: '0px !important',
                                borderBottom: '0px !important',
                            }}
                        // onClick={() => onChange(opcion?.planCodigoCore)}
                        >
                            <div className="font-source-sans-regular font-19px text-lg-center text-left">{`${format(opcion?.premium)}`}</div>
                        </div> : <div key={`divSpace${j}`}></div>
                    ))}
                    <div className="d-flex align-content-center font-source-sans-regular font-19px col text-left py-3">
                        <div className='col-12'>
                            {nameValues[3]}
                        </div>
                    </div>
                    {plans.map((opcion, j) => (
                        (opcion) ? <div
                            key={`opci3${j}`}
                            className={`d-flex justify-content-center px-3 py-3 ${value === opcion?.planCodigoCore
                                ? 'plan-activo card-border-lateral-activo '
                                : 'card-border-lateral border-bottom'
                                }`}
                            style={{
                                borderTop: '0px !important',
                                borderBottom: '0px !important',
                            }}
                        //onClick={() => onChange(opcion?.planCodigoCore)}
                        >
                            <div className="font-source-sans-regular font-19px text-lg-center text-left">
                                <Controller
                                    control={control}
                                    name={`${opcion.planCodigoCore}.assessment`}
                                    render={({ field: { value } }) => (
                                        <label className='info-plan' >{`${format(opcion.assessment)}`}</label>
                                    )}
                                />

                            </div>
                        </div> : <div key={`divSpace${j}`}></div>
                    ))}

                    <div></div>
                </div>

            </div>
            <div className="d-lg-none p-3">
                <ButtonGroup className="row d-flex justify-content-center">
                    {plans?.map((opcion, index) => {


                        var optionsDeductible = opcion?.optionsDeductible

                        console.log('prueba options', optionsDeductible )


                        if (opcion && revenue) {


                            var aux = optionsDeductible?.filter(option => option.value?.revenue == revenue)

                            if (aux) {
                                aux = aux.sort(function (a, b) {
                                    var keyA = a.value.value
                                    var keyB = b.value.value
                                    // Compare the 2 dates
                                    if (keyA < keyB) return -1;
                                    if (keyA > keyB) return 1;
                                    return 0;
                                });
                                optionsDeductible = aux
                            }


                        }

                        const stripColor = stripColors[index];
                        return ((opcion) &&

                            <div
                                className="col-md-10 col-lg-12  col-12 mt-3 position-relative"
                                key={`opcion-${index}`}
                            >

                                <div
                                    className={'plan-pago-container card-plan border-bottom position-relative'}
                                >
                                    <CabeceraPlanes showLabel={t('I_FRONT_40')} opcion={opcion} stripColor={stripColor} />
                                    {index !== 0 &&
                                        <button onClick={() => { deletePlan(opcion.planCodigoCore) }} className='button-delete-responsive'>
                                            <b>
                                                <i className="pi pi-times" />
                                            </b>
                                        </button>
                                    }
                                    <div className="plan-pago-coberturas">
                                        <div
                                            className="mb-2 font-source-sans-regular font-19px"
                                        >
                                            <CoberturaXL length={nameValues?.length}>
                                                <div className="row d-flex flex-wrap justify-content-center mx-3 ">
                                                    <div className="col-lg-6 col-md-6 col-xs-12">
                                                        {nameValues[0]}
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-xs-12 d-flex justify-content between py-3">
                                                        <SimpleContainer name={`${opcion.planCodigoCore}.limits`}>
                                                            <DropDownFilter
                                                                name={`${opcion.planCodigoCore}.limits`}
                                                                options={catalogo?.limitsLiability?.map(lim => ({ label: lim.label, value: lim })) || []}
                                                                onChangeFn={(value) => { modifyPlan(value, 'limits', opcion?.planCodigoCore) }}
                                                                rules={{ required: 'Please select' }}
                                                            />
                                                        </SimpleContainer>
                                                    </div>


                                                    <div className="col-lg-6 col-md-6 col-xs-12">
                                                        {nameValues[1]}
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-xs-12 d-flex justify-content between py-3">
                                                        <SimpleContainer name={`${opcion.planCodigoCore}.deductible`}>
                                                            <DropDownFilter
                                                                name={`${opcion.planCodigoCore}.deductible`}
                                                                options={optionsDeductible ? optionsDeductible : []}
                                                                onChangeFn={(value) => { modifyPlan(value, 'deductible', opcion?.planCodigoCore) }}
                                                                rules={{ required: 'Please select' }}
                                                            />
                                                        </SimpleContainer>
                                                    </div>

                                                    {(
                                                        nameLicense != 'Licensed Accountants' &&
                                                        nameLicense != 'Licensed Insurance Agents' &&
                                                        nameLicense != 'Licensed Property Manager' && 
                                                        nameLicense != 'Licensed Real Estate' &&
                                                        nameLicense != 'Licensed Travel Agents'&&
                                                        nameLicense != 'Licensed Management Consultants' &&
                                                        nameLicense != 'Licensed lawyers'
                                                        ) &&

                                                        <div className="col-6">
                                                            {nameLicense}
                                                        </div>

                                                    }

                                                    {(
                                                        nameLicense != 'Licensed Accountants' &&
                                                        nameLicense != 'Licensed Property Manager' && 
                                                        nameLicense != 'Licensed Insurance Agents' &&
                                                        nameLicense != 'Licensed Real Estate' &&
                                                        nameLicense != 'Licensed Travel Agents'&&
                                                        nameLicense != 'Licensed Management Consultants' &&
                                                        nameLicense != 'Licensed lawyers'
                                                        ) &&

                                                        <div className="col-6 d-flex justify-content between py-3">
                                                            <div className="font-source-sans-regular font-19px  col-12 text-end">{`${numLic}`}</div>
                                                        </div>

                                                    }


                                                    {revenue &&

                                                        <div className="col-6">
                                                            {t('I_FRONT_81')}
                                                        </div>

                                                    }

                                                    {revenue &&

                                                        <div className="col-6 d-flex justify-content between py-3">
                                                            <div className="font-source-sans-regular font-19px  col-12 text-end">{revenue}</div>
                                                        </div>

                                                    }


                                                    <div className='col-6'>
                                                        {nameValues[2]}
                                                    </div>

                                                    <div className="col-6 d-flex justify-content between py-3 text-end">
                                                        <div className="font-source-sans-regular font-19px col-12 text-right">{`${format(opcion?.premium)}`}</div>
                                                    </div>
                                                    <div className='col-6'>
                                                        {nameValues[3]}
                                                    </div>

                                                    <div className="col-6 d-flex justify-content-end py-3">
                                                        <div className="font-source-sans-regular font-19px text-right">
                                                            <Controller
                                                                control={control}
                                                                name={`${opcion.planCodigoCore}.assessment`}
                                                                render={({ field: { value } }) => (
                                                                    <label className='info-plan' >{`${format(opcion.assessment)}`}</label>
                                                                )}
                                                            />

                                                        </div>
                                                    </div>

                                                    <div>
                                                    </div>
                                                </div>
                                            </CoberturaXL>
                                        </div>
                                    </div>
                                </div>
                                {plans[index] && !plans?.[index + 1] &&
                                    <button onClick={() => addPlan()} className='button-add-responsive text-aigpr d-flex flex-wrap align-items-center shadow p-3 mb-5 bg-white rounded'>
                                        <div className='add-icon mx-2'><b>+</b></div> ADD OPTION
                                    </button>
                                }
                            </div>
                        );
                    })}
                </ButtonGroup>

            </div>
        </>
    )
}
