import classNames from 'classnames';
import React from 'react';
import Campos from '../Campos';
import ShowOnSeccionWrapper from '../ShowOnSeccionWrapper';

/**
 * @param {import('components/DynamicForm/typedefs').Seccion} props
 * @returns
 */
const Seccion = (props) => {
  return (
    <React.Fragment>
      <div className="row">
        {(props.nombre || props.img) && (
          <div
            className={classNames('col-12 dynamic__form__seccion my-2', {
              'ml-md-5': props.nivel === 1,
            })}
          >
            <div className="d-flex flex-row">
              {props.img && (
                <img
                  src={props.img}
                  alt=""
                  className="img-fluid dynamic__form__seccion__img"
                />
              )}
              {props.nombre && (
                <h2 className="align-self-center text-darkblue">
                  {props?.nombre}
                </h2>
              )}
            </div>
          </div>
        )}
      </div>
      {props.nivel === 1 && (
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12">
                <div className="row d-flex flex-row justify-content-between">
                  <Campos campos={props.campos} infoCoverageDesired={props.infoCoverageDesired} infoUmbrella={props.infoUmbrella} aprobRetroDate={props.aprobRetroDate} retroDate={props.retroDate} campo1={props.campo1} campo2={props.campo2} campo3={props.campo3}  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {props.nivel === 0 && (
        <div className="row justify-content-center">
          {(props.descripcion !== '') &&
            <div
              style={{ fontSize: "15px", fontFamily: "Futura-Medium" }}
              dangerouslySetInnerHTML={{
                __html: props?.descripcion,
              }
              }
            />
          }
          <div className="col-sm-12 d-flex flex-wrap justify-content-between">
            <Campos preload={props?.preload} campos={props.campos} infoCoverageDesired={props.infoCoverageDesired} infoUmbrella={props.infoUmbrella} aprobRetroDate={props.aprobRetroDate} retroDate={props.retroDate} campo1={props.campo1} campo2={props.campo2} campo3={props.campo3} />
          </div>
        </div>
      )}

      {props?.secciones.map(
        (/** @type {import('./typedefs').Seccion} */ seccion) => {
          const component = <Seccion {...seccion} key={seccion.id} />;
          if (seccion?.showOn) {
            return (
              <ShowOnSeccionWrapper
                name={seccion.codigoCore}
                showOn={seccion.showOn}
                key={seccion.id}
              >
                {component}
              </ShowOnSeccionWrapper>
            );
          }
          return component;
        },
      )}
    </React.Fragment>
  );
};

export default Seccion;
