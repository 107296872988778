export const ESTADOS_P2P = {
  APROBADO: "APROBADO",
  PENDIENTE: "PENDIENTE",
  RECHAZADO: "RECHAZADO",
  FALLO: "FALLO",
  REJECTED: "R",
  PENDING: "P",
  APPROVED: "A",
  ERROR: "E",
};

export const ESTADO_P2P_LABELS = {
  APROBADO: "APPROVED",
  PENDIENTE: "PENDING",
  RECHAZADO: "REJECTED",
  FALLO: "ERROR",
  REJECTED: "REJECTED",
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  ERROR: "ERROR",
  E: "ERROR",
  R: "REJECTED",
  P: "PENDING",
  A: "APPROVED",
};

export const PERIOCIDADES_PAGO_LABELS = {
  unico: "Unique payment",
  suscripcion: "Subscription payment",
};
