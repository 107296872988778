import { Column } from 'primereact/column';
import React from 'react';

export const IndexColumn = ({
  header = '#',
  width = '65px',
  styles = {},
  headerClassName = '',
} = {}) => {
  return (
    <Column
      header={header}
      style={{ width, textAlign: 'center', ...styles }}
      headerClassName={headerClassName}
      body={(rowData, row) => row.rowIndex + 1}
    />
  );
};
