import { get } from 'lodash';
import { useRef } from 'react';

const useCustomRef = (initialValue) => {
    const ref = useRef(initialValue);

    const setter = (inputReft) => {
        if (inputReft) ref.current = inputReft;
    };

    const setRef = (pathOrInputReft = null) => {
        if (typeof pathOrInputReft !== 'string') {
            setter(pathOrInputReft);
            return;
        }

        return (inputReft) => {
            const filteredRef = get(inputReft, pathOrInputReft, null);
            setter(filteredRef);
        };
    };
    const focusRef = (pathOrFocus) => {
        if (ref.current) {
            if (typeof pathOrFocus !== 'string') {
                try {
                    ref.current.focus();
                } catch (error) {
                    console.log(error);
                }

                return;
            }
            return () => {
                const focus = get(ref, pathOrFocus, null);
                focus();
            };
        }
    };
    return { ref, setRef, focusRef };
};

export default useCustomRef;
