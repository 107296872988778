/* eslint-disable react-hooks/exhaustive-deps */

import React, { useCallback, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { SimpleContainer } from "components/Inputs/containers";
import { DropDownFilter, PrimeTextInput } from "components/Inputs/inputs";
import { useTranslation } from "react-i18next";
import useFindMunicipalities from "hooks/UbicacionHooks/useFindMunicipalities";
import useFindCities from "hooks/UbicacionHooks/useFindCities";
import { consumirServicioEncirptacion } from "services/api";
import urls, { urlGetCatalogoUbicacion, urlGetLocationByZipCode } from "services/urls";
import useCustomToast from "hooks/useCustomToast";
import { zFill } from '../../../../helpers/functions'





const UbicacionInput = ({ configuracion }) => {

  const [countries, setCountries] = useState([])

  const getCountries = useCallback(
    async () => {
      try {
        const resp = await consumirServicioEncirptacion(urls.urlGetCountries, null, false, true);
        setCountries(resp?.data?.map(val => ({ label: val.paiNombre, value: val })));
      } catch (err) {
        console.log(err)
      }
    },
    [],
  )

  useEffect(() => {
    getCountries();
  }, [])

  const methods = useFormContext();
  const { t } = useTranslation();
  const [loadingZipCode, setLoadingZipCode] = useState(false);
  const [loadingMunicipalities, setLoadingMunicipalities] = useState(false);
  const [loadingInputZipCode, setLoadingInputZipCode] = useState(false)
  const [loadingCities, setLoadingCities] = useState(false);
  const { addWarningToast } = useCustomToast();


  const nameZipCode = configuracion?.zipCode?.name || 'zipCode';
  const nameCountries = configuracion?.country?.name || 'country';
  const nameMunicipalities = configuracion?.municipality?.name || 'municipality';
  const nameCities = configuracion?.city?.name || 'city';

  const countryValue = useWatch({ name: nameCountries });
  const municipalityValue = useWatch({ name: nameMunicipalities });
  const cityValue = useWatch({ name: nameCities });
  const zipValue = useWatch({ name: nameZipCode });


  const { findMunicipalities, municipalities, setMunicipalities } = useFindMunicipalities();
  const { findCities, cities, setCities } = useFindCities();


  useEffect(() => {
    if (cities.length === 0 && !configuracion?.city?.disabled) {
      if (cityValue) {
        setCities([{ value: cityValue, label: cityValue.ciuNombre }]);
        const fillZip = zFill(zipValue * 1, 5)
        methods.setValue(nameZipCode, fillZip)
      }
    }
    if (municipalities.length === 0 && !configuracion?.municipality?.disabled) {
      if (municipalityValue) {
        setMunicipalities([{ value: municipalityValue, label: municipalityValue.provNombre }])
      }
    }
  }, [municipalityValue, cityValue])

  const findZipCode = async ({ paiCodigoCore = null, provCodigoCore = null, ciuCodigoCore = null }) => {
    if (paiCodigoCore) {
      setLoadingInputZipCode(true)
      const Data = {
        selectedType: 'city',
        params: {
          paiCodigoCore, provCodigoCore,
          ciuCodigoCore
        },
      };
      try {
        const res = await consumirServicioEncirptacion(urlGetCatalogoUbicacion, Data, false, true);

        if (res?.data?.collections?.length !== 0) {
          methods.setValue(nameZipCode, zFill(res?.data?.collections[0]?.posNombre * 1, 5));
        } else {
          methods.setValue(nameZipCode, '');

          addWarningToast(t('I_FRONT_20'));
        }

      } catch (error) {
      }
      setLoadingInputZipCode(false);
    }
  }

  /**
  *
  * @param {string} codigoPostal
  */
  const findByZipCode = async (zipCode) => {
    methods.clearErrors(nameCities);
    methods.clearErrors(nameMunicipalities);
    methods.clearErrors(nameCountries);
    if (zipCode.length === 5) {

      methods.setValue(nameCountries, (configuracion?.country?.disabled) ? t('I_FRONT_21') : null);
      methods.setValue(nameMunicipalities, (configuracion?.municipality?.disabled) ? t('I_FRONT_21') : null);
      methods.setValue(nameCities, (configuracion?.city?.disabled) ? t('I_FRONT_21') : null);
      setCities([]);
      setMunicipalities([]);
      setLoadingZipCode(true);

      const Data = {
        posNombre: zipCode
      };
      try {
        const res = await consumirServicioEncirptacion(urlGetLocationByZipCode, Data, false, true);
        if (!!res?.data?.transaccion) {
          addWarningToast(t('I_FRONT_20'));
        } else {
          if (res?.data?.collections?.countries?.length === 0) {
            addWarningToast(t('I_FRONT_20'));
          }
          setMunicipalities(res?.data?.collections.provinces.map(prov => ({ label: prov.provNombre, value: prov })));
          setCities(res?.data?.collections.cities.map(city => ({ label: city.ciuNombre, value: city })));
          if (configuracion?.country?.disabled) {
            methods.setValue(nameCountries, res?.data?.collections?.countries[0]?.paiNombre);
            methods.setValue(nameMunicipalities, res?.data?.collections?.provinces[0]?.provNombre);
            methods.setValue(nameCities, res?.data?.collections?.cities[0]?.ciuNombre);
          } else {
            methods.setValue(nameMunicipalities, res?.data?.collections?.provinces[0]);
            methods.setValue(nameCities, res?.data?.collections?.cities[0]);
            methods.setValue(nameCountries, res?.data?.collections?.countries[0]);
          }
        }
      } catch (error) { }
      setLoadingZipCode(false);
    }
  };

  return (
    <div className="row d-flex flex-wrap justify-content-between">

      <div className="order-4 d-flex flex-wrap justify-content-between col-xl-6 col-md-6 col-sm-12 my-2">
        <div className="col-8 d-flex align-items-between">

          <SimpleContainer
            name={nameZipCode}
            label={t(configuracion?.zipCode?.label || t('I_FRONT_22'))}
          >
            <PrimeTextInput
              {...configuracion?.zipCode}
              name={nameZipCode}
              onChangeFn={findByZipCode}
              loading={loadingZipCode || loadingInputZipCode}
              keyfilter="pint"
              maxLength={5}
              rules={{
                required: t('W_FRONT_12'),
                minLength: {
                  value: 5,
                  message: t('I_FRONT_23'),
                },
              }}
            />
          </SimpleContainer>
        </div>
        <div className="col-4 d-flex align-items-end pl-1">
          <SimpleContainer
            name={nameZipCode + 'Ext'}
          >
            <PrimeTextInput
              name={nameZipCode + 'Ext'}
              loading={loadingZipCode || loadingInputZipCode}
              maxLength={4}
              keyfilter="pint"
              rules={
                {
                  minLength: {
                    value: 4,
                    message: t('I_FRONT_24'),
                  },
                }
              }
            />
          </SimpleContainer>
        </div>

      </div>

      <div className="order-2 col-xl-6 col-md-6 col-sm-12 my-2 d-flex align-items-end">
        <SimpleContainer
          name={nameCountries}
          label={t(configuracion?.country?.label || t('I_FRONT_25'))}
        >
          {(!configuracion?.country?.disabled) ?
            <DropDownFilter
              {...configuracion?.country}
              options={countries}
              placeholder={t('I_FRONT_26')}
              loadingText={t('I_FRONT_21') + '...'}
              emptyFilterMessage={t('I_FRONT_27')}
              name={nameCountries}
              loading={loadingZipCode}
              onChangeFn={(value) => {
                methods.setValue(nameZipCode, '');
                methods.setValue(nameMunicipalities, null);
                methods.setValue(nameCities, null);

                if (value?.paiCodigoCore) {
                  findMunicipalities(value?.paiCodigoCore, setLoadingMunicipalities)
                  methods.clearErrors(nameCountries)
                }
              }}
              rules={{
                required: t('W_FRONT_12')
              }}
            /> : <PrimeTextInput
              {...configuracion?.country}
              name={nameCountries}
              loading={loadingZipCode}
              placeHolder={t('I_FRONT_23')}
              rules={{
                required: t('I_FRONT_23'),
              }}
            />
          }
        </SimpleContainer>


      </div>

      <div className="order-1 col-xl-6 col-md-6 col-sm-12 my-2 d-flex align-items-end">
        <SimpleContainer
          name={nameMunicipalities}
          label={t(configuracion?.municipality?.label || t('I_FRONT_28'))}
          helpText={(!configuracion?.municipality?.disabled && !countryValue) && t('I_FRONT_29')}
        >
          {(!configuracion?.municipality?.disabled) ? <DropDownFilter
            {...configuracion?.municipality}
            name={nameMunicipalities}
            loading={loadingZipCode || loadingMunicipalities}
            placeholder={t('I_FRONT_26')}
            loadingText={t('I_FRONT_21') + '...'}
            emptyFilterMessage={t('I_FRONT_27')}
            options={municipalities}
            onChangeFn={(value) => {
              methods.setValue(nameZipCode, '');
              methods.setValue(nameCities, null);
              (value?.paiCodigoCore) &&
                findCities(value.paiCodigoCore, value.provCodigoCore, setLoadingCities);
            }}
            rules={{
              required: t('W_FRONT_12')
            }}
          /> : <PrimeTextInput
            {...configuracion?.municipality}
            name={nameMunicipalities}
            loading={loadingZipCode}
              placeHolder={t('I_FRONT_23')}
            rules={{
              required: t('I_FRONT_23'),
            }}
          />
          }
        </SimpleContainer>
      </div>

      <div className="order-3 col-xl-6 col-md-6 col-sm-12 my-2 d-flex align-items-end">
        <SimpleContainer
          name={nameCities}
          label={t(configuracion?.city?.label || t('I_FRONT_31'))}
          helpText={!configuracion?.city?.disabled && !municipalityValue && t('I_FRONT_30')}
        >
          {(!configuracion?.city?.disabled) ? <DropDownFilter
            {...configuracion?.ciudades}
            name={nameCities}
            placeholder={t('I_FRONT_26')}
            loadingText={t('I_FRONT_21') + '...'}
            emptyFilterMessage={t('I_FRONT_27')}
            loading={loadingZipCode || loadingCities}
            options={cities}
            onChangeFn={(value) => (value?.paiCodigoCore) && findZipCode(value)}
            rules={{
              required: t('W_FRONT_12')
            }}
          /> : <PrimeTextInput
            {...configuracion?.city}
            name={nameCities}
            loading={loadingZipCode}
              placeHolder={t('I_FRONT_23')}
            rules={{
              required: t('I_FRONT_23'),
            }}
          />
          }
        </SimpleContainer>
      </div>
    </div>
  );
};

export default UbicacionInput;
