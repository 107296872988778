import styled from "styled-components";
import COLORS from "utils/Constants/COLORS";

export const ContainerFormField = styled.div`
  width: 100% !important;
`;

export const Label = styled.label`
  font-family: 'Futura-Medium';
  font-size: 17px;
  font-weight: 500;
  text-align: left !important;
  color: #353741;
`;

export const ContainerInput = styled.div`
  width: 100%;
  min-height: 2.6rem;
  border-radius: 4px;

  border: solid 1.5px ${(props) => (props.hasError ? COLORS.red : '#dfdfdf')};
  background-color: #ffffff;
  &:focus-within {
    border: solid 1.5px ${(props) => (props.hasError ? COLORS.red : COLORS.primary)};
  }
  &:hover {
    border: solid 1.5px ${(props) => (props.hasError ? COLORS.red : COLORS.primary)};
  }
  & > * {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
  }
`;

export const Error = styled.strong`
  font-family: 'Futura-Medium', serif;
  font-size: 15px;
  color: #cf0e18 !important;
  line-break: normal;
  margin: 0 0 0 0;
  width: ${(props) => props.width || '100%'} !important;
`;

export const ContainerField = styled.div`
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  & > * {
    padding-left: 14px;
    padding-right:14px;
  }
`;

export const HelpTextComponent = styled.small`
  font-family: 'SourceSansPro-Regular', serif;
  font-size: 14px !important;
  font-weight: 600;
  color: #353741;
  width: ${(props) => props.width || '100%'} !important;
`;

export const SubLabel = styled.div`
  margin: 0 !important;
  padding: 0 !important;
  font-size: 16px;
  text-align: left;
`;