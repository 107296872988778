import { PRODUCTS } from "./PRODUCTS";
import swlogo from "../../assets/img/products/social-workers-logo.png";
import psychologistLogo from "../../assets/img/products/psychologist-logo.png";
import accountantsLogo from "../../assets/img/products/accountant-logo.png";
import insuranceAgentsLogo from "../../assets/img/products/insurance-agents-logo.png"
import realEstateLogo from "../../assets/img/products/real-estate-logo.png"
import travelAgentsLogo from "../../assets/img/products/real-estate-logo.png"
import umbrellaLogo from "../../assets/img/products/umbrella-logo.png"
import propertyManagerLogo from "../../assets/img/products/real-estate-logo.png"
import pharmacistsLogo from "../../assets/img/products/pharmacists-logo.png";
import doLogo from "../../assets/img/products/d&o-logo.png";
import managementLogo from "../../assets/img/products/management-consultants-logo.png";
import cyberLogo from "../../assets/img/products/cyber-edge-logo.png";
import lawyersLogo from "../../assets/img/products/lawyers.png";

export const STEPS_BREADCRUM = {
    [PRODUCTS['social-worker'].id]: {
        breadCrumb: {
            title: 'Social Workers',
        },

        steps: [
            {
                title: '1. Lead detail',
            },
            {
                title: '2. Questions',
            },
            {
                title: '3. Options',
            },
            {
                title: '4. Summary',
            },
            {
                title: '5. Confirmation',
            },
        ],
        breadCrumbSteps: {
            'lead-detail': {
                title: 'Social Workers',
                pasosActivos: [1],
                img: swlogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'questions': {
                title: 'Social Worker',
                pasosActivos: [1, 2],
                img: swlogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'options': {
                title: 'Social Worker',
                pasosActivos: [1, 2, 3],
                img: swlogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'summary': {
                title: 'Social Worker',
                pasosActivos: [1, 2, 3, 4],
                img: swlogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'confirmation': {
                title: 'Social Worker',
                pasosActivos: [1, 2, 3, 4, 5],
                img: swlogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
        },


    },
    [PRODUCTS['insurance-agents'].id]: {
        breadCrumb: {
            title: 'Insurance Agents',
        },

        steps: [
            {
                title: '1. Lead detail',
            },
            {
                title: '2. Questions',
            },
            {
                title: '3. Options',
            },
            {
                title: '4. Summary',
            },
            {
                title: '5. Confirmation',
            },
        ],
        breadCrumbSteps: {
            'lead-detail': {
                title: 'Insurance Agents',
                pasosActivos: [1],
                img: insuranceAgentsLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'questions': {
                title: 'Insurance Agents',
                pasosActivos: [1, 2],
                img: insuranceAgentsLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'options': {
                title: 'Insurance Agents',
                pasosActivos: [1, 2, 3],
                img: insuranceAgentsLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'summary': {
                title: 'Insurance Agents',
                pasosActivos: [1, 2, 3, 4],
                img: insuranceAgentsLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'confirmation': {
                title: 'Insurance Agents',
                pasosActivos: [1, 2, 3, 4, 5],
                img: insuranceAgentsLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
        },


    },
    [PRODUCTS['real-estate'].id]: {
        breadCrumb: {
            title: 'Real Estate',
        },

        steps: [
            {
                title: '1. Lead detail',
            },
            {
                title: '2. Questions',
            },
            {
                title: '3. Options',
            },
            {
                title: '4. Summary',
            },
            {
                title: '5. Confirmation',
            },
        ],
        breadCrumbSteps: {
            'lead-detail': {
                title: 'Real Estate',
                pasosActivos: [1],
                img: realEstateLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'questions': {
                title: 'Real Estate',
                pasosActivos: [1, 2],
                img: realEstateLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'options': {
                title: 'Real Estate',
                pasosActivos: [1, 2, 3],
                img: realEstateLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'summary': {
                title: 'Real Estate',
                pasosActivos: [1, 2, 3, 4],
                img: realEstateLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'confirmation': {
                title: 'Real Estate',
                pasosActivos: [1, 2, 3, 4, 5],
                img: realEstateLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
        },


    },
    [PRODUCTS['travel-agents'].id]: {
        breadCrumb: {
            title: 'Travel Agents',
        },

        steps: [
            {
                title: '1. Lead detail',
            },
            {
                title: '2. Questions',
            },
            {
                title: '3. Options',
            },
            {
                title: '4. Summary',
            },
            {
                title: '5. Confirmation',
            },
        ],
        breadCrumbSteps: {
            'lead-detail': {
                title: 'Travel Agents',
                pasosActivos: [1],
                img: travelAgentsLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'questions': {
                title: 'Travel Agents',
                pasosActivos: [1, 2],
                img: travelAgentsLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'options': {
                title: 'Travel Agents',
                pasosActivos: [1, 2, 3],
                img: travelAgentsLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'summary': {
                title: 'Travel Agents',
                pasosActivos: [1, 2, 3, 4],
                img: travelAgentsLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'confirmation': {
                title: 'Travel Agents',
                pasosActivos: [1, 2, 3, 4, 5],
                img: travelAgentsLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
        },


    },
    [PRODUCTS['umbrella'].id]: {
        breadCrumb: {
            title: 'Umbrella Excess',
        },

        steps: [
            {
                title: '1. Lead detail',
            },
            {
                title: '2. Questions',
            },
            {
                title: '3. Options',
            },
            {
                title: '4. Summary',
            },
            {
                title: '5. Confirmation',
            },
        ],
        breadCrumbSteps: {
            'lead-detail': {
                title: 'Umbrella Excess',
                pasosActivos: [1],
                img: umbrellaLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'questions': {
                title: 'Umbrella Excess',
                pasosActivos: [1, 2],
                img: umbrellaLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'options': {
                title: 'Umbrella Excess',
                pasosActivos: [1, 2, 3],
                img: umbrellaLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'summary': {
                title: 'Umbrella Excess',
                pasosActivos: [1, 2, 3, 4],
                img: umbrellaLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'confirmation': {
                title: 'Umbrella Excess',
                pasosActivos: [1, 2, 3, 4, 5],
                img: umbrellaLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
        },


    },
    [PRODUCTS['property-manager'].id]: {
        breadCrumb: {
            title: 'Property Manager',
        },

        steps: [
            {
                title: '1. Lead detail',
            },
            {
                title: '2. Questions',
            },
            {
                title: '3. Options',
            },
            {
                title: '4. Summary',
            },
            {
                title: '5. Confirmation',
            },
        ],
        breadCrumbSteps: {
            'lead-detail': {
                title: 'Property Manager',
                pasosActivos: [1],
                img: propertyManagerLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'questions': {
                title: 'Property Manager',
                pasosActivos: [1, 2],
                img: propertyManagerLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'options': {
                title: 'Property Manager',
                pasosActivos: [1, 2, 3],
                img: propertyManagerLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'summary': {
                title: 'Property Manager',
                pasosActivos: [1, 2, 3, 4],
                img: propertyManagerLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'confirmation': {
                title: 'Property Manager',
                pasosActivos: [1, 2, 3, 4, 5],
                img: propertyManagerLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
        },


    },
    [PRODUCTS['accountants'].id]: {
        breadCrumb: {
            title: 'Accountants',
        },

        steps: [
            {
                title: '1. Lead detail',
            },
            {
                title: '2. Questions',
            },
            {
                title: '3. Options',
            },
            {
                title: '4. Summary',
            },
            {
                title: '5. Confirmation',
            },
        ],
        breadCrumbSteps: {
            'lead-detail': {
                title: 'Accountants',
                pasosActivos: [1],
                img: accountantsLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'questions': {
                title: 'Accountants',
                pasosActivos: [1, 2],
                img: accountantsLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'options': {
                title: 'Accountants',
                pasosActivos: [1, 2, 3],
                img: accountantsLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'summary': {
                title: 'Accountants',
                pasosActivos: [1, 2, 3, 4],
                img: accountantsLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'confirmation': {
                title: 'Accountants',
                pasosActivos: [1, 2, 3, 4, 5],
                img: accountantsLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
        },


    },
    [PRODUCTS['psychologist'].id]: {
        breadCrumb: {
            title: 'Psychologist',
        },

        steps: [
            {
                title: '1. Lead detail',
            },
            {
                title: '2. Questions',
            },
            {
                title: '3. Options',
            },
            {
                title: '4. Summary',
            },
            {
                title: '5. Confirmation',
            },
        ],
        breadCrumbSteps: {
            'lead-detail': {
                title: 'Psychologist',
                pasosActivos: [1],
                img: psychologistLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'questions': {
                title: 'Psychologist',
                pasosActivos: [1, 2],
                img: psychologistLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'options': {
                title: 'Psychologist',
                pasosActivos: [1, 2, 3],
                img: psychologistLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'summary': {
                title: 'Psychologist',
                pasosActivos: [1, 2, 3, 4],
                img: psychologistLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'confirmation': {
                title: 'Psychologist',
                pasosActivos: [1, 2, 3, 4, 5],
                img: psychologistLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
        },


    },
    [PRODUCTS['pharmacists'].id]: {
        breadCrumb: {
            title: 'Pharmacists',
            //  subtitle: 'Cotización Seguro de Pymes',
        },

        steps: [
            {
                title: '1. Lead detail',
            },
            {
                title: '2. Questions',
            },
            {
                title: '3. Options',
            },
            {
                title: '4. Summary',
            },
            {
                title: '5. Confirmation',
            },
        ],
        breadCrumbSteps: {
            'lead-detail': {
                title: 'Pharmacists',
                pasosActivos: [1],
                img: pharmacistsLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'questions': {
                title: 'Pharmacists',
                pasosActivos: [1, 2],
                img: pharmacistsLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'options': {
                title: 'Pharmacists',
                pasosActivos: [1, 2, 3],
                img: pharmacistsLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'summary': {
                title: 'Pharmacists',
                pasosActivos: [1, 2, 3, 4],
                img: pharmacistsLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'confirmation': {
                title: 'Pharmacists',
                pasosActivos: [1, 2, 3, 4, 5],
                img: pharmacistsLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
        },


    },
    [PRODUCTS['D&O'].id]: {
        breadCrumb: {
            title: 'D&O Not for profit',
            //  subtitle: 'Cotización Seguro de Pymes',
        },

        steps: [
            {
                title: '1. Lead detail',
            },
            {
                title: '2. Questions',
            },
            {
                title: '3. Options',
            },
            {
                title: '4. Summary',
            },
            {
                title: '5. Confirmation',
            },
        ],
        breadCrumbSteps: {
            'lead-detail': {
                title: 'D&O Not for profit',
                pasosActivos: [1],
                img: doLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'questions': {
                title: 'D&O Not for profit',
                pasosActivos: [1, 2],
                img: doLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'options': {
                title: 'D&O Not for profit',
                pasosActivos: [1, 2, 3],
                img: doLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'summary': {
                title: 'D&O Not for profit',
                pasosActivos: [1, 2, 3, 4],
                img: doLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'confirmation': {
                title: 'D&O Not for profit',
                pasosActivos: [1, 2, 3, 4, 5],
                img: doLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
        },


    },
    [PRODUCTS['management-consultants'].id]: {
        breadCrumb: {
            title: 'Management Consultants',
            //  subtitle: 'Cotización Seguro de Pymes',
        },

        steps: [
            {
                title: '1. Lead detail',
            },
            {
                title: '2. Questions',
            },
            {
                title: '3. Options',
            },
            {
                title: '4. Summary',
            },
            {
                title: '5. Confirmation',
            },
        ],
        breadCrumbSteps: {
            'lead-detail': {
                title: 'Management Consultants',
                pasosActivos: [1],
                img: managementLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'questions': {
                title: 'Management Consultants',
                pasosActivos: [1, 2],
                img: managementLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'options': {
                title: 'Management Consultants',
                pasosActivos: [1, 2, 3],
                img: managementLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'summary': {
                title: 'Management Consultants',
                pasosActivos: [1, 2, 3, 4],
                img: managementLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'confirmation': {
                title: 'Management Consultants',
                pasosActivos: [1, 2, 3, 4, 5],
                img: managementLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
        },


    },
    [PRODUCTS['cyber-edge'].id]: {
        breadCrumb: {
            title: 'Cyber Edge',
            //  subtitle: 'Cotización Seguro de Pymes',
        },

        steps: [
            {
                title: '1. Lead detail',
            },
            {
                title: '2. Questions',
            },
            {
                title: '3. Options',
            },
            {
                title: '4. Summary',
            },
            {
                title: '5. Confirmation',
            },
        ],
        breadCrumbSteps: {
            'lead-detail': {
                title: 'Cyber Edge',
                pasosActivos: [1],
                img: cyberLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'questions': {
                title: 'Cyber Edge',
                pasosActivos: [1, 2],
                img: cyberLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'options': {
                title: 'Cyber Edge',
                pasosActivos: [1, 2, 3],
                img: cyberLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'summary': {
                title: 'Cyber Edge',
                pasosActivos: [1, 2, 3, 4],
                img: cyberLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'confirmation': {
                title: 'Cyber Edge',
                pasosActivos: [1, 2, 3, 4, 5],
                img: cyberLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
        },


    },
    [PRODUCTS['lawyers'].id]: {
        breadCrumb: {
            title: 'Lawyers',
            //  subtitle: 'Cotización Seguro de Pymes',
        },

        steps: [
            {
                title: '1. Lead detail',
            },
            {
                title: '2. Questions',
            },
            {
                title: '3. Options',
            },
            {
                title: '4. Summary',
            },
            {
                title: '5. Confirmation',
            },
        ],
        breadCrumbSteps: {
            'lead-detail': {
                title: 'Lawyers',
                pasosActivos: [1],
                img: lawyersLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'questions': {
                title: 'Lawyers',
                pasosActivos: [1, 2],
                img: lawyersLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'options': {
                title: 'Lawyers',
                pasosActivos: [1, 2, 3],
                img: lawyersLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'summary': {
                title: 'Lawyers',
                pasosActivos: [1, 2, 3, 4],
                img: lawyersLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
            'confirmation': {
                title: 'Lawyers',
                pasosActivos: [1, 2, 3, 4, 5],
                img: lawyersLogo,
                styleimg: 'img-fluid img-breadcrumb',
            },
        },


    },
};