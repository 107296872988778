import { types } from "redux/types/types";
import { apiPost } from "services/api";
import { urlDiccionario, urlLanguagesFlags } from "services/urls";
import { empresa } from "../../services/urls";

export const isLoading = () => {
  return { type: types.LOADING };
};

export const stopLoading = () => {
  return { type: types.STOP_LOADING };
};

export const errorLogin = () => {
  return { type: types.ERROR_LOGIN };
};

export const cleanErrors = () => {
  return { type: types.CLEAN_ERRORS };
};

export const setLanguage = (language = "EN") => {
  return {
    type: types.SET_LANGUAGE,
    payload: language,
  };
};

export const setLanguages = (languages = {}) => {
  return {
    type: types.SET_LANGUAGES,
    payload: languages,
  };
};

export const loadTexts = (language, i18next) => {
  return async (dispatch) => {
    try {
      const respTextos = await apiPost(urlDiccionario, {
        category: "db-front",
        language,
      });
      if (respTextos?.data.transaccion) {
        const resources = respTextos?.data?.labels;
        i18next.addResourceBundle(
          language,
          "translation",
          resources[language]?.translation,
          true,
          true
        );
        i18next.changeLanguage(language);
        dispatch(setLanguage(language));
        localStorage.setItem("lng", language);
        localStorage.setItem("texts", JSON.stringify(resources[language]?.translation));
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };
};

export const loadLanguages = (i18next) => {
  return async (dispatch) => {
    try {
      const respIdiomas = await apiPost(urlLanguagesFlags, { empresa });
      if (respIdiomas?.data?.transaccion) {
        if (respIdiomas?.data?.empTiempoCaducidad) {
          dispatch(setSessionTime(respIdiomas?.data?.empTiempoCaducidad));
        }
        if (respIdiomas?.data?.rangoVigencia) {
          dispatch(setRangoVigencia(respIdiomas?.data?.rangoVigencia));
        }
        dispatch(setLanguages(respIdiomas?.data?.idiomas));
        const lng = localStorage.getItem("lng") || null;
        dispatch(setLanguage(lng || respIdiomas?.data?.idomaPredeterminado));
        if (localStorage.getItem("texts") && lng) {
          i18next.addResourceBundle(
            lng,
            "translation",
            JSON.parse(localStorage.getItem("texts")),
            true,
            true
          );
          i18next.changeLanguage(lng);
        } else {
          dispatch(
            loadTexts(lng || respIdiomas?.data?.idomaPredeterminado || "us", i18next)
          );
        }
        if (!lng) {
          localStorage.setItem("lng", respIdiomas?.data?.idomaPredeterminado);
        }
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };
};

export const setRangoVigencia = (rangoVigencia = 5) => {
  return {
    type: types.SET_RANGOVIGENCIA,
    payload: rangoVigencia
  }
}

export const setSessionTime = (sessionTime = 3600) => {
  return {
    type: types.SET_TIMESESSION,
    payload: sessionTime,
  };
};
