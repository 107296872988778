import { SimpleContainer } from "components/Inputs/containers";
import { DateCalendar, SimpleErrorMessage } from "components/Inputs/inputs";
import { PAYMENT_TYPES } from "constants/PAYMENT_TYPES";
import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { apiGet } from "services/api";
import { urlPreguntasFrecuentesP2p, urlUploadFile } from "services/urls";
import moment from 'moment';
import { getQueryParams } from "utils/functions";
import bill from "./assets/img/bill.png";
import TipoPagoRadioButton from "./components/TipoPagoRadioButton";
import './styles.scss'
import { InputNumber } from "primereact/inputnumber";
import useCustomToast from "hooks/useCustomToast";
import { v4 as uuidv4 } from "uuid";

const PaymentForm = ({
  onSubmit,
  payments = [],
  vigencia = new Date(),
  onClickBack = () => { console.log('Event to back') },
  total,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);

  const methods = useForm({ mode: "onChange" });
  const { setValue } = methods;

  const qParams = getQueryParams();

  const history = useNavigate();
  const [uploading, setUploading] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [modalShowTerms, setModalShowTerms] = useState(false);
  const [p2pProps, setP2pProps] = useState({});
  const [beFinanced, setBeFInanced] = useState(false)
  const [amount, setAmount] = useState(0)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [files, setFiles] = useState([])


  const [tieneErrorValor, setTieneValorError] = useState(false)
  const [tieneErrorFile, setTieneFileError] = useState(false)
  const [mensajeErrorValor, setMensajeErrorValor] = useState('')



  const { rangoVigencia } = useSelector(state => state.ui);

  const isP2p = useMemo(
    () =>
      payments.some(
        (item) =>
          item.isActive &&
          (item.code === PAYMENT_TYPES.DIRECT || item.code === PAYMENT_TYPES.DEFERRED)
      ),
    [payments]
  );

  const isReferenced = useMemo(
    () =>
      payments.some((item) => item.isActive && item.code === PAYMENT_TYPES.REFERENCED),
    [payments]
  );

  const isDirect = useMemo(
    () => payments.some((item) => item.isActive && item.code === PAYMENT_TYPES.DIRECT),
    [payments]
  );

  const isDefered = useMemo(
    () => payments.some((item) => item.isActive && item.code === PAYMENT_TYPES.DEFERRED),
    [payments]
  );

  useEffect(() => {
    if (isP2p) {
      apiGet(urlPreguntasFrecuentesP2p).then((res) => {
        setP2pProps(res?.data?.data);
      }).catch(err => console.log);

    }
  }, [isP2p]);

  const defaultValueFormaPago = useMemo(() => {
    if (isDirect && !isDefered) {
      return PAYMENT_TYPES.DIRECT;
    }
    if (!isDirect && isDefered) {
      return PAYMENT_TYPES.DEFERRED;
    }
    return null;
  }, [isDefered, isDirect]);

  const handleOnSubmit = async (formData) => {



    let aux = { ...formData }

    if (beFinanced) {
      aux.beFinanced = true
      aux.amount = amount
      aux.files = files
    }

    console.log('formData2', aux)
    onSubmit(aux);
  };

  const onClickContinue = () => {
    const { pathname, search } = window.location;
    if (isReferenced || qParams.p2p === "true") {
      methods.handleSubmit(handleOnSubmit)();
    }
    if (isP2p && !qParams.p2p) {

      if (beFinanced) {

        if (amount < 0) {
          setTieneValorError(true)
          setMensajeErrorValor('Please enter a valid value. It must be greater than zero.')
        } else if (amount > parseInt(total)) {
          setTieneValorError(true)
          setMensajeErrorValor('Please enter a valid value. It must be greater than zero and not exceed $' + total)
        } else if (files.length == 0) {

          setTieneValorError(false)
          setMensajeErrorValor('')

          setTieneFileError(true)

        } else {
          setTieneValorError(false)
          setTieneFileError(false)
          return history(`${pathname}${search}&p2p=true`);
        }

      } else {
        return history(`${pathname}${search}&p2p=true`);
      }

    }
  };

  const _onClickBack = () => {
    if (isReferenced || qParams.p2p !== "true") {
      return onClickBack();
    }
    const { pathname, search } = window.location;
    if (qParams.p2p === "true") {
      return history(`${pathname}${search}`.replace("&p2p=true", ""));
    }
  };

  const onClickFrecuentQuestions = async () => {
    // const preguntas = await apiGet(urlPreguntasFrecuentesP2p);
    // setP2pProps(preguntas?.data?.data);
    setModalShow(true);
  };

  const onClickTerms = async () => {
    // const preguntas = await apiGet(urlPreguntasFrecuentesP2p);
    // setP2pProps(preguntas?.data?.data);
    setModalShowTerms(true);
  };

  const calcularFecha = (val = 5) => {
    const fecha = moment();
    fecha.add(val, 'days');
    return fecha.toDate();
  }

  const { addWarningToast } = useCustomToast();


  const onChangeFiles = () => async (e) => {
    console.log('verFile1', e.target)
    if (e.target.files.length !== 0) {
      try {
        const formData = new FormData();

        const file = e.target.files[0];
        if (file.size / 1000 > 10000) {
          addWarningToast(
            `The maximum size allowed is: ${10000}KB`

          );
          return;
        }

        const splits = file.name?.split(".");

        const lastSplit = splits.length;

        const type = splits[lastSplit - 1];

        const filename = file.name
          .toLowerCase()
          .replace(`.${type}`, `${uuidv4()}.${type}`);

        formData.append("file", e.target.files[0]);
        formData.append("filename", filename);

        setUploading(true);
        setError(false)
        await fetch(urlUploadFile, {
          method: "POST",
          body: formData,
        })
          .then((res) => res.json())
          .then((res) => {
            e.target.value = null;
            setUploading(false);
            console.log('UPLOAD', res);
            if (res?.transaccion) {
              //  field.value.push(res?.url);
              //  setValue(campo.name, field.value, { shouldValidate: true });

              let newFiles = [...files]
              newFiles.push(res?.url)

              setFiles(newFiles)
              setTieneFileError(false)

            } else {
              setError(true)
              setErrorMessage(res.errors?.file[0])
            }
          }).catch(err => console.log);
        setUploading(false)
      } catch (error) {
        console.log(error);
        setUploading(false);
      }
    }
  };

  const uploadFile = () => {
    ref.current.click();
  };

  useLayoutEffect(() => {

    if (isReferenced) {
      setValue('vigencia', vigencia)
    }

  }, [vigencia, setValue, isReferenced]);

  let yes = "Yes";
  let no = "No";

  return (
    <FormProvider {...methods}>
      <div>
        {isP2p && (
          <div className='my-5 text-center'>
            {qParams.p2p !== "true" && (
              <div className='d-flex flex-column align-items-center text-center'>
                <p style={{ maxWidth: "21rem" }}>
                  {t("Thank you for trusting AIG for your insurance needs!")}
                </p>

                <p style={{ maxWidth: "24rem" }}>
                  {t(
                    "Please proceed with payment of premium through our secure platform to complete the binding process"
                  )}
                </p>
              </div>
            )}


            {(qParams.p2p != "true") && <React.Fragment>
              <Row style={{ marginTop: '10px' }}>
                <Col style={{ textAlign: "right" }}>
                  <p >
                    Will the policy be financed?
                  </p>
                </Col>
                <Col >
                  <Row style={{ textAlign: "right" }}>
                    <Col md={1}>
                      <label className="containerCheck">
                        {t(yes)}
                        <input
                          name={'beFinanced'}
                          type="checkbox"
                          checked={
                            beFinanced
                          }
                          disabled={false}
                          onChange={() => {
                            setBeFInanced(true)
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                    <Col md={1}>
                      <label className="containerCheck">
                        {t(no)}

                        <input
                          name={'beFinanced'}
                          type="checkbox"
                          disabled={false}
                          checked={
                            !beFinanced
                          }
                          onChange={() => {
                            setBeFInanced(false)
                          }}
                        />

                        <span className="checkmark"></span>
                      </label>
                    </Col>
                  </Row>

                </Col>
              </Row>

              {(beFinanced && (qParams.p2p != "true")) &&
                <Row style={{ marginTop: '10px' }}>
                  <Col style={{ textAlign: "right" }}>
                    <p >
                      Please enter the amount of the required down  payment
                    </p>
                  </Col>
                  <Col style={{ textAlign: "left" }} >
                    <InputNumber

                      value={amount}
                      onChange={(e) => {
                        setTieneValorError(false)
                        setMensajeErrorValor('')
                        if (e.value != null) {

                          setAmount(e.value);
                        } else {
                          setAmount(0);
                        }


                      }}
                      name={'amount'}
                      inputId={'amount'}
                      style={{ border: '1px solid #ccc', }}  // Añade esta línea para el borde
                      mode="decimal"
                      max={parseInt(total)}
                      minFractionDigits={2}
                      maxFractionDigits={2}
                    />
                    <br></br>

                    {tieneErrorValor &&
                      <small style={{ color: 'red' }} class="sc-jrQzAO kBIWbc">{mensajeErrorValor}</small>
                    }




                  </Col>

                </Row>
              }


              {(beFinanced && (qParams.p2p != "true")) &&
                <Row style={{ marginTop: '10px' }}>
                  <Col style={{ textAlign: "right" }}>
                    <p >
                      Please upload the financing agreement
                    </p>
                  </Col>
                  <Col style={{ textAlign: "left" }} >
                    <button
                      className="btn btn-aigpr-outlined text-darkblue align-self-center"
                      type="button"
                      disabled={uploading}
                      onClick={uploadFile}
                      style={{ width: "150px", whiteSpace: "nowrap" }}
                    >
                      {!uploading && "UPLOAD FILE"}
                      {uploading && "UPLOADING..."}
                    </button>
                    <input
                      type="file"
                      accept=".pdf, .docx, .xlsx"
                      ref={ref}
                      hidden
                      multiple={true}
                      onChange={onChangeFiles()}
                      disabled={uploading}
                    />


                    <div
                      className="d-flex flex-column justify-content-end w-100 mt-3"
                      style={{ textAlign: "left" }}
                    >
                      {files &&
                        files.map((file, index) => (
                          <div className="my-1" key={index}>
                            <a
                              className="mx-2 btn btn-aigpr-outlined text-truncate"
                              rel="noreferrer"
                              href={file}
                              style={{ maxWidth: "100px" }}
                              disabled={uploading}
                              target="_blank"
                            >
                              {file?.replace?.("https://aigpr-pdf.s3.amazonaws.com/", "")}
                            </a>
                            <button
                              className="btn"
                              type="button"
                              onClick={() => {
                                const newFiles = files.filter((item) => item !== file);
                                setFiles(newFiles)
                              }}
                              disabled={uploading}
                            >
                              <i className="pi pi-times" />
                            </button>
                          </div>
                        ))}
                    </div>
                    {tieneErrorFile &&
                      <small style={{ color: 'red' }}>{'Please upload a file to proceed.'}</small>
                    }


                  </Col>
                </Row>
              }



            </React.Fragment>}









            {qParams.p2p === "true" && (
              <React.Fragment>
                <img src={p2pProps?.logo} alt='' style={{ maxWidth: "300px" }} />

                <div className='d-flex flex-column align-items-center text-center'>
                  <Row  >


                    {beFinanced ?
                      <Col>
                        <h5>TOTAL</h5>
                        <br></br>
                        <h5>$ {total}</h5>

                      </Col> :
                      <Col>
                        <h3>TOTAL</h3>
                        <h3>$ {total}</h3>

                      </Col>

                    }



                    {beFinanced &&

                      <React.Fragment>


                        <Col>
                          <h5>DOWN PAYMENT</h5>
                          <h5>$ {amount.toFixed(2)}</h5>

                        </Col>


                        <Col>
                          <h5>AMOUNT FINANCED</h5>
                          <h5>$ {(total - amount).toFixed(2)} </h5>

                        </Col>

                      </React.Fragment>


                    }


                  </Row>

                </div>



                <div className='my-2'>{t("Payment type")}:</div>
                <div className='d-flex justify-content-center'>
                  <Controller
                    name='formaPago'
                    defaultValue={defaultValueFormaPago}
                    rules={{
                      required: t("It is necessary to select the payment method"),
                    }}
                    render={({ field: { name, onChange, value } }) => (
                      <React.Fragment>
                        {isDirect && (
                          <TipoPagoRadioButton
                            id={PAYMENT_TYPES.DIRECT}
                            label={t("Direct")}
                            name={name}
                            valor={PAYMENT_TYPES.DIRECT}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                        {isDefered && (
                          <TipoPagoRadioButton
                            id={PAYMENT_TYPES.DEFERRED}
                            label={t("Defered")}
                            name={name}
                            valor={PAYMENT_TYPES.DEFERRED}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      </React.Fragment>
                    )}
                  />
                </div>
                <SimpleErrorMessage name='formaPago' />

                <div className='d-flex flex-column '>
                  <div className='d-flex justify-content-center'>
                    <Controller
                      name='terminosCondiciones'
                      rules={{
                        required: t(
                          "It is necessary to accept the terms and conditions to continue"
                        ),
                      }}
                      render={({ field }) => (
                        <input
                          type='checkbox'
                          name=''
                          id='terminos'
                          className='align-self-center'
                          {...field}
                        />
                      )}
                    />
                    <label
                      htmlFor='terminos'
                      style={{ fontSize: "19px" }}
                      className='align-self-center ms-1 no-text-selection cpointer'>
                      {t("Accept ")}
                      <button
                        type='button'
                        className='btn-link-primary bg-white text-darkblue text-underline-hover'
                        onClick={onClickTerms}>
                        {t("terms and conditions")}
                      </button>
                    </label>
                  </div>
                  <SimpleErrorMessage name='terminosCondiciones' />
                </div>

                <p
                  style={{ cursor: "pointer" }}
                  className='text-darkblue text-underline-hover'
                  onClick={onClickFrecuentQuestions}>
                  {t("Frequent questions")}
                </p>

                <Modal
                  size='lg'
                  centered
                  show={modalShow}
                  onHide={() => setModalShow(false)}>
                  <Modal.Header>
                    <Modal.Title>{t("Frequent questions")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {p2pProps?.preguntasFrecuentes?.map?.(({ pregunta, respuesta }) => (
                      <Accordion key={pregunta}>
                        <AccordionTab header={pregunta}>
                          <div dangerouslySetInnerHTML={{ __html: respuesta }} />
                        </AccordionTab>
                      </Accordion>
                    ))}
                  </Modal.Body>
                </Modal>

                <Modal
                  size='lg'
                  centered
                  show={modalShowTerms}
                  onHide={() => setModalShowTerms(false)}>
                  <Modal.Header>
                    <Modal.Title>{t("Terms and conditions")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div
                      dangerouslySetInnerHTML={{ __html: p2pProps?.termsConditions }}
                    />
                  </Modal.Body>
                </Modal>

                <div className='row justify-content-center'>
                  <div className='col-md-7 col-lg-5'>
                    <div className='d-flex flex-row flex-wrap'>
                      {p2pProps?.logoTarjetas?.map?.((tarjeta) => (
                        <img
                          alt='tarjeta'
                          src={tarjeta}
                          className='mx-auto img-fluid'
                          key={tarjeta}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        )}

        {isReferenced && (
          <div className='text-center'>
            <img src={bill} width='100' className='img-fluid mb-4' alt='' />
            <div>
              {t("Do you wish to proceed with policy issuance?")}

              <Controller
                name='aceptoTerminos'
                rules={{
                  validate: (value) => {
                    if ([true, false].includes(value)) {
                      return true;
                    }
                    return t("Please select an option");
                  },
                }}
                render={({ field: { name, onChange, value } }) => (
                  <React.Fragment>
                    <div className='d-inline'>
                      <input
                        className='ms-4'
                        type='radio'
                        value={true}
                        name={name}
                        id='yesInput'
                        checked={value === true}
                        onChange={() => onChange(true)}
                      />
                      <label className='ms-1' htmlFor='yesInput'>
                        {t("Yes")}
                      </label>
                    </div>

                    <div className='d-inline'>
                      <input
                        className='ms-4'
                        type='radio'
                        value={false}
                        name={name}
                        id='noInput'
                        checked={value === false}
                        onChange={() => onChange(false)}
                      />
                      <label className='ms-1' htmlFor='noInput'>
                        {t("No")}
                      </label>
                    </div>
                  </React.Fragment>
                )}
              />
            </div>
            <SimpleErrorMessage name='aceptoTerminos' />
            <div className="row col-12 d-flex flex-wrap justify-content-center mt-5">
              <div className="col-4">
                <SimpleContainer classContainer=" ms-1" name={'vigencia'} label={'Effective date'}>
                  <DateCalendar rules={{ required: 'Please select a date' }} name={'vigencia'}
                    fechaFin={calcularFecha(rangoVigencia)}
                    fechaInicio={calcularFecha(-rangoVigencia)}
                  />
                </SimpleContainer>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className='mt-5 text-center' style={{ maxWidth: "600px" }}>
        <div className='d-flex flex-row flex-wrap justify-content-around'>
          <button
            className='btn-aigpr-no-block btn btn-primary my-2 px-5'
            onClick={_onClickBack}
            type='button'
            style={{ width: "200px" }}>
            {t("BACK")}
          </button>
          <button
            className='btn-aigpr-no-block btn btn-primary my-2 px-5'
            onClick={onClickContinue}
            type='button'
            style={{ width: "200px" }}>
            {t("CONTINUE")}
          </button>
        </div>
      </div>
    </FormProvider>
  );
};

export default PaymentForm;
