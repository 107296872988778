import { useCallback } from "react";
import { useNavigate } from "react-router";

const useHistory = () => {
  const navigate = useNavigate();

  navigate.replace = useCallback(
    (path) => {
      return navigate(path, { replace: true });
    },
    [navigate]
  );

  navigate.push = navigate;

  return navigate;
};

export default useHistory;
