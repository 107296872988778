import { Confirmation } from "components/Confirmation/Confirmation";
import { HeaderLogo } from "components/Header/HeaderLogo";
import { IndexColumn } from "components/Table/Columns";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormControl } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { consumirServicioEncirptacion, apiGet } from "services/api";
import { empresa, plataforma, urlCambiarEstadoPoliza, urlListarPolizas } from "services/urls";
import { Loading } from "../Loading/Loading";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const ListadoPolizas = () => {
  const [loading, setLoading] = useState(false);
  const [polizas, setPolizas] = useState([]);
  const [value, setValue] = useState([]);
  const [showConfirmacion, setShowConfirmacion] = useState(false);
  const [id, setId] = useState(null);
  const refBuscador = useRef(null);

  const { t } = useTranslation();
  const { dataUsuario } = useSelector((state) => state.login);

  const getData = useCallback(async () => {
    setLoading(true);

    const body = {
      empresa,
      plataforma,
      Data: {
        email: dataUsuario?.correo,
      },
    };
    const res = await consumirServicioEncirptacion(urlListarPolizas, body, false, true);
    const mappedPolizas = res?.data?.map?.((item) => ({
      ...item,
      total: formatter.format(item.total),
      polNumber:{...item.polNumber, invoice: item.polNumber.invoice.replace('135-A','135').replace('135-B','135').replace('135-C','135').replace('135-D','135')},
      createdAt: moment(item?.createdAt).format("DD-MM-YYYY hh:mm:ss"),
    }));
    setPolizas(mappedPolizas);
    setValue(mappedPolizas);
    setLoading(false);
  }, [dataUsuario?.correo]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onClickBuscar = () => {
    const valueInput = refBuscador.current.value;

    setValue(
      polizas.filter(
        (poliza) =>
          String(poliza?.polNumber?.invoice)
            .toLowerCase()
            .includes?.(valueInput?.toLowerCase()) ||
          String(poliza?.createdAt)
            .toLowerCase()
            ?.includes?.(valueInput?.toLowerCase()) ||
          String(poliza?.ramo).toLowerCase()?.includes?.(valueInput?.toLowerCase()) ||
          String(poliza?.usuario).toLowerCase()?.includes?.(valueInput?.toLowerCase()) ||
          String(poliza?.total).toLowerCase()?.includes?.(valueInput?.toLowerCase()) ||
          (["completed", "completada"].includes(valueInput?.toLowerCase())
            ? !poliza.pendiente
            : false) ||
          (["uncompleted", "incompleta"].includes(valueInput?.toLowerCase())
            ? poliza.pendiente
            : false)
      )
    );
  };

  const openConfirmation = (idPolicy) => {
    setId(idPolicy);
    setShowConfirmacion(true);
  }

  const cleanPolicie = async () => {
    setLoading(true);

    try {
      const res = await apiGet(urlCambiarEstadoPoliza + '&idPoliza=' + id);
      if (res?.data?.transaction) {
        const mappedPolizas = polizas.filter(poliza => poliza.uid !== id);
        setPolizas(mappedPolizas);
        setValue(mappedPolizas);
      }
    } catch (error) {
    }
    setId(null);
    setShowConfirmacion(false);
    setLoading(false);
  }

  return (
    <Loading message='Loading' isLoading={loading}>
      <div className='container-fluid p-3'>
        <HeaderLogo />
        <div className='row justify-content-center'>
          <div className='col-11 col-lg-6 my-3'>
            <InputGroup>
              <InputGroup.Text>{t("I_FRONT_56")}:</InputGroup.Text>

              <FormControl
                type='text'
                className='form-control'
                ref={refBuscador}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onClickBuscar();
                  }
                }}
              />
              <button
                className='btn rounded-right btn-aigpr-no-block text-white'
                onClick={onClickBuscar}>
                <i className='fa fa-chevron-circle-right' aria-hidden='true' />
                {t("I_FRONT_57")}
              </button>
            </InputGroup>
          </div>
          <div className='col-11' style={{ marginBottom: "10rem" }}>
            <DataTable
              value={value}
              showGridlines
              autoLayout
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 50]}
              emptyMessage={t("I_FRONT_58")}>
              {IndexColumn()}
              <Column
                header={t("I_FRONT_67")}
                field='createdAt'
                body={(rowData) => {
                  const dateFormat = t("dddd[,] MMMM DD[,] YYYY, [at] ");
                  const date = moment(rowData?.createdAt, "DD-MM-YYYY HH:mm:ss");
                  const hora = moment(rowData?.createdAt, "DD-MM-YYYY HH:mm:ss");
                  const dateMoment =
                    date.format(dateFormat).toString().toUpperCase() +
                    hora.format("hh:mm:ss a");
                  return <React.Fragment>{dateMoment}</React.Fragment>;
                }}
              />
              <Column header={t("I_FRONT_68")} field='polNumber.invoice' />
              <Column header={t("I_FRONT_69")} field='usuario' />
              <Column header={t("I_FRONT_70")} field='ramo' />
              <Column header={t("I_FRONT_71")} field='total' />
              <Column
                header={t("I_FRONT_74")}
                body={(rowData) => {
                  if (rowData.pendiente) {
                    return (
                      <div className="row d-flex justify-content-center flex-wrap">
                        <div className="col-5">
                          <Link
                            to={`/generador/${rowData.url}/policy-form?id=${rowData.uid}`}>
                            <button className='btn btn-aigpr text-white'>
                              {t("I_FRONT_72")}
                            </button>
                          </Link>
                        </div>
                        <div className="col-5">
                          <button className='btn btn-aigpr text-white col-5' onClick={() => openConfirmation(rowData.uid)}>
                            {t("VOID")}
                          </button>
                        </div>
                      </div>
                    );
                  }
                  return (
                    <button className='btn btn-aigpr text-white' disabled>
                      {t("I_FRONT_73")}
                    </button>
                  );
                }}
              />
            </DataTable>
            <Confirmation texto={'Are you sure you want to void this policy?'} onContinuar={cleanPolicie} setShowConfirmacion={setShowConfirmacion} showConfirmacion={showConfirmacion} />
          </div>
        </div>
      </div>
    </Loading>
  );
};

export default ListadoPolizas;
