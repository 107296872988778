/*eslint eqeqeq: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-array-constructor: "off"*/
/*eslint react-hooks/exhaustive-deps: "off"*/
import { Confirmation } from "components/Confirmation/Confirmation";
import { IndexColumn } from "components/Table/Columns";
import useHistory from "hooks/useHistory";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { Accordion, InputGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { apiGet, consumirServicioEncriptacionGCM } from "services/api";
import { urlCambiarEstadoCotizacion, urlListCotizaciones } from "services/urls";
import { scrollTop } from "utils/Funciones";
import { Loading } from "../Loading/Loading";
import "./styles.scss";

export const ContainerListCotizaciones = () => {
  const [loading, setLoading] = useState(false);
  const refBuscador = useRef(null);
  const [fetchedData, setFetchedData] = useState([]);
  const [leads, setLeads] = useState([]);
  const [, setTruncados] = useState([]);
  const [id, setId] = useState(null);
  const [showConfirmacion, setShowConfirmacion] = useState(false)
  const { t } = useTranslation();
  const history = useHistory();
  const { dataUsuario } = useSelector((state) => state.login);
  const { cliCodigoCore } = dataUsuario;

  const mapItemsTable = (items, ramosMap) => {
    const dateFormat = t("dddd[,] MMMM DD[,] yyyy, [at] ");
    return items.map((value, index) => {
      const date = moment(value?.carCreatedAt * 1);
      const hora = moment(value?.carCreatedAt * 1);
      const dateMoment =
        date.format(dateFormat).toString().toUpperCase() + hora.format("hh:mm:ss a");

      const nameClient = value?.carContratante?.datosCliente?.companyName
        ? value?.carContratante?.datosCliente?.companyName
        : `${value?.carContratante?.datosCliente?.usuNombre || ""} ${value?.carContratante?.datosCliente?.lastName1 || ""
        } ${value?.carContratante?.datosCliente?.lastName2 || ""}`;
      return {
        ...value,
        fecha: dateMoment,
        nameClient,
        "#": index + 1,
      };
    });
  };

  const fetchData = async () => {
    setLoading(true);
    const body = {
      codigoAgente: cliCodigoCore,
    };
    try {
      const response = await consumirServicioEncriptacionGCM(urlListCotizaciones, body);
      if (response?.data) {
        const result = mapItemsTable(response.data, response.ramosfiltro);
        setFetchedData(result);
        setLeads(result);
      }
    } catch (error) { }

    scrollTop();
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const bodyVerTotales = (row) => {
    if (row?.carContratante?.datosCliente?.coverageDesired?.value === "individual" && row?.carProdName != "management_consultants") {

      return row?.carProductos?.planPrimaAnual
    }

    return <>
      <Accordion flush>
        <Accordion.Item eventKey={0} >
          <Accordion.Header >
            <div className="d-flex flex-wrap justify-content-between font-12px">
              Options
            </div>
          </Accordion.Header>
          <Accordion.Body>
            {Object.values(row.carProductos)?.map(tot => (<div className="text-center">
              {tot?.premium}
            </div>))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>

  }


  const bodyVerDetalles = (row) => {
    const carProdName = row?.carProdName || "social-worker";
    const step = row?.pasoCarrito;
    let path = "/";
    if (carProdName && step) {
      path = `/generador/${carProdName.replaceAll("_", "-")}/${step.replaceAll(
        "_",
        "-"
      )}?id=${row._id}`;
    }
    return (
      <div className="row d-flex flex-wrap justify-content-center col-12 p-2">
        <div className="col-6">
          <button className='btn btn-primary btn-aigpr' onClick={() => history.push(path)}>
            {t("I_FRONT_75")}
          </button>
        </div>
        <div className="col-6">
          <button className='btn btn-aigpr text-white' onClick={() => openConfirmation(row._id)}>
            {t("VOID")}
          </button>
        </div>
      </div>

    );
  };

  const cleanQuote = async () => {
    setLoading(true);

    try {
      const res = await apiGet(urlCambiarEstadoCotizacion + '&idLead=' + id);
      if (res?.data?.transaction) {
        const mappedLeads = leads.filter(lead => lead._id !== id);
        setLeads(mappedLeads);
      }
    } catch (error) {
    }
    setId(null);
    setShowConfirmacion(false);
    setLoading(false);
  }

  const openConfirmation = (idPolicy) => {
    setId(idPolicy);
    setShowConfirmacion(true);
  }

  const onClickBuscar = () => {
    const text = refBuscador.current.value.toLowerCase();
    if (text === "") {
      setLeads(fetchedData);
      return;
    }

    const result = fetchedData.filter((item) => {
      return (
        item?.fecha?.toLowerCase()?.includes(text) ||
        item?.carDescripcionRamo?.toLowerCase()?.includes(text) ||
        item?.carProductos?.planPrimaAnual?.toString()?.includes(text) ||
        item?.nameClient?.toLowerCase()?.includes(text)
      );
    });

    setLeads(result);
  };

  useEffect(() => {
    const formatter = new Intl.NumberFormat("en-US", {
      currency: "USD",
      maximumFractionDigits: 2,
    });
    const valoresTruncados = leads.map((item) => {
      if (item?.primaTotal && !isNaN(item?.primaTotal)) {
        const primaTotal = formatter.format(parseFloat(item?.primaTotal));
        return { ...item, primaTotal };
      } else {
        return item;
      }
    });

    setTruncados(valoresTruncados);
  }, [leads]);

  return (
    <Loading message={`${t("I_FRONT_35")}...`} isLoading={loading}>
      <div className='container pt-3'>
        <Row className='mb-3 justify-content-center'>
          <div className='col-lg-8 mb-3'>
            <InputGroup>
              <InputGroup.Text>{t("I_FRONT_56")}:</InputGroup.Text>

              <input
                type='text'
                className='form-control'
                ref={refBuscador}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onClickBuscar();
                  }
                }}
              />

              <button
                className='btn rounded-right btn-aigpr-no-block text-white'
                onClick={onClickBuscar}>
                <i className='fa fa-chevron-circle-right' aria-hidden='true' />
                {t("I_FRONT_57")}
              </button>
            </InputGroup>
          </div>
        </Row>

        {fetchedData.length > 0 && (
          <Row>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <DataTable
                value={leads}
                responsive
                className='p-datatable-gridlines mb-5 p-datatable-sm'
                rowHover
                paginator
                rows={20}
                emptyMessage={t("I_FRONT_58")}
                resizableColumns
                autoLayout>
                {IndexColumn()}
                <Column
                  field='fecha'
                  header={t("I_FRONT_67")}
                  sortable
                  style={{ width: "auto" }}
                />
                <Column field='nameClient' header={t("I_FRONT_69")} sortable />
                <Column field='carDescripcionRamo' header={t("I_FRONT_70")} sortable />
                <Column
                  body={bodyVerTotales}
                  header={t("I_FRONT_71")}
                  sortable
                  style={{ width: "auto" }}
                />
                <Column
                  header={t("I_FRONT_48")}
                  body={bodyVerDetalles}
                  style={{ width: "auto" }}
                />
              </DataTable>
            </div>
          </Row>
        )}
        {fetchedData.length === 0 && (
          <h1 className='text-center font-mex my-5'>{t("I_FRONT_58")}</h1>
        )}
        <div className='col-12 d-flex justify-content-center mb-5'>
          <div className='form-contenedor-type1 col-3'>
            <button
              id='btn-login-ingresar'
              className='btn btn-primary login-btn'
              onClick={() => {
                history.push("/");
              }}>
              {t("I_FRONT_9")}
            </button>
          </div>
        </div>
        <Confirmation texto={'Are you sure you want to void this quote?'}
          onContinuar={cleanQuote}
          setShowConfirmacion={setShowConfirmacion}
          showConfirmacion={showConfirmacion} />
      </div>
    </Loading>
  );
};
