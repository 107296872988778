import { Accordion, Button } from 'react-bootstrap';
import { TextInput } from 'components/Inputs/inputs'
import { emailRegex } from 'components/Inputs/validaciones'
import { format } from 'helpers/functions'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { selectProduct, selectTerrorism } from 'redux/actions/productActions';
import { useForm } from 'react-hook-form';




export const DynamicSummary = ({ downloadPDF, generarPDF, sendEmail, loading, catalogoService, onChange, planSelected, onSubmit }) => {

    const { t } = useTranslation();
    const { selectedOption, selectedTerrorism } = useSelector((state) => state.product);
    const { assessmentPercentage } = useSelector((state) => state.ui);
    const [catalogo, setCatalogo] = useState(null);
    const [checkedState, setCheckedState] = useState(
        []
    );
    const dispatch = useDispatch();
    const methods = useForm({ mode: "onChange" });

    useEffect(()=>{
        dispatch(selectProduct(null));
        dispatch(selectTerrorism(false));

    }, [])

    useEffect(() => {
        setCatalogo(catalogoService);
        const arr = new Array(Object.entries(catalogoService).length).fill(false);
        if (planSelected) {
            const index = Object.values(catalogoService)?.findIndex(plan => planSelected?.planCodigoCore === plan.planCodigoCore);
            arr[index] = true;

            onChange(planSelected?.planCodigoCore);
            //setSelectedOption(Object.values(catalogoService)?.[index]);
            dispatch(selectProduct(Object.values(catalogoService)?.[index]));

        }
        setCheckedState(arr)
    }, [catalogoService, onChange, planSelected]);

    useEffect(() => {
     
            console.log('selectedOption',selectedOption)
      
        
    }, [selectedOption, selectTerrorism]);


    const showOption = useCallback(
        (option, index) => {
            setCheckedState(checkedState.map((item, i) => {
                if (i === index) {

                    console.log('optuon', option)
                    console.log('methods', methods.formState)

                    onChange(!item ? option?.planCodigoCore : null);
                    // setSelectedOption(!item ? option : null);
                    dispatch(selectProduct(!item ? option : null));
                    dispatch(selectTerrorism(false));
                    return !item
                }
                return false
            }))
        },
        [checkedState, onChange]
    )



    return (
        <><div className="order-2 row col-lg-6 col-md-12 col-sm-12  d-flex flex-wrap justify-content-center">



            <Accordion flush>
                {
                    catalogo && Object.entries(catalogo).map(([key, option], index) => (
                        <Accordion.Item key={'optionsToogle' + key + index} eventKey={index} >
                            <Accordion.Header >
                                <div className="d-flex flex-wrap justify-content-between col-11">
                                    <div className='col-6'>

                                        <input type="checkbox" checked={checkedState[index]} className='check-planes mx-2' onChange={() => showOption(option, index)}></input>
                                        {option?.planNombre}
                                    </div>
                                    <div className='col-6 text-end px-2'>

                                   
                                        {option['Umbrella Premium'] ? format( (selectedTerrorism ?Math.round( (option['Umbrella Premium']+ option['Umbrella Premium']*5/100 ) * assessmentPercentage ) :  option['Assessment Fee']) + option['Umbrella Premium']+ (selectedTerrorism ? Math.round(option['Umbrella Premium']*5/100):0)) : format(option?.premium ? option?.premium : option?.planPrimaAnual)}
                                    </div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                {option?.limits?.label &&
                                    <div className="col-12 row d-flex border-bottom pb-1">
                                        <div className="col-4 d-flex justify-content-start">
                                            {t('I_FRONT_38')}
                                        </div>
                                        <div className="col-4">

                                        </div>
                                        <div className="col-4 d-flex justify-content-end">

                                            {option?.limits?.label}

                                        </div>
                                    </div>
                                }

                                {
                                    option?.deductible?.label &&

                                    <div className="col-12 row d-flex border-bottom pb-1">
                                        <div className="col-4 d-flex justify-content-start">
                                            {t('I_FRONT_39')}
                                        </div>
                                        <div className="col-4 d-flex justify-content-center">

                                        </div>
                                        <div className="col-4 d-flex justify-content-end">
                                            {option?.deductible?.label}
                                        </div>
                                    </div>
                                }


                                {(option && option.hasOwnProperty('revenue') && option?.revenue !=null ) &&

                                    <div className="col-12 row d-flex border-bottom pb-1">
                                        <div className="col-4 d-flex justify-content-start">
                                            {t('I_FRONT_81')}
                                        </div>
                                        <div className="col-4 d-flex justify-content-center">

                                        </div>
                                        <div className="col-4 d-flex justify-content-end">
                                            {(option?.revenue)}
                                        </div>
                                    </div>

                                }

                                {option?.premium &&
                                    <div className="col-12 row d-flex border-bottom pb-1">
                                        <div className="col-4 d-flex justify-content-start">
                                            {t('I_FRONT_40')}
                                        </div>
                                        <div className="col-4 d-flex justify-content-center">

                                        </div>
                                        <div className="col-4 d-flex justify-content-end">
                                            {format(option?.premium)}
                                        </div>
                                    </div>
                                }

                                {option.hasOwnProperty('assessment')  &&
                                    <div className="col-12 row d-flex border-bottom pb-1">
                                        <div className="col-4 d-flex justify-content-start">
                                            {t('I_FRONT_41')}
                                        </div>
                                        <div className="col-4 d-flex justify-content-center">

                                        </div>
                                        <div className="col-4 d-flex justify-content-end">
                                            {format(option?.assessment)}
                                        </div>
                                    </div>

                                }

                                {option['Limits'] &&
                                    <div className="col-12 row d-flex border-bottom pb-1">
                                        <div className="col-4 d-flex justify-content-start">
                                            {'Limits'}
                                        </div>
                                        <div className="col-4 d-flex justify-content-center">

                                        </div>
                                        <div className="col-4 d-flex justify-content-end">
                                            {(option['Limits'])}
                                        </div>
                                    </div>

                                }

                              

                                {option['Limits of liability'] &&
                                    <div className="col-12 row d-flex border-bottom pb-1">
                                        <div className="col-4 d-flex justify-content-start">
                                            {'Limits of liability'}
                                          
                                        </div>
                                        <div className="col-4 d-flex justify-content-center">

                                        </div>
                                        <div className="col-4 d-flex justify-content-end">
                                            {(option['Limits of liability'])}
                                        </div>
                                    </div>

                                }

                                {(option['Loss Incurred'] && !option.hasOwnProperty('Umbrella Premium')) &&
                                    <div className="col-12 row d-flex border-bottom pb-1">
                                        <div className="col-4 d-flex justify-content-start">
                                            {'Loss Incurred'}
                                        </div>
                                        <div className="col-5 d-flex justify-content-center">

                                        </div>
                                        <div className="col-3 d-flex justify-content-end">
                                            {(option['Loss Incurred'])}
                                        </div>
                                    </div>

                                }
                                {(option.hasOwnProperty('Total Base Premium') && !option.hasOwnProperty('Umbrella Premium')) &&
                                    <div className="col-12 row d-flex border-bottom pb-1">
                                        <div className="col-4 d-flex justify-content-start">
                                            {'Total Base Premium'}
                                        </div>
                                        <div className="col-4 d-flex justify-content-center">

                                        </div>
                                        <div className="col-4 d-flex justify-content-end">
                                            {(format(option['Total Base Premium']))}
                                        </div>
                                    </div>

                                }


                            </Accordion.Body>
                        </Accordion.Item>
                    ))
                }

            </Accordion>
        </div>

            <div className="order-1 row col-lg-6 col-md-10 col-sm-12 border  d-flex flex-wrap justify-content-center p-3">
                {selectedOption ?
                    <>

                        <h4 className="text-darkblue text-left">{t('I_FRONT_49')}</h4>
                

                        {selectedOption?.premium &&
                            <div className=" col-12 d-flex justify-content-center pt-2 mt-3 ">
                                <div className="col-4 d-flex justify-content-start">
                                    {t('I_FRONT_40')}
                                </div>
                                <div className="col-4 d-flex justify-content-center">

                                </div>
                                <div className="col-4 d-flex justify-content-end">
                                    {format(selectedOption?.premium)}
                                </div>




                            </div>
                        }

                        {selectedOption && selectedOption['Premium'] &&
                            <div className=" col-12 d-flex justify-content-center pt-2 mt-3 ">
                                <div className="col-4 d-flex justify-content-start">
                                    {t('I_FRONT_40')}
                                </div>
                                <div className="col-4 d-flex justify-content-center">

                                </div>
                                <div className="col-4 d-flex justify-content-end">
                                    {format(selectedOption['Premium'])}
                                </div>




                            </div>
                        }

                    




                        {selectedOption && selectedOption.hasOwnProperty('Assessment Fee') &&
                            <div className=" col-12 d-flex justify-content-center pt-2 mt-3 ">
                                <div className="col-4 d-flex justify-content-start">
                                    {t('I_FRONT_41')}
                                </div>
                                <div className="col-4 d-flex justify-content-center">

                                </div>
                                <div className="col-4 d-flex justify-content-end">
                                    {selectedTerrorism ? format(Math.round( (selectedOption['Premium']+ selectedOption['Premium']*5/100 ) * assessmentPercentage )) : format(selectedOption['Assessment Fee'])}
                                </div>




                            </div>
                        }

                        {selectedTerrorism &&
                            <div className=" col-12 d-flex justify-content-center pt-2 mt-3 ">
                                <div className="col-4 d-flex justify-content-start">
                                    {'Terrorism'}
                                </div>
                                <div className="col-4 d-flex justify-content-center">

                                </div>
                                <div className="col-4 d-flex justify-content-end">
                                    {format(Math.round( selectedOption['Premium']*5/100))}
                                </div>




                            </div>
                        }


                        {selectedOption.hasOwnProperty('assessment')  &&

                            <div className=" col-12 d-flex justify-content-center pt-2 mt-3 ">
                                <div className="col-4 d-flex justify-content-start">
                                    {t('I_FRONT_41')}
                                </div>
                                <div className="col-4 d-flex justify-content-center">

                                </div>
                                <div className="col-4 d-flex justify-content-end">
                                    {format(selectedOption?.assessment)}
                                </div>
                            </div>
                        }

                        <div className=" col-12 d-flex justify-content-center pt-2 mt-3 mb-5 ">
                            <div className="col-4 d-flex justify-content-start">
                                <h4 className="text-darkblue text-left">{t('I_FRONT_50')}</h4>
                            </div>
                            <div className="col-4 d-flex justify-content-center">

                            </div>
                            {selectedOption?.premium &&

                                <div className="col-4 d-flex justify-content-end">
                                    <h4>{format(selectedOption?.premium * 1 + selectedOption?.assessment * 1)}</h4>
                                </div>

                            }
                            {selectedOption['Umbrella Premium'] &&

                                <div className="col-4 d-flex justify-content-end">
                                    <h4>{format( (selectedTerrorism ?Math.round( (selectedOption['Premium']+ selectedOption['Premium']*5/100 ) * assessmentPercentage ) :  selectedOption['Assessment Fee']) + selectedOption['Umbrella Premium']+ (selectedTerrorism ? Math.round(selectedOption['Umbrella Premium']*5/100):0))}</h4>
                                </div>

                            }

                        </div>
                    </> : <div className='text-darkblue'>
                        <p>
                            Step #1. Click Download Quote and provide to prospect.
                        </p>
                        <p>
                            Step #2. Select the option the prospect has agreed to purchase
                        </p>
                        <p>
                            Step #3. Click Continue to begin the issuance process.
                        </p>
                    </div>
                }
                <div className=" col-12 d-flex justify-content-center pt-2 mb-3 ">
                    <div className="col-8 p-1" >

                        <Button
                            className={`mx-auto text-center w-100 outline-aigpr col`}
                            onClick={() => { downloadPDF(selectedOption?.planCodigoCore) }}
                            disabled={generarPDF}
                        >
                            <span className="text-wrap">DOWNLOAD QUOTE</span>
                        </Button>
                    </div>
                </div>
                <div className="text-darkblue col-12 d-flex justify-content-between pt-2 mb-3 ">
                    {t('I_FRONT_76')}
                </div>
                <div className="text-darkblue col-12 d-flex justify-content-between pt-2 mb-3 ">
                    <TextInput
                        extrasClasses={'col-6'}
                        inputProps={{
                            type: 'email',
                            name: 'email',
                            autoComplete: 'off',
                        }}
                        rules={{
                            required: t('W_FRONT_62'),
                            pattern: {
                                value: emailRegex,
                                message: t('E_FRONT_63'),
                            },
                        }}

                    /><Button
                        className={`mx-auto text-center  w-100 btn-aigpr col`}
                        onClick={() => sendEmail()}
                        disabled={loading}
                    >
                        <span className="text-wrap">{(loading) ? t('I_FRONT_55') : t('I_FRONT_54')}</span>
                    </Button>
                </div>
            </div></>
    )
}
