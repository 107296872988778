import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";
import useCustomRef from "hooks/useCustomRef";
import _, { forIn } from "lodash";
import moment from "moment";
import normalizeText from "normalize-text";
import { Button } from "primereact/button";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import styled from "styled-components";
import { ComboDatePicker } from "./ComboDatePicker";
import { SimpleContainer } from "./containers";
import Campos from "components/DynamicForm/components/Campos";
import { htmlMessage } from "components/DynamicForm/utils/funcionesGenerales";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import en from "date-fns/locale/en-US";
import "react-datepicker/dist/react-datepicker.css";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { format } from "helpers/functions";
import { selectTerrorism } from "redux/actions/productActions";




/* eslint no-eval: 0 */

export const TextInput = ({
  rules,
  inputProps,
  extrasClasses = "",
  onChangeBn,
  onChangeFn,
  defaultValue = "",
}) => {
  return (
    <Controller
      name={inputProps.name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { ref, ...field } }) => (
        <input
          ref={ref}
          id={inputProps.name}
          name={inputProps.name}
          type={inputProps?.type || "text"}
          className={`${extrasClasses} border p-3`}
          onChange={(evt) => {
            let value = evt.target.value;
            if (onChangeBn) {
              value = onChangeBn(evt.target.value);
            }
            field.onChange(value);
            onChangeFn && onChangeFn(value);
          }}
          {...field}
        />
      )}
    />
  );
};

export const NumberInput = ({
  rules,
  extrasClasses,
  hideArrows = false,
  defaultValue = null,
  name,
  ...rest
}) => {
  const { control } = useFormContext();
  const { focusRef, setRef } = useCustomRef();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      onFocus={focusRef}
      render={({ field: { value, onChange } }) => (
        <InputNumber
          className='input__mask__mex'
          value={value}
          onChange={(e) => {
            onChange(e.value);
          }}
          name={name}
          style={{
            border: "none",
            outline: "none",
            fontFamily: "SourceSansPro-Regular",
          }}
          // mode="decimal"
          ref={setRef("input")}
          inputId={name}
          {...rest}
        />
      )}
    />
  );
};

export const NumberInputDisabled = ({
  rules,
  extrasClasses,
  hideArrows = false,
  defaultValue = null,
  name,
  ...rest
}) => {
  const { control, getValues } = useFormContext();
  const { focusRef, setRef } = useCustomRef();
  const [suma, setSuma] = useState(0);

  useEffect(() => {
    const c3 = parseFloat(getValues('c3Umbrella') || 0);
    const c4 = parseFloat(getValues('c4Umbrella') || 0);
    const c5 = parseFloat(getValues('c5Umbrella') || 0);
    const c6 = parseFloat(getValues('c6Umbrella') || 0);
    const c7 = parseFloat(getValues('c7Umbrella') || 0);

    const newSuma = c3 + c4 + c5 + c6 + c7;
    if (!isNaN(newSuma)) {
      setSuma(newSuma);
    }
  }, [getValues('c3Umbrella'), getValues('c4Umbrella'), getValues('c5Umbrella'), getValues('c6Umbrella'), getValues('c7Umbrella')]);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      onFocus={focusRef}
      render={({ field: { value, onChange } }) => {

        // onChange(suma);

        return (
          <InputNumber
            className='input__mask__mex'
            value={suma}
            disabled={true}
            onChange={(e) => {
              // field.onChange(e.value);
            }}
            name={name}
            style={{
              border: "none",
              outline: "none",
              fontFamily: "SourceSansPro-Regular",
            }}
            // mode="decimal"
            ref={setRef("input")}
            inputId={name}
            {...rest}
          />
        )
      }}
    />
  );
};

export const PrimeTextInput = ({
  rules,
  inputProps,
  extrasClasses,
  hideArrows = false,
  defaultValue = null,
  name,
  disabled = false,
  isUpper = false,
  onChangeFn,
  loading = false,
  placeHolder = "",
  showEyeIcon = false,
  tipoProducto = '',
  type = "text",
  disabledTravel = false,
  ...rest
}) => {
  const { control } = useFormContext();
  const [typePassword, setTypePassword] = useState(true);
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onChange, value, ref } }) => (
        <div className=' row col-12 d-flex'>
          <InputText
            ref={ref}
            className={`${extrasClasses} col${type !== "password" ? "-12" : "-8"
              } shadow-none`}
            value={value || ""}
            placeholder={placeHolder}
            onChange={(e) => {
              if (isUpper) {
                onChange(e.currentTarget.value.toUpperCase());
              } else {
                onChange(e.currentTarget.value);
              }
              onChangeFn && onChangeFn(e.currentTarget.value);
            }}
            name={name}
            style={{
              border: "none",
              outline: "none",
              fontFamily: "SourceSansPro-Regular",
            }}
            type={typePassword ? type : "text"}
            {...rest}
            disabled={disabledTravel ? true : tipoProducto === 'fastTrack' ? false : disabled}
          />
          {type === "password" && (
            <div className='p-0 col-4 d-flex justify-content-end align-items-center'>
              <Button
                type='button'
                style={{ borderRadius: "25%" }}
                className='p-button-text p-button-plain font-20px shadow-none mx-3'
                onClick={() => {
                  setTypePassword(!typePassword);
                }}>
                <i
                  className={typePassword ? "pi pi-eye" : "pi pi-eye-slash"}
                  style={{ fontSize: "1rem" }}
                />
              </Button>
            </div>
          )}
        </div>
      )}
    />
  );
};

export const TextInputField = (props) => {
  const { name, label, rules, disabled = false, sources, helpText, ...rest } = props;
  const methods = useFormContext();
  const value = methods.watch(name);
  const { setValue } = methods;
  const onChangeFn = useMemo(() => {
    if (typeof rest?.onChangeFn === "string") {
      return (val) => {
        try {
          const newFunction = eval(rest.onChangeFn.toString());
          return newFunction({
            ...props,
            methods,
            val,
          });
        } catch (error) {
          return () => {
            //Empty function
          };
        }
      };
    }
    return () => {
      //Empty function
    };
  }, [rest?.onChangeFn, methods, props]);

  useEffect(() => {
    if ((!value || value === "") && rest?.preload) {
      const defaultValue = _.get(
        sources,
        rest?.preload?.sourcePath,
        rest?.preload?.defaultValue
      );
      setValue(name, defaultValue);
    }
  }, [value, rest.preload, sources, name, setValue]);

  return (
    <div
      className={classNames("form-group mt-3", {
        "d-none": rest.hide,
        "col-md-6 col-lg-5": !rest?.column,
        "col-md-12 col-lg-12": rest?.column,
      })}>
      <SimpleContainer label={label} name={name} helpText={helpText} info={rest?.info}>
        <PrimeTextInput
          extrasClasses={"col-md-12 col-lg-12"}
          name={name}
          readOnly={disabled}
          isUpper={!!rest?.isUpper}
          rules={rules}
          onChangeFn={onChangeFn}
        />
      </SimpleContainer>
    </div>
  );
};

const customStylesDropDown = {
  menu: (provided, state) => ({
    ...provided,
    color: state.selectProps.menuColor,
    marginLeft: "-1em",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontWeigth: "bold",
    color: "#6c757d",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    fontSize: "11px",
    fontWeigth: "bold",
  }),
  control: (styles) => ({
    ...styles,
    border: "none",
    boxShadow: "none",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = "#001871";
    return {
      ...styles,
      // backgroundColor: isDisabled
      //   ? null
      //   : isSelected
      //   ? data.color
      //   : isFocused
      //   ? "#e9ecef"
      //   : null,
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? data.color : color),
        color: "white",
      },
    };
  },
};

const SelectStyled = styled.select`
  width: 99%;
  border: none;
  outline: none;
  font-family: SourceSansPro-Regular, serif;
  font-size: 14px;
  color: #151515;
  padding-left: 15px;
  font-weight: 600;
  height: 32px;
`;

const Option = styled.option`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  font-family: SourceSansPro-Regular, serif;
  font-size: 16px;
  color: #151515;
  padding-left: 15px;
  font-weight: 600;
  background-color: white;
`;
export const DropDown = ({
  options = [],
  callbackOnChange,
  name,
  rules,
  defaultValue = null,
  placeholder,
  includePlaceholder = true,
  onChangeFn,
  optionValue = "value",
  ...props
}) => {
  const methods = useFormContext();
  const { control } = methods;

  const { focusRef, setRef } = useCustomRef();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      //defaultValue={options.length > 0 && JSON.stringify(options[0].value)}
      defaultValue={defaultValue}
      onFocus={focusRef}
      render={({ onChange, value }) => {
        return (
          <SelectStyled
            id={name}
            onChange={({ target }) => {
              const parsedValue = JSON.parse(target.value);
              onChange(parsedValue);
              onChangeFn && onChangeFn(parsedValue, methods);
            }}
            value={JSON.stringify(value)}
            {...props}
            ref={setRef}>
            {includePlaceholder && (
              <Option value={JSON.stringify("")}>{placeholder || "SELECCIONE"}</Option>
            )}
            {options?.map?.((option, index) => (
              <Option key={index} value={JSON.stringify(option.value)}>
                {option.label}
              </Option>
            ))}
          </SelectStyled>
        );
      }}
    />
  );
};

export const DropDownFilter = ({
  name,
  rules,
  onSelect = () => { console.log('Event do not controlled') },
  optionLabel = "label",
  optionValue = "value",
  options = [],
  onChangeFn = null,
  dataKey,
  loading,
  disabled = false,
  defaultValue = null,
  placeholder = "SELECT",
  loadingText = "SEARCHING...",
  helpText,
  filter = true,
  emptyFilterMessage = "NO INFORMATION FOUND",
  isClearable = true,
  fontSizeClass = '',
  //value,
  ...props
}) => {
  const { control } = useFormContext();
  const objectPath = require("object-path");

  const opciones = options?.map((option) => {
    return {
      label: objectPath.get(option, optionLabel),
      value: objectPath.get(option, optionValue),
    };
  });

  const internalPlaceHolder = useMemo(
    () => (loading ? loadingText : placeholder),
    [loading, placeholder, loadingText]
  );

  return (
    <React.Fragment>
      <Controller
        //as={Select}
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field: { onChange, ref, value } }) => {
          const label = opciones?.find(
            (item) => JSON.stringify(item?.value) === JSON.stringify(value)
          )?.label;
          let normalizado = {
            label,
            value,
          };

          let esValido = normalizado.label && normalizado.value;

          if (!esValido && !!defaultValue) {
            normalizado = {
              label: defaultValue.label,
              value: defaultValue,
            };
            esValido = true;
          }

          return (
            <React.Fragment>
              <Select
                ref={ref}
                className={'w-100 p-0 ' + fontSizeClass}
                id={name}
                defaultValue={defaultValue}
                isClearable={isClearable}
                isSearchable={true}
                isDisabled={loading || disabled}
                isLoading={loading}
                noOptionsMessage={() => emptyFilterMessage}
                loadingMessage={() => {
                  return "Buscando...";
                }}
                value={esValido ? normalizado : internalPlaceHolder}
                name={name}
                options={opciones}
                onChange={(evt) => {
                  if (evt) {
                    onChange(evt?.value);
                    onChangeFn && onChangeFn(evt.value || "", name);
                    onSelect(evt.value || "");
                  } else {
                    onChange(null);
                    onChangeFn && onChangeFn(null);
                  }
                }}
                placeholder={internalPlaceHolder}
                styles={customStylesDropDown}
              />
            </React.Fragment>
          );
        }}
      />
    </React.Fragment>
  );
};

const Error = styled.strong`
  font-family: "Futura-Medium";
  font-size: 15px;
  color: #cf0e18 !important;
  line-break: normal;
  width: ${(props) => props.width || "100%"} !important;
`;

export const SimpleErrorMessage = ({ name }) => {
  const { errors } = useFormContext();
  return (
    <ErrorMessage
      name={name}
      errors={errors}
      render={({ message }) => <Error>{message}</Error>}
    />
  );
};

export const SimpleCheckBox = ({
  name,
  label,
  rules = {},
  defaultValue = false,
  id,
  type = "normal",
  subCampos,
}) => {
  return (
    <div className='row my-2'>
      <Controller
        name={name}
        defaultValue={defaultValue}
        rules={rules}
        render={(field) => (
          <div className='col-1' style={{ marginRight: "-1em" }}>
            <input type='checkbox' id={id} className='chek-terminos' {...field} />
            {type === "slide" && (
              <label htmlFor={name} className='check-type-slide'></label>
            )}
          </div>
        )}
      />
      <div className='col-11'>
        <label htmlFor={name} className='disclaimers-auto'>
          {label}
          <br></br>
          <SimpleErrorMessage name={name} />
        </label>
      </div>
    </div>
  );
};

export const SimpleRadio = ({
  name,
  label,
  rules = {},
  defaultValue = false,
  id,
  type = "normal",
  subCampos,
  infoCoverageDesired = null,
  infoUmbrella = null,
  options
}) => {


  const optionFinded = options.find(option => option.value.value == "individual")


  if (infoCoverageDesired && optionFinded) {

    if (infoCoverageDesired.value) {
      infoCoverageDesired = infoCoverageDesired.value
    } else {
      infoCoverageDesired = infoCoverageDesired
    }

    options = options.map(option => {
      console.log('infoCoverageDesired', infoCoverageDesired.value, option.value.value)
      return {
        ...option,
        disabled:
          (infoCoverageDesired == 'organization' && (option.value.value == "individual") ||
            (infoCoverageDesired == 'individual' && (option.value.value != "individual")))

      }
    })



  }



  return (
    <div >
      {htmlMessage(label)}
      {options?.map((option, index) => (
        <Controller
          name={name}
          defaultValue={infoUmbrella ? 'disabled' : optionFinded &&
            infoCoverageDesired &&
            infoCoverageDesired == 'individual'

            ? {
              value: optionFinded.value.value,
              label: optionFinded.value.label,
              checked: true
            } : defaultValue}
          rules={rules}
          render={({ field: { value, onChange, name } }) => {


            if (infoCoverageDesired && infoCoverageDesired.value == 'individual') {
              const optionFinded = options.find(option => option.value.value == "individual")

              if (optionFinded) {



              }


            }



            return (



              <React.Fragment>

                <input
                  id={option.label}
                  disabled={option.disabled || infoUmbrella}
                  type="radio"
                  name={name}
                  className="align-self-center"
                  checked={value?.checked && value?.label == option.label}
                  value={value}
                  onChange={(e) => {


                    onChange({
                      ...option?.value,
                      checked: e.target.checked,
                    });
                  }}
                />
                <label
                  htmlFor={option.label}
                  className="align-self-center ms-2"
                  style={{ color: (option.disabled || infoUmbrella) ? '#dddddd' : null }}
                >
                  {option.label}
                </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

              </React.Fragment>
            )
          }}
        />

      ))}

      <div className='col-11'>
        <label htmlFor={name} className='disclaimers-auto'>

          <br></br>
          <SimpleErrorMessage name={name} />
        </label>
      </div>
    </div>
  );
};

export const SimpleRadioSeparator = ({
  name,
  label,
  rules = {},
  defaultValue = false,
  id,
  type = "normal",
  subCampos,
  infoCoverageDesired = null,
  options
}) => {

  const { selectedOption } = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const { reset } = useFormContext();

  useEffect(() => {
    if (name == 'checkSummaryUmbrella') {
      reset({ [name]: false });
    }

  }, [selectedOption]);





  if (infoCoverageDesired) {

    options = options.map(option => {

      return {
        ...option,
        disabled:
          (infoCoverageDesired.value == 'organization' && (option.value.value == "individual") ||
            (infoCoverageDesired.value == 'individual' && (option.value.value == "corporation" || option.value.value == "partnership")))

      }
    })

  }

  const optionFinded = options.find(option => option.value.value == "individual")
  return (
    <div >
      {htmlMessage(label)}
      {options?.map((option, index) => (
        <Controller
          name={name}
          defaultValue={optionFinded &&
            infoCoverageDesired &&
            infoCoverageDesired.value == 'individual'

            ? {
              value: optionFinded.value.value,
              label: optionFinded.value.label,
              checked: true
            } : defaultValue}
          rules={rules}
          render={({ field: { value, onChange, name } }) => {


            if (infoCoverageDesired && infoCoverageDesired.value == 'individual') {
              const optionFinded = options.find(option => option.value.value == "individual")

              if (optionFinded) {



              }


            }



            return (

              (

                !option.label.includes('$planPrimaAnual5') ||
                (option.label.includes('$planPrimaAnual5') && selectedOption != undefined)

              )


              &&
              <React.Fragment>

                <Row>
                  <Col md={1}>
                    <input
                      id={option.label}
                      disabled={option.disabled}
                      type="radio"
                      name={name}
                      className="align-self-center"
                      checked={value?.checked && value?.label == option.label}
                      value={value}
                      onChange={(e) => {

                        const valueAux = {
                          ...option?.value,
                          checked: e.target.checked,
                        }

                        if (valueAux.value == 'elect' && valueAux.checked) {
                          dispatch(selectTerrorism(true));
                        } else {
                          dispatch(selectTerrorism(false));
                        }



                        onChange(valueAux);
                      }}
                    />
                  </Col>
                  <Col md={11}>
                    <label
                      htmlFor={option.label}
                      className="align-self-center ms-2"
                      style={{ color: option.disabled ? '#dddddd' : null }}
                    >
                      {option.label.replace('$planPrimaAnual5', selectedOption ? format(Math.round(selectedOption['Premium'] * 5 / 100)) : "$0.00")}
                    </label>


                  </Col>
                </Row>







                {(index == 0) &&
                  <hr></hr>
                }



              </React.Fragment>







            )
          }}
        />

      ))}

      <div className='col-11'>
        <label htmlFor={name} className='disclaimers-auto'>

          <br></br>
          <SimpleErrorMessage name={name} />
        </label>
      </div>
    </div>
  );
};


export const SimpleCheckBoxHorizontal = ({
  name,
  label,
  rules = {},
  defaultValue = false,
  id,
  type = "normal",
  subCampos,
  infoCoverageDesired = null,
  options
}) => {





  if (infoCoverageDesired) {

    options = options.map(option => {

      return {
        ...option,
        disabled:
          (infoCoverageDesired.value == 'organization' && (option.value.value == "individual") ||
            (infoCoverageDesired.value == 'individual' && (option.value.value == "corporation" || option.value.value == "partnership")))

      }
    })

  }

  const optionFinded = options.find(option => option.value.value == "individual")
  return (
    <div >
      {htmlMessage(label)}

      {options?.map((option, index) => (
        <Controller
          name={name}
          defaultValue={optionFinded &&
            infoCoverageDesired &&
            infoCoverageDesired.value == 'individual'

            ? {
              value: optionFinded.value.value,
              label: optionFinded.value.label,
              checked: true
            } : defaultValue}
          rules={rules}
          render={({ field: { value, onChange, name, } }) => {


            if (infoCoverageDesired && infoCoverageDesired.value == 'individual') {
              const optionFinded = options.find(option => option.value.value == "individual")

              if (optionFinded) {



              }


            }



            return (



              <React.Fragment>








                <input
                  id={option.label}
                  type="checkbox"
                  name={name}
                  className="align-self-center"
                  checked={value[option.value.value]?.checked}
                  value={value[option.value.value]?.checked}
                  onChange={(e) => {


                    let aux = {
                      ...value,
                      [option.value.value]: {
                        ...option.value,
                        checked: e.target.checked,
                      }

                    }

                    let validador = false

                    for (const key in aux) {
                      if (aux[key].checked) {
                        validador = true

                      }
                    }

                    console.log('aux', validador)
                    onChange(validador ? aux : false);
                  }}
                />


                <label
                  htmlFor={option.label}
                  className="align-self-center ms-2"
                  style={{ color: option.disabled ? '#dddddd' : null }}
                >
                  {option.label}
                </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

              </React.Fragment>
            )
          }}
        />

      ))}

      <div className='col-11'>
        <label htmlFor={name} className='disclaimers-auto'>

          <br></br>
          <SimpleErrorMessage name={name} />
        </label>
      </div>
    </div>
  );
};

export const SimpleCheckBoxHorizontalBasic = ({
  name,
  label,
  rules = {},
  defaultValue = false,
  id,
  type = "normal",
  subCampos,
  infoCoverageDesired = null,
  options,
  onChangeMain,
  valueMain,
  disabled = false
}) => {





  if (infoCoverageDesired) {

    options = options.map(option => {

      return {
        ...option,
        disabled:
          (infoCoverageDesired.value == 'organization' && (option.value.value == "individual") ||
            (infoCoverageDesired.value == 'individual' && (option.value.value == "corporation" || option.value.value == "partnership")))

      }
    })

  }

  const optionFinded = options.find(option => option.value.value == "individual")
  return (
    <div >
      {htmlMessage(label)}

      {options?.map((option, index) => (
        <Controller
          name={name}
          defaultValue={valueMain ? valueMain : optionFinded &&
            infoCoverageDesired &&
            infoCoverageDesired.value == 'individual'

            ? {
              value: optionFinded.value.value,
              label: optionFinded.value.label,
              checked: true
            } : defaultValue}
          rules={rules}
          render={({ field: { value, onChange, name, } }) => {


            if (infoCoverageDesired && infoCoverageDesired.value == 'individual') {
              const optionFinded = options.find(option => option.value.value == "individual")

              if (optionFinded) {



              }


            }



            return (



              <React.Fragment>








                <input
                  id={option.label}
                  type="checkbox"
                  name={name}
                  className="align-self-center"
                  checked={value[option.value.value]?.checked}
                  disabled={disabled}
                  value={value[option.value.value]?.checked}
                  onChange={(e) => {


                    let aux = {
                      ...value,
                      [option.value.value]: {
                        ...option.value,
                        checked: e.target.checked,
                      }

                    }

                    let validador = false

                    for (const key in aux) {
                      if (aux[key].checked) {
                        validador = true

                      }
                    }

                    console.log('aux', validador)
                    onChange(validador ? aux : false);
                    onChangeMain(validador ? aux : false);
                  }}
                />


                <label
                  htmlFor={option.label}
                  className="align-self-center ms-2"
                  style={{ color: option.disabled ? '#dddddd' : null }}
                >
                  {option.label}
                </label><br></br>

              </React.Fragment>
            )
          }}
        />

      ))}

      <div className='col-11'>
        <label htmlFor={name} className='disclaimers-auto'>

          <br></br>
          <SimpleErrorMessage name={name} />
        </label>
      </div>
    </div>
  );
};


export const DropDownDate = ({
  name,
  rules,
  outFormat = "yyyy-MM-DD",
  defaultValue = null,
  maxDate = new Date(),
  minDate = null,
  ...props
}) => {
  const { control, getValues } = useFormContext();
  const [, setInternal] = useState(null);
  const { focusRef, setRef } = useCustomRef();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Controller
        name={name}
        control={control}
        rules={{
          setValueAs: (value) => (value ? moment(value).format(outFormat) : value),
          ...rules,
        }}
        defaultValue={getValues(name)}
        // defaultValue={defaultValue}
        onFocus={focusRef}
        render={({ field: { value, onChange } }) => {
          return (
            <ComboDatePicker
              id={name}
              name={name}
              date={new Date(value)}
              maxDate={maxDate}
              months={`${t("January")},${t("February")},${t("March")},${t("April")},${t(
                "May"
              )},${t("June")},${t("July")},${t("August")},${t("September")},${t(
                "October"
              )},${t("November")},${t("December")}`}
              placeholder={[t("Year"), t("Month"), t("Day")]}
              order='mdy'
              subref={setRef}
              onChange={(_picker, date) => {
                setInternal(date);
                if (
                  "invalid date" !== normalizeText(String(date)).toLowerCase() &&
                  "fecha invalida" !== normalizeText(String(date)).toLowerCase() &&
                  date !== null &&
                  date !== "" &&
                  date !== undefined
                ) {
                  onChange(date);
                } else {
                  onChange(null);
                }
              }}
              attrsDate={{ disabled: props?.disabled }}
              attrsMonth={{ disabled: props?.disabled }}
              attrsYear={{ disabled: props?.disabled }}
              {...props}
            />
          );
        }}
      />
    </React.Fragment>
  );
};

export const TextInputMask = ({
  name,
  rules,
  mask = "999999",
  //unmask = true,
  unmask = true,
  replaces = [],
  defaultValue = null,
  ...props
}) => {
  const { control } = useFormContext();
  const { focusRef, setRef } = useCustomRef();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      onFocus={focusRef}
      render={({ field }) => (
        <InputMask
          className='input__mask__mex'
          mask={mask}
          name={name}
          value={field.value}
          autoClear={false}
          onChange={(e) => {
            field.onChange(e.value);
          }}
          unmask={unmask}
          style={{
            border: "none",
            outline: "none",
          }}
          {...props}
          id={name}
          ref={setRef("input")}
        />
      )}
    />
  );
};

export const DivText = ({
  preload = false,
  name = "",
  rules,
  label = "",
  subCampos,
  disabled = false,
  options = [],
  columnas = 12,
  ...rest
}) => {
  const newSubCampos = useMemo(
    () =>
      subCampos?.map?.((item) => ({
        ...item,
        name: `${name}Fields.${item.name}`,
        containerProps: {
          ...(item?.containerProps || {}),
          name: `${name}Fields.${item.name}`,
        },
      })) || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={`col-md-${columnas} d-flex flex-wrap mt-5 justify-content-around`}>
      <div className='col-12'>
        {htmlMessage(label)}
        <SimpleErrorMessage name={name} />
      </div>
      {newSubCampos && newSubCampos?.length > 0 && (
        <div className='col-12'>
          <div className='row justify-content-center'>
            <div className='col-11'>
              <div className='row'>
                <Campos campos={newSubCampos} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const DateCalendar = ({
  name = "",
  rules,
  fechaInicio,
  fechaFin,
  placeholder,
  preload = null,
  onChangeMain = null,
  valueMain = null,
  initialDate = new Date(),
  dateFormat = "EEEE, MMMM dd, yyyy",
}) => {
  const { control, setValue } = useFormContext();
  registerLocale("en-US", en);

  useEffect(() => {
    if(valueMain){
      if(validarFecha(valueMain)){
        setValue(name, new Date(valueMain))
      }
    }
  }, [valueMain]);

  function validarFecha(fecha) {
    const date = new Date(fecha);
    return date instanceof Date && !isNaN(date);
  }
  

  return (
    <React.Fragment>

      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={valueMain ? new Date(valueMain) : initialDate}
        render={({ field: { value = new Date(), onChange } }) => {
          return (
            <React.Fragment>
              <ReactDatePicker
                locale="en"
                selected={value}
                onChange={(date) => {
                  onChange(date)
                  if (onChangeMain) {
                    onChangeMain(date)
                  }
                }
                }
                minDate={new Date(fechaInicio)}
                maxDate={new Date(fechaFin)}
                placeholderText={placeholder}
                className="picker border-0"
                isClearable
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChangeRaw={(e) => {
                  e.preventDefault();
                }}
                dateFormat={dateFormat}
              />
            </React.Fragment>
          );
        }}
      />
    </React.Fragment>
  );
};
