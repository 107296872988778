import { SimpleWrapper } from "components/Inputs/containers";
import { TextInput } from "components/Inputs/inputs";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";


export const FormChangePassword = ({
  onSubmit,
  errorMsg,
  showError,
  names,
  labelBtn,
}) => {
  const methods = useForm({ mode: "onChange" });
  const { watch, handleSubmit } = methods;
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const { passActual, passUno, passDos } = names;

  const onError = () => {
    addToast("Enter a valid password", {
      appearance: "warning",
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        {passActual && (
          <SimpleWrapper label={t("I_FRONT_15")} name={passActual.name}>
            <TextInput
              inputProps={{
                name: passActual.name,
                placeholder: t("I_FRONT_15"),
                type: "password",
              }}
              extrasClasses="pass-input"
              rules={passActual.rules}
            />
          </SimpleWrapper>
        )}

        {passUno && (
          <SimpleWrapper label={t("I_FRONT_11")} name={passUno.name}>
            <TextInput
              inputProps={{
                name: passUno.name,
                placeholder: t("I_FRONT_11"),
                type: "password",
              }}
              extrasClasses="pass-input"
              rules={{
                ...passUno.rules,
                validate:
                  passUno.rules.validate && passUno.rules.validate(watch),
              }}
            />
          </SimpleWrapper>
        )}

        {passDos && (
          <SimpleWrapper label={t("I_FRONT_66")} name={passDos.name}>
            <TextInput
              inputProps={{
                name: passDos.name,
                placeholder: t("I_FRONT_66"),
                type: "password",
              }}
              extrasClasses="pass-input"
              rules={{
                ...passDos.rules,
                validate:
                  passDos.rules.validate && passDos.rules.validate(watch),
              }}
            />
          </SimpleWrapper>
        )}

        {showError && (
          <div className="text-center mt-2 text-danger">
            <span className="font-mex msg-error">{errorMsg}</span>
          </div>
        )}
        <div className="row mt-3 justify-content-center">
          <div className="col-10">
            <button
              id="btn-login-ingresar"
              className="btn btn-primary login-btn"
              type="submit"
            >
              {labelBtn || t("I_FRONT_10")}
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
