import { ESTADO_P2P_LABELS } from "constants/P2P";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { strToDateFormat } from "utils/functions";

/**
 * @typedef {object} PaymentInfoProps
 * @property {string} resultLabel
 * @property {"APROBADO"|"PENDIENTE"|"RECHAZADO"} resultValue
 * @property {string} policyRefLabel
 * @property {string|any} policyRefValue
 * @property {string} paymentRefLabel
 * @property {string|any} paymentRefValue
 * @property {string} paymentDateLabel
 * @property {string|any} paymentDateValue
 * @property {boolean} formatDate
 * @property {string} dateFormat
 * @param {PaymentInfoProps} props
 * @returns
 */
const PaymentInformation = (props) => {
  const { t } = useTranslation();

  const newDate = useMemo(
    () =>
      props.formatDate
        ? strToDateFormat(props.paymentDateValue, props.dateFormat)
        : props.paymentDateValue,
    [props.paymentDateValue, props.formatDate, props.dateFormat]
  );

  return (
    <React.Fragment>
      <h6>
        {t(props.resultLabel)}: {t(ESTADO_P2P_LABELS[props.resultValue])}
      </h6>
      <h6>
        {t(props.policyRefLabel)}: {props.policyNumber? props.policyNumber.replace('135-A','135').replace('135-B','135').replace('135-C','135').replace('135-D','135'):props.policyRefValue ? props.policyRefValue.replace('135-A','135').replace('135-B','135').replace('135-C','135').replace('135-D','135') : ''}
      </h6>
      <h6>
        {t(props.paymentRefLabel)}: {props.paymentRefValue}
      </h6>
      {newDate && (
        <h6>
          {t(props.paymentDateLabel)}: {newDate}
        </h6>
      )}
    </React.Fragment>
  );
};

PaymentInformation.defaultProps = {
  resultLabel: "Result",
  policyRefLabel: "Policy ref",
  paymentRefLabel: "Payment ref",
  paymentDateLabel: "Date",
  formatDate: true,
  dateFormat: undefined,
};

export default PaymentInformation;
