import React from "react";
import { Provider } from "react-redux";
import { store } from "store";
import { AppRoutes } from "./router/AppRoutes";
import { ToastProvider } from "react-toast-notifications";
const App = () => {
  return (
    <Provider store={store}>
      <ToastProvider
        autoDismiss
        autoDismissTimeout={20000}
        placement="top-center"
      >
        <AppRoutes />
      </ToastProvider>
    </Provider>
  );
};

export default App;
