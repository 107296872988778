import { types } from "redux/types/types";
import { consumirServicioEncriptacionGCM } from "services/api";
import { urlLogin } from "services/urls";
import { loadProducts } from "./productActions";
import { errorLogin, isLoading, stopLoading } from "./uiActions";

export const loginAES = (body, t) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const data = await consumirServicioEncriptacionGCM(urlLogin, body);
    if (data.transaccion && data.transaccion === true) {
      const dataUser = {
        correo: data.cliEmail,
        cedula: data.cliIdentificacion,
        nombres: !data.cliNombre2
          ? data.cliNombre1
          : data.cliNombre1 + " " + data.cliNombre2,
        apellidos: !data.cliApellido2
          ? data.cliApellido1
          : data.cliApellido1 + " " + data.cliApellido2,
        sucursal: data.cliSucursal,
        sucursalCodCore: data.sucCodigoCore,
        errorDescripcion: data.errorDescripcion,
        userIsLoggedIn: true,
        cliCodigoCore: data.cliCodigoCore,
        usu_tipo_usuario: data.cliTipo,
        brokerRUC: data?.brokerRUC || "",
        brokerNombre: data?.brokerNombre || "",
        brokerCodigo: data?.brokerCodigo || "",
        fechaUltimoAcceso: data?.fechaUltimoAcceso,
        nombreAgente: data.nombreAgente,
        codigoAgente: data.codigoAgente,
        cambiarContrasenia: data?.cambiarContrasenia || false,
        agente: data?.agente,
        agenciaCodigo: data?.agenciaCodigo || "",
      };

      if (
        (!data?.agenciaCodigo || data?.agenciaCodigo === "-" || data?.agenciaCodigo === "")
      ) {
        dispatch(stopLoading());
        return {
          description: t(
            "The user does not belong to any agency, contact the administrator"
          ),
        };
      }
      if (data?.cambiarContrasenia) {
        dispatch(stopLoading());
        return { expires: true, cliCodigoCore: data.cliCodigoCore };
      } else {
        if (data?.productos) {
          const prods = JSON.parse(data?.productos);
          dispatch(loadProducts(prods || []));
        }
        dispatch(login(dataUser));
      }
    } else {
      dispatch(stopLoading());
      return {
        description: data?.errorDescripcion || "Verify your email or password",
      };
    }
  } catch (error) {
    dispatch(stopLoading());
    dispatch(errorLogin());
  }
  dispatch(stopLoading());
};

export const login = (user) => {
  return {
    type: types.LOGIN,
    payload: { ...user },
  };
};

export const logout = () => {
  return {
    type: types.LOGOUT,
  };
};
