import { useToasts } from 'react-toast-notifications';
import React from 'react';

const useCustomToast = () => {
  const { addToast, ...rest } = useToasts();

  const baseText = (content) => (
    <div
      className="font-source-sans-regular"
      style={{
        width: '100%',
        fontWeight: 'bold',
        fontSize: '20px',
        paddingTop: '1rem',
        paddingBottom: '1rem',
      }}
    >
      <div
        style={{ fontSize: "15px", fontFamily: "Futura-Medium" }}
        dangerouslySetInnerHTML={{
          __html: content,
        }
        }
      />
    </div>
  );

  const baseToast = (content, type, extraProps = {}) => {
    addToast(baseText(content), { ...extraProps, appearance: type });
  };

  return {
    ...rest,
    addSuccessToast: (content, extraProps) =>
      baseToast(content, 'success', extraProps),
    addErrorToast: (content, extraProps) => baseToast(content, 'error', extraProps),
    addWarningToast: (content, extraProps) =>
      baseToast(content, 'warning', extraProps),
    addInfoToast: (content, extraProps) => baseToast(content, 'info', extraProps),
  };
};

export default useCustomToast;
