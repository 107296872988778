import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";
import { ModalInfo } from "components/DynamicForm/components/Modal/Modal";
import { Button } from "primereact/button";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  ContainerField,
  ContainerFormField,
  ContainerInput,
  Error,
  HelpTextComponent,
  Label,
  SubLabel,
} from "../styledComponents";

Object.byString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  const a = s.split(".");
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

export const InputContainer = ({
  children,
  name,
  label,
  subLabel,
  labelProps = {},
}) => {
  const { formState } = useForm();
  const { errors } = formState;
  let hasError;
  if (errors) {
    hasError = Object.byString(errors, name) !== undefined;
  }
  let className = "full-input-2";
  let showCheck = false;
  if (formState.dirtyFields[name]) {
    if (!hasError) {
      showCheck = true;
      className = "full-input-2-active";
    }
  }

  return (
    <React.Fragment>
      <div className={className}>
        <label {...labelProps}>
          {label}
          {showCheck && <div className={"img-input-active "}>&nbsp;</div>}
        </label>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
            {children}
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <div className="mt-2">{subLabel}</div>
          </Col>
        </Row>
        {Object.byString(errors, name) && (
          <strong className="error-msg-input">{errors[name].message}</strong>
        )}
      </div>
    </React.Fragment>
  );
};

const htmlMessage = (mssg) => {
  return (
    <div
      style={{
        // fontSize: "1rem",
        fontFamily: "Futura-Medium"
      }}
      dangerouslySetInnerHTML={{
        __html: mssg,
      }}
    />
  );
};

export const SimpleContainer = ({
  children,
  name,
  label,
  subLabel = null,
  helpText,
  info = null,
  labelProps = {},
  hide = false,
  classContainer = "",
  dialogHelpText = null,
  disabled= false,
  align = "center",
}) => {
  const { t } = useTranslation();
  const { formState: { errors } = [], watch } = useFormContext();
  const [showModal, setshowModal] = useState(false);
  const msgSelected = watch(name);
  return (
    <ContainerFormField
      className={classNames({
        "d-none": hide,
      })}
    >
      <Label
        htmlFor={name}
        {...labelProps}
        className={`d-flex align-items-${align}`}
      >
        {htmlMessage(label)}{" "}
        {(info !== null || dialogHelpText !== null) && (
          <Button
            type="button"
            style={{ borderRadius: "25%" }}
            className="p-button-text p-button-plain p-0 pl-1 font-20px shadow-none mx-3"
            tooltip={info ?? ""}
            onClick={() => {
              dialogHelpText && setshowModal(true);
            }}
          >
            <i className="pi pi-question-circle" style={{ fontSize: "1rem" }} />
          </Button>
        )}
      </Label>

      <ContainerInput
        className={" d-flex-column " + classContainer}
        hasError={!!errors[name]}
       style={{backgroundColor:disabled?'#dddddd':null}}
      >
        <div className="d-inline-flex w-100">
          <ContainerField className="w-100">{children}</ContainerField>
          {subLabel && (
            <div className="col-3 col-md-2 text-left">
              <SubLabel>{subLabel}</SubLabel>
            </div>
          )}
        </div>
        {helpText && (
          <div
            className="d-flex flex-row justify-content-center"
            style={{ margin: "0 0 0 0" }}
          >
            <HelpTextComponent width="95%">{helpText}</HelpTextComponent>
          </div>
        )}
        {dialogHelpText && (
          <div
            className="d-flex flex-row justify-content-center"
            style={{ margin: "0 0 0 0" }}
          >
            <ModalInfo
              show={showModal}
              setShowModal={setshowModal}
              messageInfo={dialogHelpText}
            />
          </div>
        )}
        <div
          className="d-flex flex-row justify-content-center"
          style={{ marginTop: "0", marginBottom: "0" }}
        >
          <ErrorMessage
            name={name}
            errors={errors}
            render={({ message }) => <Error width="95%">{message}</Error>}
          />
          {/* <SimpleErrorMessage name={name} /> */}
        </div>
      </ContainerInput>
      {msgSelected?.infoOnSelect && (
        <h6 className="text-muted mt-3 mb-3">{t(msgSelected?.infoOnSelect)}</h6>
      )}
    </ContainerFormField>
  );
};

export const SimpleWrapper = ({
  children,
  label,
  subLabel = null,
  name,
  extrasClasses,
}) => {
  const {
    formState,
    formState: { errors },
  } = useFormContext();
  let hasError = Object.byString(errors, name) !== undefined;
  let showCheck = false;
  if (formState.dirtyFields[name]) {
    showCheck = !hasError;
  }
  return (
    <React.Fragment>
      <div className={"container w-100" + extrasClasses}>
        <label
          className="accidentes text-left"
          htmlFor={name}
          style={{ fontSize: "20px" }}
        >
          {label}
          {showCheck && <div className="img-input-active ">&nbsp;</div>}
        </label>
        <div className="container-input-accidentes">
          <div className="row">
            <div
              className={classNames({
                "my-2": true,
                "col-10": subLabel !== null,
                "col-12": !subLabel,
              })}
            >
              {children}
            </div>
            {subLabel && (
              <div className="col-2 mt-2">
                <div className="placeholder-full-input-type1 mt-2 mb-2">
                  {subLabel}
                </div>
              </div>
            )}
          </div>
          {Object.byString(errors, name) && (
            <strong className="error-msg-input">
              {Object.byString(errors, name).message}
            </strong>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export const SimpleContainerHorizontal = ({
  children,
  name,
  label,
  subLabel = null,
  helpText,
  info = null,
  labelProps = {},
  hide = false,
  classContainer = "",
  dialogHelpText = null,
  align = "center",
  orientation = "horizontal",
  ...rest
}) => {
  const { t } = useTranslation();
  const { formState: { errors } = [], watch } = useFormContext();
  const [showModal, setshowModal] = useState(false);
  const msgSelected = watch(name);
  return (
    <div
      className={
        "row " +
        classNames({
          "d-none": hide,
        })
      }
    >
      <div
        className={`col-xs-12 col-${orientation === "horizontal" ? "md-5" : "md-12"
          }`}
      >
        <Label
          htmlFor={name}
          // style={{ fontSize: "1.25rem" }}
          {...labelProps}
          className={`d-flex align-items-${align}`}
        >
          {label}{" "}
          {(!!info || !!dialogHelpText) && (
            <Button
              type="button"
              style={{ borderRadius: "25%" }}
              className="p-button-text p-button-plain p-0 pl-1 font-20px shadow-none mx-3"
              tooltip={info ?? ""}
              onClick={() => {
                dialogHelpText && setshowModal(true);
              }}
            >
              <i
                className="pi pi-question-circle"
                style={{ fontSize: "1rem" }}
              />
            </Button>
          )}
        </Label>
      </div>
      <div
        className={`col-xs-12 col-${orientation === "horizontal" ? "md-7" : "md-12"
          }`}
      >
        <ContainerInput
          className={"d-flex-column " + classContainer}
          hasError={!!errors[name]}
        >
          <div className="d-inline-flex w-100">
            <ContainerField className="w-100">{children}</ContainerField>
            {subLabel && (
              <div className="col-3 col-md-2 text-left">
                <SubLabel>{subLabel}</SubLabel>
              </div>
            )}
          </div>
          {helpText && (
            <div
              className="d-flex flex-row justify-content-center"
              style={{ margin: "0 0 0 0" }}
            >
              <HelpTextComponent width="95%">{helpText}</HelpTextComponent>
            </div>
          )}
          {dialogHelpText && (
            <div
              className="d-flex flex-row justify-content-center"
              style={{ margin: "0 0 0 0" }}
            >
              <ModalInfo
                show={showModal}
                setShowModal={setshowModal}
                messageInfo={dialogHelpText}
              />
            </div>
          )}
          <div
            className="d-flex flex-row justify-content-center"
            style={{ marginTop: "0", marginBottom: "0" }}
          >
            <ErrorMessage
              name={name}
              errors={errors}
              render={({ message }) => <Error width="95%">{message}</Error>}
            />
            {/* <SimpleErrorMessage name={name} /> */}
          </div>
        </ContainerInput>
      </div>
      {msgSelected?.infoOnSelect && (
        <div className="col-12">
          <h6 className="text-muted mt-3 mb-3">
            {t(msgSelected?.infoOnSelect)}
          </h6>
        </div>
      )}
    </div>
  );
};
