import classNames from "classnames";
import React from "react";
import "./breadcrumb.scss";

export const BreadCrumb = ({
  title,
  subtitle,
  img,
  steps = [],
  subtitleStyles = {},
  subtitleClassnames = "",
  styleimg = "",
  pasosActivos,
}) => {
  return (
    <div className='row justify-content-center mt-3'>
      <div className='col-md-12 col-xl-12'>
        <div className='w-100 d-flex flex-row'>
          <div className='d-none d-sm-block align-self-center mx-3'>
            {styleimg !== "" && <img src={img} className={styleimg} alt='' />}
            {styleimg === "" && (
              <img
                src={img}
                className='img-fluid'
                alt=''
                style={{ height: "200px" }}
                loading='lazy'
              />
            )}
          </div>
          <div className='d-flex flex-column align-self-center'>
            <h1 className={`text-app d-flex letter-spacing`} style={{ fontSize: "50px" }}>
              {title &&
                title?.split?.(" ")?.map?.((word, index) => {
                  return (
                    <p
                      key={`${title}${index}`}
                      className={`${index === 0 ? "text-darkblue" : "text-lightblue"}`}
                      style={{
                        fontSize: "50px",
                        marginLeft: `${index > 0 ? "15px" : "0px"}`,
                      }}>
                      {word}
                    </p>
                  );
                })}
            </h1>
            <div>
              <ul className='step-breadcrumb mt-1'>
                {steps.map((step, index) => {
                  return (
                    <li
                      key={`${step.title}${index}`}
                      className={classNames({
                        active: pasosActivos?.includes?.(index + 1),
                      })}
                      style={{ fontSize: "20px" }}>
                      {step.title}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={subtitleClassnames} style={subtitleStyles}>
              <h5 className='subtitle-breadcum-cot-tipo2' style={{ fontSize: "19px" }}>
                {subtitle}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
