import useHistory from "hooks/useHistory";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const withValidarCambioContrasenia = (WrappedComponent) => () => {
  const { dataUsuario } = useSelector((state) => state.login);
  const isValid =
    dataUsuario?.userIsLoggedIn && !dataUsuario?.cambiarContrasenia;
  const history = useHistory();

  useEffect(() => {
    if (isValid) {
      history("/");
    }
  }, [history, isValid]);

  return <React.Fragment>{!isValid && <WrappedComponent />}</React.Fragment>;
};

export default withValidarCambioContrasenia;
