import MisPagosP2p from "components/PlaceToPay/MisPagos";
import PaymentInformation from "components/PlaceToPay/PaymentInformation";
import React from "react";
import { useTranslation } from "react-i18next";
import { dollarFormater } from "utils/functions";
import hands from "./assets/img/hands.png";

const DetallePoliza = ({
  isProcessSuccess,
  errorMessage,
  policyNumber,
  downloadPolicyButtonProps,
  downloadApplicationButtonProps,
  paymentInformation,
  idPoliza,
}) => {
  const { t } = useTranslation();

  return (
    <div className='d-flex flex-wrap justify-content-center'>
      <div className='text-center'>
        {isProcessSuccess && (
          <React.Fragment>
            <img style={{ maxWidth: "100px" }} src={hands} alt='' />
            <h3 className='my-4'>{t("Congratulations!")}</h3>
            <p className='mt-2'>
              {t(
                "Your insurance policy with AIG Insurance Company Puerto Rico has been issued"
              )}
            </p>
            {policyNumber && (
              <h3 className='my-3'>
                {t("Policy number")}: {policyNumber.replace('135-A','135').replace('135-B','135').replace('135-C','135').replace('135-D','135')}
              </h3>
            )}

            {paymentInformation && (
              <div>
                <h4>{dollarFormater.format(paymentInformation?.total)} USD</h4>
                <PaymentInformation {...{...paymentInformation, policyNumber}} />
                <MisPagosP2p idPoliza={idPoliza} />
              </div>
            )}

            <div className='d-flex flex-wrap justify-content-center justify-content-md-between mt-2'>
              {downloadPolicyButtonProps && (
                <button
                  className='btn btn-aigpr-outlined rounded-0 me-lg-3 my-2'
                  style={{ width: "230px" }}
                  {...downloadPolicyButtonProps}>
                  {t("DOWNLOAD POLICY")}
                </button>
              )}
              {downloadApplicationButtonProps && (
                <button
                  className='btn btn-aigpr-outlined rounded-0 ms-lg-3 my-2'
                  style={{ width: "230px" }}
                  {...downloadApplicationButtonProps}>
                  {t("DOWNLOAD APPLICATION")}
                </button>
              )}
            </div>
          </React.Fragment>
        )}
        {!isProcessSuccess && <p>{errorMessage}</p>}
      </div>
    </div>
  );
};

export default DetallePoliza;
