import React from 'react'
import { Modal } from 'react-bootstrap';
import './modal.scss'

export const ModalInfo = ({ messageInfo = '', setShowModal, show = false }) => {
    return (
        <Modal
            show={show}
            onHide={() => setShowModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered

        >
            <Modal.Body>
                <div className="row justify-content-center p-5">
                    <div className="d-flex justify-content-end ">
                        <button onClick={() => setShowModal(false)} className="btn-close text-muted"><span aria-hidden="true"></span></button>
                    </div>
                    <div
                        style={{ fontSize: "15px", fontFamily: "Futura-Medium" }}
                        dangerouslySetInnerHTML={{
                            __html: messageInfo,
                        }
                        }
                    />

                </div>
            </Modal.Body>
        </Modal>
    )
}
