import { useState } from 'react';
import { consumirServicioEncirptacion } from 'services/api';
import { urlGetCatalogoUbicacion } from 'services/urls';

const useFindCities = () => {
    const [cities, setCities] = useState([]);

    const findCities = async (codCountry, codMunicipality, setLoadingCities) => {
        if (codCountry && codMunicipality) {
            setLoadingCities(true);
            const Data = {
                selectedType: "province",
                params:
                {
                    paiCodigoCore: codCountry,
                    provCodigoCore: codMunicipality
                }
            };
            try {


                const res = await consumirServicioEncirptacion(urlGetCatalogoUbicacion, Data, false, true);
                const data = res?.data?.collections?.map?.(city => {
                    return {
                        value: city,
                        label: city?.ciuNombre
                    }
                }) || [];
                setCities(data);
                setLoadingCities(false);
                return data;
            } catch (error) {
                setLoadingCities(false);
            }

        }
        return [];
    };

    return { findCities, cities, setCities };
};

export default useFindCities;
