/* eslint-disable react-hooks/exhaustive-deps */
import { SimpleErrorMessage } from "components/Inputs/inputs";
import React, { useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useCustomToasts from "../../../../hooks/useCustomToast";
import Campos from "../Campos";
import _ from "lodash";
export const YesNoComponent = ({
  preload = false,
  name = "",
  rules,
  label = "",
  subCampos,
  disabled = false,
  options = [],
  aprobRetroDate,
  retroDate,
  campo1,
  campo2,
  campo3,
  ...rest
}
) => {


  const { t } = useTranslation();

  const methods = useFormContext({ mode: "onChange" });
  const selected = methods.watch(name);
  const { addWarningToast } = useCustomToasts();

  useEffect(() => {
    if (rules?.isValue) {
      if (selected && rules?.isValue?.value === "true") {
        addWarningToast(rules?.isValue.message, "warning");
      }
    }
  }, [selected, t]);

  const value = methods.watch(name);
  const newSubCampos = useMemo(
    () =>
      subCampos
        ?.filter?.((item) => item?.whenValue === value)
        ?.map?.((item) => ({
          ...item,
          name: `${name}Fields.${item.name}`,
          containerProps: {
            ...(item?.containerProps || {}),
            name: `${name}Fields.${item.name}`,
          },
        })) || [],
    [value]
  );

  const htmlMessage = (mssg) => {
    return (
      <div
        style={{
          // fontSize: "1rem",
          fontFamily: "Futura-Medium",
          opacity: (disabled || (aprobRetroDate && name== campo1)) && "0.6",
        }}
        dangerouslySetInnerHTML={{
          __html: mssg,
        }}
      />
    );
  };
  let yes = "Yes";
  let no = "No";
  if (options.length > 0) {
    yes = options[0].label ?? "Yes";
    no = options[1].label ?? "No";
  }

  return (
    <div className="col-md-12 d-flex flex-wrap mt-5 justify-content-around">
      <div className="col-md-8 col-sm-12">
        {htmlMessage(label)}
        <SimpleErrorMessage name={name} />
      </div>
      <Controller
        name={name}
        rules={rules}
        render={({ field: { value, ref, onChange } }) => (
          <React.Fragment>
            <div className="col-md-2 col-sm-6 d-flex justify-content-end">
              <label className="containerCheck">
                {t(yes)}
                <input
                  name={name}
                  type="checkbox"
                  checked={
                    options.length > 0
                      ? _.isEqual(options[0].value, value)
                      : value === true
                  }
                  disabled={disabled || (aprobRetroDate && name== campo1)}
                  onChange={() => {
                    onChange(options.length > 0 ? options[0].value : true);
                  }}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="col-md-2 col-sm-6 d-flex justify-content-end">
              <label className="containerCheck">
                {t(no)}

                <input
                  name={name}
                  type="checkbox"
                  disabled={disabled || (aprobRetroDate && name== campo1)}
                  checked={
                    options.length > 0
                      ? _.isEqual(options[1].value, value)
                      : value === false
                  }
                  onChange={() => {
                    onChange(options.length > 0 ? options[1].value : false);
                  }}
                />

                <span className="checkmark"></span>
              </label>
            </div>
          </React.Fragment>
        )}
      />

      {(aprobRetroDate && campo1 == name) &&
      <React.Fragment>
        <div>

        <b style={{fontWeight:'bold'}}> {'Retro date: '}</b>
     
        <span style={{opacity:'0.6'}}>{retroDate}</span>

        </div>
      </React.Fragment>
      
      }

      {newSubCampos && newSubCampos?.length > 0 && (
        <div className="col-12">
          <div className="row justify-content-center">
            <div className="col-11">
              <div className="row">
                <Campos campos={newSubCampos} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
