/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import { PAYMENT_TYPES } from "constants/PAYMENT_TYPES";
import useCustomToast from "hooks/useCustomToast";
import useHistory from "hooks/useHistory";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import {
  apiGet,
  consumirServicioEncirptacion,
  consumirServicioEncriptacionGCM,
} from "services/api";
import urls, { urlLayoutPagina } from "services/urls";
import { decryptResponseAES } from "utils/Encriptar/crypto";
import { downloadFileFromUrl, getIp } from "utils/functions";
import { Loading } from "../../pages/Loading/Loading";
import { ambienteAplicacion, empresa, plataforma } from "../../services/urls";
import Content from "./components/Content";
import { useGeneradorPagina, useGeneradorPaginaActions } from "./store";

const GeneradorPaginas = () => {
  const { callbacks, generandoPagina, components, isLoading, container } =
    useGeneradorPagina();
  const { resetState } = useGeneradorPaginaActions();
  const actions = useGeneradorPaginaActions();

  const { producto, pagina } = useParams();
  const toasts = useCustomToast();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const qParams = queryString.parse(location.search);
  const dataUsuario = useSelector((state) => state.login);

  const getPage = async () => {
    try {
      const Data = {
        empresa: "AIGPR",
        layoutCodigo: producto.replaceAll("-", "_"),
      };
      const resp = await consumirServicioEncirptacion(urlLayoutPagina, Data, false, true);
      if (resp?.transaccion) {

        return resp?.data?.pages?.find(
          (page) => page?.layCodigoCore === pagina.replace("-", "_")
        );
      }
    } catch (error) {}
    return history.push("/");
  };

  const init = async () => {
    resetState();

    const resources = {
      callbacks,
      history,
      qParams,
      actions,
      producto,
      pagina,
      moment,
      consumirServicioEncirptacion,
      consumirServicioEncriptacionGCM,
      urls,
      plataforma,
      empresa,
      ambiente: ambienteAplicacion,
      dataUsuario: dataUsuario.dataUsuario,
      apiGet,
      decryptResponseAES,
      getIp,
      addWarningToast: toasts.addWarningToast,
      toasts,
      t,
      payment: {
        TYPES: PAYMENT_TYPES,
      },
      downloadFileFromUrl,
    };
    const page = await getPage();
    actions.generarPagina(page, resources);
  };

  useEffect(() => {
    init();
  }, [producto, pagina]);

  useEffect(() => {
    if (!generandoPagina && components.length > 0) {
      try {
        callbacks.onInit();
      } catch (error) {
        console.log(error);
      }
    }
  }, [generandoPagina, components]);

  return (
    // <Loading isLoading={true}>
    <Loading message={`${t("I_FRONT_35")}...`} isLoading={isLoading || generandoPagina}>
      <main
        className={classNames(container?.classNames, {
          container: !container?.fluid,
          "container-fluid": container?.fluid,
        })}
        {...container?.props}>
        <Content components={components} />
      </main>
    </Loading>
  );
};

export default GeneradorPaginas;
