import { BreadCrumb } from "components/BreadCrumb/BreadCrumb";
import DetallePoliza from "components/DetallePoliza";
import DynamicForm from "components/DynamicForm";
import { YesNoComponent } from "components/DynamicForm/components/YesNoComponent/YesNoComponent";
import { HeaderLogo } from "components/Header/HeaderLogo";
import { InputContainer, SimpleContainer } from "components/Inputs/containers";
import { DropDownFilter, TextInput } from "components/Inputs/inputs";
import PaymentForm from "components/PaymentForm";
import React from "react";
import { useTranslation } from "react-i18next";
import { STEPS_BREADCRUM } from "utils/Constants/BREADCRUM_PRODCUTS";
import UbicacionInput from "../../../components/DynamicForm/components/Ubicacion";
import FormContainer from "../FormContainer";
import { useGeneradorPagina } from "../store";
import Container from "./Container";
import { ProductOptions } from "./ProductOptions/ProductOptions";
import { Summary } from "./Summary/Summary";
import ErrorPage from "pages/ErrorPage/ErrorPage";

/**
 *
 * @param {{
 * components:any[]
 * }} props
 * @returns
 */
const Content = (props) => {
  const { pageState, mappedComponents } = useGeneradorPagina();
  const { t } = useTranslation();

  const mergeProps = (mapped, state, id, node) => {
    return {
      ...mapped?.[id]?.[node],
      ...state?.[id]?.[node],
    };
  };

  return props?.components?.map((component) => {
    let finalComponent = null;
    const { id } = component;
    const isErrorPage = pageState["applicantHonorRetroactiveDate"];
    switch (component.type) {
      case "h1":
        finalComponent = (
          <h1
            {...{
              ...mappedComponents?.[component.id]?.props,
              ...pageState?.[component.id]?.props,
            }}>
            {component?.content}
            {pageState?.[component.id]?.content}
          </h1>
        );
        break;
      case "h3":
        const { divProps: divP, ...restP } = component?.props;
        finalComponent = (
          <div {...divP}>
            <h3 {...{ ...restP }}>{t(component?.content)}</h3>
          </div>
        );
        break;
      case "h5":
        finalComponent = (
          <h5
            {...{
              ...mappedComponents?.[component.id]?.props,
              ...pageState?.[component.id]?.props,
            }}>
            {t(component?.content)}
          </h5>
        );
        break;

      case "headerLogo":
        finalComponent = <HeaderLogo />;
        break;

      case "breadcrum":

        finalComponent = isErrorPage ? <></> : (
          <div {...component?.props?.divProps}>
            <BreadCrumb
              {...STEPS_BREADCRUM[mappedComponents?.[id]?.codProduct]?.breadCrumbSteps[
              mappedComponents?.[id]?.codPage
              ]}
              steps={STEPS_BREADCRUM[mappedComponents?.[id]?.codProduct]?.steps}
            />
          </div>
        );
        break;

      case "button":

        finalComponent = isErrorPage ? <></> : <button key={Math.random()} {...component?.props}>{component?.content}</button>;
        break;

      case "container":
        finalComponent = (
          <Container props={mergeProps(mappedComponents, pageState, id, "props")}>
            <Content components={mappedComponents?.[id]?.childrens} />
          </Container>
        );
        break;
      case "formContainer":
        finalComponent = (
          <FormContainer
            props={mergeProps(mappedComponents, pageState, id, "props")}
            onSubmit={mappedComponents?.[id]?.props?.onSubmit}
            formProps={mappedComponents?.[id]?.formProps}
            id={id}>
            <Content components={mappedComponents?.[id]?.childrens} />
          </FormContainer>
        );
        break;

      case "input":
      case "text":
        const { name, label, labelProps = {}, divProps = {} } = component?.props;
        finalComponent = (
          <>
            {!component?.showOn ||
              component?.showOn.includes(
                pageState[component?.conditionComponent]?.value
              ) ? (
              <div {...divProps}>
                <InputContainer labelProps={labelProps} label={t(label)} name={name}>
                  <TextInput {...component.props} />
                </InputContainer>
              </div>
            ) : (
              <></>
            )}
          </>
        );
        break;
      case "select":
      case "dropdown":
        const { options, ...restProps } = component?.props;
        finalComponent = (
          <SimpleContainer {...restProps}>
            <DropDownFilter
              onChangeFn={() => component?.onChangeFn}
              {...restProps}
              options={options}
            />
          </SimpleContainer>
        );
        break;
      case "ubicacion":
        const countries = pageState[id]?.countries || [];
        finalComponent = (
          <UbicacionInput
            countries={countries}
            configuracion={component?.props?.configuracion}
          />
        );
        break;

      case "options":
        const value = pageState[component?.props.idPlanSelected];
        const optionsPlan = pageState[component?.props.idOptions];
        const applicantHonorRetroactiveDate = pageState["applicantHonorRetroactiveDate"];


        finalComponent = applicantHonorRetroactiveDate ? (
          <ErrorPage
            mainText={pageState["retroTitle"]}
            secondaryText={pageState["retroSubTitle"]}

          />
        ) : (
          optionsPlan?.length > 0 && (
            <ProductOptions
              value={value}
              plans={pageState?.["plans"]}
              tipoProducto={pageState?.["tipoProducto"]}
              infoFastTrack={pageState?.["infoFastTrack"]}
              infoLawyers={pageState?.["infoLawyers"]}
              infoRevenue={pageState?.["infoRevenue"]}
              numLic={pageState?.["numLic"]}
              {...component.props}
              options={optionsPlan}
            />
          )
        );
        break;


      case "div":
        finalComponent = (
          <div {...mergeProps(mappedComponents, pageState, id, "props")}>
            <Content components={mappedComponents?.[id]?.childrens} />
          </div>
        );
        break;
      case "yesNoQuestion":
        finalComponent = <YesNoComponent {...mappedComponents[id]}></YesNoComponent>;
        break;
      case "summary":
        const data = pageState[component?.props?.idSummary];
        const planSel = pageState?.["planSel"];
        finalComponent = (data?.product || data?.products) && <Summary planSel={planSel} {...data} {...component.props} />;
        break;
      case "dynamicForm":
        const { props: dynamicFormProps, value: valueDynamic } = pageState[id];
        finalComponent = dynamicFormProps ? (
          <DynamicForm {...{ ...dynamicFormProps, value: valueDynamic }} infoUmbrella={pageState?.["infoUmbrella"]} infoCoverageDesired={pageState['infoCoverageDesired']} aprobRetroDate={pageState['aprobRetroDate']} retroDate={pageState['retroDate']} campo1={pageState['campo1']} campo2={pageState['campo2']} campo3={pageState['campo3']} />
        ) : (
          <></>
        );
        break;
      case "paymentForm":
        finalComponent = (
          <PaymentForm {...mergeProps(mappedComponents, pageState, id, "props")} />
        );
        break;

      case "policyDetail":
        finalComponent = (
          <DetallePoliza {...mergeProps(mappedComponents, pageState, id, "props")} />
        );
        break;
      default:
        finalComponent = <React.Fragment></React.Fragment>;
        break;
    }

    return <React.Fragment key={id}>{finalComponent}</React.Fragment>;
  });
};

export default Content;
