export const PRODUCTS = {
    'social-worker': {
        title: 'Social Worker',
        id: 'social-worker',
        pages: [
            'lead-detail',
            'questions',
            'options',
            'summary',
            'confirmation'
        ],
    },
    'insurance-agents': {
        title: 'Insurance Agents',
        id: 'insurance-agents',
        pages: [
            'lead-detail',
            'questions',
            'options',
            'summary',
            'confirmation'
        ],
    },
    'real-estate': {
        title: 'Real Estate',
        id: 'real-estate',
        pages: [
            'lead-detail',
            'questions',
            'options',
            'summary',
            'confirmation'
        ],
    },
    'travel-agents': {
        title: 'Travel Agents',
        id: 'travel-agents',
        pages: [
            'lead-detail',
            'questions',
            'options',
            'summary',
            'confirmation'
        ],
    },
    'umbrella': {
        title: 'Umbrella Umbrella Excess',
        id: 'umbrella',
        pages: [
            'lead-detail',
            'questions',
            'options',
            'summary',
            'confirmation'
        ],
    },
    'property-manager': {
        title: 'Property Manager',
        id: 'property-manager',
        pages: [
            'lead-detail',
            'questions',
            'options',
            'summary',
            'confirmation'
        ],
    },
    'psychologist': {
        title: 'Psychologist',
        id: 'psychologist',
        pages: [
            'lead-detail',
            'questions',
            'options',
            'summary',
            'confirmation'
        ],
    },
    'D&O': {
        title: 'D&O',
        id: 'D&O',
        pages: [
            'lead-detail',
            'questions',
            'options',
            'summary',
            'confirmation'
        ],
    },
    'accountants': {
        title: 'Accountants',
        id: 'accountants',
        pages: [
            'lead-detail',
            'questions',
            'options',
            'summary',
            'confirmation'
        ],
    },
    'pharmacists': {
        title: 'Pharmacists',
        id: 'pharmacists',
        pages: [
            'lead-detail',
            'questions',
            'options',
            'summary',
            'confirmation'
        ],
    }, 'management-consultants': {
        title: 'Management Consultants',
        id: 'management-consultants',
        pages: [
            'lead-detail',
            'questions',
            'options',
            'summary',
            'confirmation'
        ],
    }, 'cyber-edge': {
        title: 'Cyber Edge',
        id: 'cyber-edge',
        pages: [
            'lead-detail',
            'questions',
            'options',
            'summary',
            'confirmation'
        ]
    },
    'lawyers': {
        title: 'Lawyers',
        id: 'lawyers',
        pages: [
            'lead-detail',
            'questions',
            'options',
            'summary',
            'confirmation'
        ]
    }
}