import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';

const ShowOnFieldWrapper = ({ showOn, children, name, id }) => {
  const values = useWatch({ name: showOn?.fields });
  const [showing, setShowing] = useState(false);
  useEffect(() => {
    try {
      if (typeof showOn?.custom === 'function') {
        setShowing(showOn.custom(values));
      } else if (values) {
        if (typeof values === 'object') {
          const mappedArray = values.map(val => 
            (typeof val == 'number' && val > 0) ? 'sum' : (val.OTHER && val.OTHER > 0 ? true : (val.other && val.other.checked) ? { label: "Other (specify)", value: "other" } : (typeof val === 'object' && val.label) ? { label: val.label, value: val.value } : val)
            );

          if (name == 'h5ManagementConsultants') {
            const isShowing = Object.entries(mappedArray)
              .map(([fieldName, value], index) => {
                return _.isEqual(showOn?.entries?.[showOn?.fields[index]] == undefined ? false : showOn?.entries?.[showOn?.fields[index]], value)
              }
              )
              .some((value) => value === true);
            setShowing(isShowing);

          } else {

            if (name == 'c3Umbrella') {
              var isShowing = Object.entries(mappedArray)
                .map(([fieldName, value], index) => {

                  console.log('balue', showOn?.entries?.[showOn?.fields[index]])

                  return _.isEqual(showOn?.entries?.[showOn?.fields[index]] == undefined ? false : showOn?.entries?.[showOn?.fields[index]], value)
                }
                )
                .every((value) => value === true);
              console.log('c3Umbrella', isShowing)


              setShowing(isShowing);


            } else {
              const isShowing = Object.entries(mappedArray)
                .map(([fieldName, value], index) => {
                  return _.isEqual(showOn?.entries?.[showOn?.fields[index]] == undefined ? false : showOn?.entries?.[showOn?.fields[index]], value)
                }
                )
                .every((value) => value === true);
              setShowing(isShowing);
            }

          }



        }
      }
    } catch (error) { }
  }, [name, showOn, showOn.custom, showOn.entries, values]);
  return showing ? children : null;
};

export default ShowOnFieldWrapper;
