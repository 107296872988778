/* eslint-disable react-hooks/exhaustive-deps */
import { SimpleErrorMessage } from "components/Inputs/inputs";
import React, { useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useCustomToasts from "../../../../hooks/useCustomToast";
import Campos from "../Campos";
import _ from "lodash";
import useCustomRef from "hooks/useCustomRef";
import { InputNumber } from "primereact/inputnumber";
import { SimpleContainer } from "components/Inputs/containers";
export const NumberDecimalQuestion = ({
  containerProps,
  preload = false,
  name = "",
  rules,
  label = "",
  subCampos,
  disabled = false,
  options = [],
  defaultValue,
  type,
  ...rest
}) => {


  const { t } = useTranslation();

  const methods = useFormContext({ mode: "onChange" });
  const selected = methods.watch(name);
  const { addWarningToast } = useCustomToasts();
  const { setValue, getValues } = useFormContext();

  useEffect(() => {
    if (rules?.isValue) {
      if (selected && rules?.isValue?.value === "true") {
        addWarningToast(rules?.isValue.message, "warning");
      }
    }
  }, [selected, t]);

  const value = methods.watch(name);
  const newSubCampos = useMemo(
    () =>
      subCampos
        ?.filter?.((item) => item?.whenValue === value)
        ?.map?.((item) => ({
          ...item,
          name: `${name}Fields.${item.name}`,
          containerProps: {
            ...(item?.containerProps || {}),
            name: `${name}Fields.${item.name}`,
          },
        })) || [],
    [value]
  );

  const htmlMessage = (mssg) => {
    return (
      <div
        style={{
          // fontSize: "1rem",
          fontFamily: "Futura-Medium",
          opacity: disabled && "0.6",
        }}
        dangerouslySetInnerHTML={{
          __html: mssg,
        }}
      />
    );
  };
  let yes = "Yes";
  let no = "No";
  if (options.length > 0) {
    yes = options[0].label ?? "Yes";
    no = options[1].label ?? "No";
  }
  const { control } = useFormContext();
  const { focusRef, setRef } = useCustomRef();


  return (
    <div className="col-md-12 d-flex flex-wrap mt-5 justify-content-around">
      <div className="col-md-7 col-sm-12" style={{ marginTop: 20 }} >
        {htmlMessage(label)}
      </div>

      <div className="col-md-5 col-sm-12">

        <div className="row">
          <div className="col-md-8">
            <SimpleContainer {...containerProps} label="">

              <Controller
                control={control}
                name={name}
                rules={rules}
                defaultValue={defaultValue}
                onFocus={focusRef}
                render={({ field: { value, onChange } }) => (
                  <InputNumber
                    className='input__mask__mex'
                    disabled={disabled}
                    value={value}
                    onChange={(e) => {
                      onChange(e.value);
                    }}
                    name={name}
                    style={{
                      border: "none",
                      outline: "none",
                      fontFamily: "SourceSansPro-Regular",
                    }}
                    // mode="decimal"
                    ref={setRef("input")}
                    inputId={name}
                    {...rest}
                  />
                )}
              />


            </SimpleContainer>

          </div>
          {(type == 'numberDecimalQuestionPercentage' || type == 'numberDecimalQuestionPercentageCheck') &&

            <div className="col-md-1 color-red thead-center" style={{ marginTop: 20 }} >

              <p>%</p>

            </div>



          }

          {type == 'numberDecimalQuestionPercentageCheck' &&

            <div className="col-md-3 color-red thead-center" style={{ marginTop: 20 }} >
             

              <input
                id={'checkboxIf'}
                disabled={disabled}
                type="checkbox"
                className="align-self-center"
                checked={getValues('checkboxIf')}
                value={getValues('checkboxIf')}
                onChange={(e) => {
                  console.log('none', e.target.checked)
                  setValue('checkboxIf', e.target.checked,  { shouldValidate: true });
                }}
              />
              <label
                htmlFor={'checkboxIf'}
                className=""
                style={{fontSize:'10px'}}
              >
                Check if none
              </label>



             

            </div>



          }

        </div>

      </div>



      {newSubCampos && newSubCampos?.length > 0 && (
        <div className="col-12">
          <div className="row justify-content-center">
            <div className="col-11">
              <div className="row">
                <Campos campos={newSubCampos} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
