import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const Geolocalizacion = ({ name, label, rules, defaultValue = false }) => {
  const [cords, setCords] = useState({
    latitude: '',
    longitude: '',
  });

  const consentimientoName = `${name}.consentimiento`;
  const latitudName = `${name}.latitud`;
  const longitudName = `${name}.longitud`;
  const methods = useFormContext();

  const hasConsentimiento = methods.watch(consentimientoName) === true;
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setCords({
        latitude: `${position.coords.latitude}`,
        longitude: `${position.coords.longitude}`,
      });
    });
  }, []);

  const handleOnCheck = (onChange) => (evt) => {
    const isChecked = evt.target.checked;
    if (isChecked) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setCords({
          latitude: `${position.coords.latitude}`,
          longitude: `${position.coords.longitude}`,
        });
        methods.setValue(latitudName, position.coords.latitude);
        methods.setValue(longitudName, position.coords.longitude);
      });
    }
    onChange(isChecked);
  };

  return (
    <div className="col-md-6 col-xl-5 my-2">
      <div className="d-flex flex-column justify-content-center">
        <Controller
          name={consentimientoName}
          rules={rules}
          defaultValue={defaultValue}
          render={({ onChange, value }) => (
            <div className="d-flex flex-row">
              <input
                type="checkbox"
                className="align-self-center"
                id={consentimientoName}
                name={consentimientoName}
                checked={value}
                style={{ height: '20px', width: '20px' }}
                onChange={handleOnCheck(onChange)}
              />
              <label
                htmlFor={consentimientoName}
                className="font-20px ml-2"
                style={{ fontFamily: 'Futura-Medium' }}
              >
                {label}
              </label>
            </div>
          )}
        />
        <ErrorMessage
          name={consentimientoName}
          render={({ message }) => (
            <smal className="w-100 font-15px" style={{ color: '#cf0e18' }}>
              {message}
            </smal>
          )}
        />
        {hasConsentimiento && (
          <React.Fragment>
            <div className="d-flex flex-row justify-content-around">
              <Controller
                name={latitudName}
                defaultValue={cords.latitude}
                render={({ value }) => (
                  <small className="font-20px">Latitud: {value}</small>
                )}
              />
              <Controller
                name={longitudName}
                defaultValue={cords.longitude}
                render={({ value }) => (
                  <small className="font-20px">Longitud: {value}</small>
                )}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default Geolocalizacion;
