import { useGeneradorPagina } from "../../../../pages/GeneradorPaginas/store";
import { NumberInput, SimpleErrorMessage } from "components/Inputs/inputs";
import moment from "moment";
import React, { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { SimpleContainer } from "components/Inputs/containers";
import Campos from "../Campos";
import { InputNumber } from "primereact/inputnumber";
import { values } from "lodash";
import { InputText } from "primereact/inputtext";

const ID_ATTR = "UNIQUE_ID_FOR_INTERNAL_OPERATIONS";

/**
 *
 * @param {{campo:import("components/DynamicForm/typedefs").Campo}} props
 * @returns
 */
const TablaOptionsGrossConsultants = ({ campo }) => {

  const [showModal, setShowModal] = useState(false);
  const { pageState } = useGeneradorPagina();
  const toggle = () => {
    methods.reset({
      [ID_ATTR]: null,
      name: pageState?.['policyFormData']?.value?.usuNombre || 'No Name'
    });
    setShowModal(!showModal);
  };

  const methods = useForm({ mode: "onChange" });

  const opciones  = [
    {
      inputA:'Organizational structure',
      valueA:'ORGANIZATIONAL_STRUCTURE',
      inputB:'Benefit consulting',
      valueB:'BENEFIT_CONSULTING'
    },
    {
      inputA:'Investment counseling',
      valueA:'INVESTMENT_COUNSELING',
      inputB:'Marketing',
      valueB:'MARKETING'
    },
    {
      inputA:'Employee evaluation',
      valueA:'EMPLOYEE_EVALUATION',
      inputB:'Data processing consulting',
      valueB:'DATA_PROCESSING_CONSULTING'
    },
    {
      inputA:'Management leverages buyouts',
      valueA:'MANAGEMENT_LEVERAGES_BUYOUTS',
      inputB:'Merger/acquisition/divestiture',
      valueB:'MERGER_ACQUISITION_DIVESTITURE'
    },
    {
      inputA:'Long range planning',
      valueA:'LONG_RANGE_PLANNING ',
      inputB:'System analysis',
      valueB:'SYSTEM_ANALYSIS'
    },
    {
      inputA:'Risk management',
      valueA:'RISK_MANAGEMENT',
      inputB:'Product development',
      valueB:'PRODUCT_DEVELOPMENT'
    },
    {
      inputA:'Other (please specify)',
      valueA:'OTHER',
      inputB:'Total %',
      valueB:'TOTAL',
      disabled: true
    }
  ]

  const _onSubmit = (field) => (formData) => {
    Object.entries(formData).forEach(([key, value]) => {
      if (typeof value === 'object' && value instanceof Date) {
        formData[key] = moment(value).format('MM/DD/YYYY');
      }
      return true;
    })
    if (formData[ID_ATTR]) {
      field.value = field.value.filter(
        (item) => item[ID_ATTR] !== formData[ID_ATTR]
      );
      formData[ID_ATTR] = uuidv4();
      field.value.push(formData);
    } else {
      formData[ID_ATTR] = uuidv4();
      field.value.push(formData);
    }
    field.onChange(field.value);
    toggle();
  };

  const htmlMessage = (mssg) => {
    return (
      <div
        style={{
          // fontSize: "1rem",
          fontFamily: "Futura-Medium"
        }}
        dangerouslySetInnerHTML={{
          __html: mssg,
        }}
      />
    );
  };
  return (
    <Controller
      name={campo.name}
      defaultValue={[]}
      rules={campo.rules}
      render={({ field: { value, onChange, ref } }) => (
        <div className="col-12 my-5">
          <div className="d-flex mb-3">
            <div className="col-md-8 col-sm-12">
              {htmlMessage(campo.label)}

            </div>
          </div>
          <div className="w-100">
            <SimpleErrorMessage name={campo.name} />
          </div>

          <table className="table table-bordered table-responsive-lx table-responsive-l table-responsive-md">
            <thead>
              <tr>
                <th style={{ fontSize: 13 }} className="thead-center">Activity</th>
                <th style={{ fontSize: 13 }} className="thead-center">Current year</th>
                <th style={{ fontSize: 13 }} className="thead-center">Activity</th>
                <th style={{ fontSize: 13 }} className="thead-center">Current year</th>
              </tr>

            </thead>

            <tbody>
              {opciones.map(opcion=>{
                return (

                  <tr>
                  <th className="thead-center">{opcion.inputA}</th>
                  <th className="thead-center">
                    <SimpleContainer {...campo.subCampos[0].containerProps} disabled={false}>
                      <InputNumber
                        ref={ref}
  
                        value={value[opcion.valueA]}
  
                        onChange={(e) => {
                          const valueAux = { ...value }

                          
  
                          valueAux[opcion.valueA] = e.value

                          var suma = 0;

                          for (const key in valueAux) {
                            if(key!='TOTAL'){
                              suma+=valueAux[key]
                            }
                          
                          }

                          valueAux['TOTAL'] = suma


                        

                        
  
  
  
                          onChange(valueAux);
                        }}
                        name={opcion.valueA}
                        style={{
                          border: "none",
                          outline: "none",
                          fontFamily: "SourceSansPro-Regular",
                        }}
                        type={"text"}
  
  
                      />
                    
                    </SimpleContainer>
                  
  
  
                  </th>
                  <th className="thead-center">{opcion.inputB}</th>
                  <th>
                  <SimpleContainer {...campo.subCampos[0].containerProps} disabled={opcion.disabled}>
                      <InputNumber
                        ref={ref}
                        disabled={opcion.disabled}
  
                        value={value[opcion.valueB]}
  
                        onChange={(e) => {
                          const valueAux = { ...value }

                     
                          valueAux[opcion.valueB] = e.value

                          var suma = 0;

                          for (const key in valueAux) {
                            if(key!='TOTAL'){
                              suma+=valueAux[key]
                            }
                          
                          }

                          valueAux['TOTAL'] = suma
  
  
  
                          onChange(valueAux);
                        }}
                        name={opcion.valueB}
                        style={{
                          border: "none",
                          outline: "none",
                          fontFamily: "SourceSansPro-Regular",
                        }}
                        type={"text"}
  
  
                      />
                    
                    </SimpleContainer>
                  </th>
  
  
                </tr>
                )
              })}
          
            </tbody>

          </table>

       



        </div>
      )}
    />
  );
};

export default TablaOptionsGrossConsultants;
