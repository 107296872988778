/* eslint-disable no-eval */
export const processRules = (rules, resources) => {
  try {
    if (rules && typeof rules === "object") {
      if (rules?.validate) {
        const { validate } = rules;
        if (typeof validate === "object") {
          const entries = Object.entries(rules?.validate);
          if (entries.length > 0) {
            entries.forEach(([key, validacion]) => {
              let validation = () => true;
              if (typeof validacion === "string") {
                validation = eval(validacion);
              }
              rules.validate[key] = async (val) => {
                return await validation(val, resources);
              };
            });
          }
        } else if (typeof validate === "string") {
          const validation = eval(rules.validate);

          rules.validate = async (value) => await validation(value, resources);
        }
      }

      if (typeof rules?.pattern?.value === "string") {
        rules.pattern.value = new RegExp(rules.pattern.value);
      }

      return rules;
    }

    return rules || {};
  } catch (e) {
    console.error(e);
    return { ...rules, validate: null };
  }
};
