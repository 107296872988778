import { IndexColumn } from "components/Table/Columns";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { apiGet } from "services/api";
import { urlConsultarMisPagosP2p } from "services/urls";
import { strToDateFormat } from "utils/functions";

const MisPagosP2p = ({ idPoliza }) => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const onClickWatch = async () => {
    setLoading(true);
    try {
      const res = await apiGet(urlConsultarMisPagosP2p(idPoliza));
      setData(res.data?.data);
      setShowModal(true);
    } catch (ex) { }

    setLoading(false);
  };

  return (
    <React.Fragment>
      <button
        onClick={onClickWatch}
        disabled={loading}
        className='btn btn-aigpr-no-block text-white'
        type='button'>
        {!loading && t("Payment History")}
        {loading && t("Loading payments") + "..."}
      </button>
      <Modal show={showModal} centered size='xl' onHide={() => setShowModal(false)}>
        <Modal.Header className='d-flex flex-row justify-content-between'>
          <Modal.Title>{t("Payment history")}</Modal.Title>
          <button
            className='btn btn-aiggopr'
            type='button'
            onClick={() => setShowModal(false)}>
            <i className='pi pi-times' />
          </button>
        </Modal.Header>
        <Modal.Body className='d-flex'>
          <DataTable
            value={data}
            paginator
            rows={10}
            rowHover
            autoLayout
            rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}>
            {IndexColumn()}
            <Column
              header={t("Date time")}
              body={(rowData) => strToDateFormat(rowData.fecha)}
            />
            <Column header={t("Status")} body={(rowData) => t(rowData.estado)} />
            <Column header={t("Reference")} field='numReference' />
            <Column header={t("Currency")} body={() => "USD"} />
            <Column header={t("Amount")} field='monto' />
          </DataTable>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default MisPagosP2p;
