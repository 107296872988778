import classNames from 'classnames';
import { DynamicPlans } from 'components/DynamicPlans/DynamicPlans';
import { PlansCasualty } from 'components/PlansCasualty/PlansCasualty';
import { format } from 'helpers/functions';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { match } from 'x-match-expression';

export const stripColors = ['#d8e5ed', '#f8ba3d', '#c093e8', '#81d1d4', '#ef8ba1', '#f3dda3', '#f3dadf', '#4ed19b'];

export const CabeceraPlanes = ({ opcion, stripColor, showLabel = '' }, t) => (
    <div className="position-relative" style={{ height: '250px' }}>
        <div
            className="w-100 font-mex text-mex text-center font-20px mt-4"
            style={{ backgroundColor: stripColor, paddingBottom: '0.5rem' }}
        >
            <h4>{opcion?.planNombre}</h4>
        </div>
        <div className="d-flex flex-wrap mx-3">

            {(showLabel) !== '' && <h6 className="col-6 col font-source-sans-regular text-mex my-3 px-3 text-left">
                {(showLabel)}
            </h6>
            }
            <h6 className="col font-source-sans-regular font-20px text-aigpr my-3 text-center">
                {format(opcion?.planPrimaAnual || opcion?.premium)}
            </h6>
        </div>
    </div>
);

export const ProductOptions = ({
    name = '',
    rules,
    options = [],
    value = null,
    plans = null,
    tipoProducto = '',
    infoFastTrack,
    infoLawyers,
    infoUmbrella,
    infoRevenue,
    panelesVisibles = tipoProducto === "casualty"? 5:3,
    numLic,
    onChange = () => { return true }
}) => {
    const { t } = useTranslation();
    const [visiblePlans, setVisiblePlans] = useState([])
    const [pagination, setPagination] = useState(1);
    const [planes, setPlanes] = useState([])
    const [showForward, setShowForward] = useState(false);
    const [showBack, setShowBack] = useState(false);
    const nameValues = [t('I_FRONT_38'), t('I_FRONT_39'), t('I_FRONT_40'), t('I_FRONT_41')];
    const nameValuesCasualty = ['Limits of liability','Premium','Assessment Fee'];
    //Rellenar arreglo para evitar descuadres en panel de opciones
    const fillArr = useCallback(
        (arr = []) => {
            const arrPlanes = [...arr]
            const numPlanesVisibles = panelesVisibles - arrPlanes?.length;
            if (numPlanesVisibles > 0) {
                for (let index = 0; index < numPlanesVisibles; index++) {
                    arrPlanes.push(false);
                }
            }
            return arrPlanes
        },
        [panelesVisibles],
    )



    useEffect(() => {
        var planesAux = []

        if(infoRevenue){
            planesAux = [...options.filter(opt=>opt.planRevenue == infoRevenue.label)]
        }else{
            planesAux = [...options]
        }
        setPlanes(planesAux)

        setVisiblePlans(fillArr([...planesAux.slice(0, panelesVisibles)]));


       
      
        if (planesAux.length > panelesVisibles) {
            setShowForward(true);
        }
    }, [options, panelesVisibles, fillArr])

    //Funcion para avanzar o retroceder en los planes
    const showMoreLess = (more = true) => {
        if (more && planes.length > (pagination + 1) * panelesVisibles - panelesVisibles) {
            setPagination(pagination + 1);
            setShowBack(true);
           
                setVisiblePlans(fillArr([...planes.slice((pagination + 1) * panelesVisibles - panelesVisibles, (pagination + 1) * panelesVisibles)]))
         
            
            if (planes.length <= (pagination + 2) * panelesVisibles - panelesVisibles) {
                setShowForward(false)
            }
        } else {
            if (pagination - 1 === 1) {
                setShowBack(false)
            }
            if (pagination !== 1 && !more) {
                setShowForward(true)
                setPagination(pagination - 1);
                setVisiblePlans(fillArr(...[planes.slice((pagination - 1) * panelesVisibles - panelesVisibles, (pagination - 1) * panelesVisibles)]))
            }
        }
    }



    return (
        <React.Fragment>
            {tipoProducto === "fastTrack" ?
                <DynamicPlans catalogoService={infoFastTrack} infoLawyers={infoLawyers} plans={plans?.length === 0 ? null : plans} nameValues={nameValues} onChange={onChange} numLic={numLic?.value} nameLicense={numLic?.name} />
                :

                tipoProducto === "casualty" ?
                    <PlansCasualty
                    options={
                        options.map((option,index)=>{
                            return {...option,
                                planNombre: `Option ${index+1}`,
                                planCodigo: `option${index+1}`,
                                planCodigoCore: `option${index+1}`,
                                planPrimaAnual: option['Umbrella Premium']+option['Assessment Fee'],
                                Premium: option['Umbrella Premium'],
                                commisionPercentage:0.00,
                                planLimitesResposabilidad:0.00


                             }
                        })
                    }
                    onChange={(value)=>{onChange(value)}}
                    panelesVisibles={panelesVisibles}
                    visiblePlans={
                        visiblePlans.map((option,index)=>{

                  

                            const index2 =  options.indexOf(option)

                  
                            return {...option,
                                planNombre: `Option ${index2+1}`,
                                planCodigo: `option${index2+1}`,
                                planCodigoCore: `option${index2+1}`,
                                planPrimaAnual: option['Umbrella Premium']+option['Assessment Fee'],
                                Premium: option['Umbrella Premium'],
                                commisionPercentage:0.00,
                                planLimitesResposabilidad:0.00
                             }
                        })
                    }
                    classNames={classNames}
                    showBack={showBack}
                    showMoreLess={(value)=>{showMoreLess(value)}}
                    showForward={(value)=>{showForward(value)}}
                    nameValues={nameValuesCasualty}
                    
                    />
                    :
                    <>
                    <div className="row col-12 d-flex flex-wrap justify-content-center px-2 py-1 d-lg-block">

                        <div className={`container-planes-${panelesVisibles} position-relative`}>
                            <div className="d-flex flex-column justify-content-end font-source-sans-regular text-aigpr font-19px col text-left mb-3">
                                Premium
                            </div>
                            {visiblePlans?.map((opcion, index) => (
                                <React.Fragment key={`product-plan-${index}`}>
                            
                                    {(opcion) ?
                                    <div
                                        className={classNames('plan-pago-container', {
                                            'active-plan': value === opcion?.planCodigoCore,
                                            'card-plan': value !== opcion?.planCodigoCore,
                                        })}
                                        onClick={() => onChange(opcion?.planCodigoCore)}
                                    >
                                        <CabeceraPlanes
                                            opcion={opcion}
                                            stripColor={stripColors[planes.indexOf(opcion) || 0]}
                                        />

                                    </div> : <div ></div>}

                                    {
                                        (index === 0 && planes?.length > panelesVisibles && showBack) && <div
                                            key={`back${index}`}
                                            className={`${(planes?.length === 0) && 'd-none'} d-flex align-content-center pagination-button-back `}
                                            onClick={() => { showMoreLess(false) }}><span className="arrow mt-3">❬</span>
                                        </div>
                                    }
                                    {
                                        (index === visiblePlans?.length - 1 && planes?.length > panelesVisibles && showForward) && <div
                                            key={`forward${index}`}
                                            className={`${(planes?.length === 0) && 'd-none'} d-flex align-content-center pagination-button-forward `}
                                            onClick={() => { showMoreLess(true) }}><span className="arrow mt-3">❭</span>
                                        </div>
                                    }

                                </React.Fragment>
                            ))}

                            <div className="d-flex align-content-center font-source-sans-regular font-19px col text-left py-3">
                                {nameValues[0]}
                            </div>
                            {visiblePlans.map((opcion, j) => (
                                (opcion) ? <div
                                    key={`opci0${j}`}
                                    className={`d-flex justify-content-center flex-column px-3 py-3 ${value === opcion?.planCodigoCore
                                        ? 'plan-activo card-border-lateral-activo '
                                        : 'card-border-lateral'
                                        }`}
                                    style={{
                                        borderTop: '0px !important',
                                        borderBottom: '0px !important',
                                    }}
                                    onClick={() => onChange(opcion?.planCodigoCore)}
                                >
                                    <div className="col-12 d-flex justify-content between">
                                        <div className="col-6 font-source-sans-regular font-16px text-lg-center text-left">{`${format(opcion?.planLimitesResposabilidad[0]?.inferior, 0)}`}</div>
                                        <div className="col-6 font-source-sans-regular font-16px text-lg-center text-left">{t('I_FRONT_42')}</div>
                                    </div>
                                    <div className="col-12 d-flex justify-content between">
                                        <div className="col-6 font-source-sans-regular font-16px text-lg-center text-left">{`${format(opcion?.planLimitesResposabilidad[0]?.superior, 0)}`}</div>
                                        <div className="col-6 font-source-sans-regular font-16px text-lg-center text-left">{t('I_FRONT_44')}</div>
                                    </div>
                                </div> : <div key={`divSpace${j}`}></div>
                            ))}
                            <div className="font-source-sans-regular font-19px col text-left mb-3">
                                {nameValues[1]}
                            </div>
                            {visiblePlans.map((opcion, j) => (
                                (opcion) ? <div
                                    key={`opci1${j}`}
                                    className={`d-flex justify-content-center px-3 py-3 ${value === opcion?.planCodigoCore
                                        ? 'plan-activo card-border-lateral-activo '
                                        : 'card-border-lateral'
                                        }`}
                                    style={{
                                        borderTop: '0px !important',
                                        borderBottom: '0px !important',
                                    }}
                                    onClick={() => onChange(opcion?.planCodigoCore)}
                                >
                                    <div className="font-source-sans-regular font-19px text-lg-center text-left">{`${format(opcion?.planDeducibles)}`}</div>
                                </div> : <div key={`divSpace${j}`}></div>
                            ))}
                            <div className="font-source-sans-regular font-19px col text-left py-3">
                                {nameValues[2]}
                            </div>
                            {visiblePlans.map((opcion, j) => (
                                (opcion) ? <div
                                    key={`opci2${j}`}
                                    className={`d-flex justify-content-center px-3 py-3 ${value === opcion?.planCodigoCore
                                        ? 'plan-activo card-border-lateral-activo '
                                        : 'card-border-lateral'
                                        }`}
                                    style={{
                                        borderTop: '0px !important',
                                        borderBottom: '0px !important',
                                    }}
                                    onClick={() => onChange(opcion?.planCodigoCore)}
                                >
                                    <div className="font-source-sans-regular font-19px text-lg-center text-left">{`${format(opcion?.planPrimaAnual)}`}</div>
                                </div> : <div key={`divSpace${j}`}></div>
                            ))}
                            <div className="font-source-sans-regular font-19px col text-left py-3">
                                {nameValues[3]}
                            </div>
                            {visiblePlans.map((opcion, j) => (
                                (opcion) ? <div
                                    key={`opci3${j}`}
                                    className={`d-flex justify-content-center px-3 py-3 ${value === opcion?.planCodigoCore
                                        ? 'plan-activo card-border-lateral-activo '
                                        : 'card-border-lateral'
                                        }`}
                                    style={{
                                        borderTop: '0px !important',
                                        borderBottom: '0px !important',
                                    }}
                                    onClick={() => onChange(opcion?.planCodigoCore)}
                                >
                                    <div className="font-source-sans-regular font-19px text-lg-center text-left">{`${format(opcion?.planAssesmentFee)}`}</div>
                                </div> : <div key={`divSpace${j}`}></div>
                            ))}

                            

                            <div></div>
                            {visiblePlans?.sort((a, b) => (a.planNombre > b.planNombre) ? 1 : -1).map((opcion, index) => (
                                <div className={`pt-3 pt-3 ${value === opcion?.planCodigoCore
                                    ? 'active-plan-button card-border-lateral-activo '
                                    : opcion ? 'border-top':''
                                    }`} key={`opcines11${index}`}>
                                    {(opcion) &&
                                        <Button
                                            className={`mx-auto text-center w-100 ${value === opcion?.planCodigoCore ? 'btn-aigpr' : 'outline-aigpr'}`}
                                            style={{ whiteSpace: 'break-spaces' }}
                                            onClick={() => onChange(opcion?.planCodigoCore)}
                                        >
                                            <span className="text-wrap">{t('I_FRONT_43')}</span>
                                        </Button>
                                    }
                                </div>
                            ))}
                        </div>

                    </div>
                    <div className="d-lg-none p-3">
                        <ButtonGroup className="row d-flex justify-content-center">
                            {planes?.map((opcion, index) => {
                                const stripColor = stripColors[index];
                                return ((opcion) &&

                                    <div
                                        className="col-md-6 col-lg-2  col-12 mt-3"
                                        key={`opcion-${name}-${index}`}
                                        onClick={() => onChange(opcion?.planCodigoCore)}
                                    >
                                        <div
                                            className={classNames('plan-pago-container', {
                                                'active-plan': value === opcion?.planCodigoCore,
                                                'card-plan': value !== opcion?.planCodigoCore,
                                            })}
                                        >
                                            <CabeceraPlanes showLabel={t('I_FRONT_40')} opcion={opcion} stripColor={stripColor} />
                                            <div className="plan-pago-coberturas">
                                                <div
                                                    className="mb-2 font-source-sans-regular font-19px"
                                                >
                                                    <CoberturaXL length={nameValues?.length}>
                                                        <div onClick={() => onChange(opcion?.planCodigoCore)} className="row d-flex justify-content-center mx-3">
                                                            <div className="col-6 text-left">
                                                                {nameValues[0]}
                                                            </div>
                                                            <div className="col-6 font-source-sans-regular font-19px text-lg-center text-left">{`${opcion?.planLimitesResposabilidad[0]?.inferior}`}</div>
                                                            <div className="col-6 text-left">
                                                                {nameValues[1]}
                                                            </div>

                                                            <div className="col-6 font-source-sans-regular font-19px text-lg-center text-left">{`${format(opcion?.planDeducibles)}`}</div>
                                                            <div className="col-6 text-left">
                                                                {nameValues[2]}
                                                            </div>

                                                            <div className="col-6 font-source-sans-regular font-19px text-lg-center text-left">{`${format(opcion?.planPrimaAnual)}`}</div>
                                                            <div className="col-6 text-left">
                                                                {nameValues[3]}
                                                            </div>
                                                            <div className="col-6 font-source-sans-regular font-19px text-lg-center text-left">{`${format(opcion?.planAssesmentFee)}`}</div>
                                                        </div>
                                                    </CoberturaXL>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`pt-3 ${value === opcion?.planCodigoCore
                                            ? 'active-plan-button card-border-lateral-activo '
                                            : 'border-top'
                                            }`}>
                                            <Button
                                                className={`mx-auto text-center w-100 ${value === opcion?.planCodigoCore ? 'btn-aigpr' : 'outline-aigpr'}`}
                                                style={{ whiteSpace: 'break-spaces' }}
                                                onClick={() => onChange(opcion?.planCodigoCore)}
                                            >
                                                <span className="text-wrap">{t('I_FRONT_43')}</span>
                                            </Button>
                                        </div>

                                    </div>
                                );
                            })}
                        </ButtonGroup>
                    </div>
                </>
            }
        </React.Fragment>
    );

};



export const CoberturaXL = styled.div`
  font-size: 1rem;
  /* font-size: 20px;
  font-family: 'SourceSansPro-Regular'; */
  color: #151515;
  @media (min-width: 1290px) {
    height: ${(props) =>
        match(props?.length)
            .case((n) => n >= 0 && n <= 30, '20px')
            .case((n) => n >= 31 && n <= 50, '30px')
            .case((n) => n >= 51 && n <= 80, '50px')
            .case((n) => n >= 81 && n <= 85, '60px')
            .case((n) => n >= 91 && n <= 120, '100px')
            .default('100px')};
  }
  align-items: center;

  & .info {
    font-weight: bold;
  }
`;
