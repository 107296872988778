/* eslint-disable react-hooks/exhaustive-deps */
import withValidarCambioContrasenia from "HOC/withValidarCambioContrasenia";
import { HeaderLogo } from "components/Header/HeaderLogo";
import { validarContraseniaNueva } from "components/Inputs/validaciones";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { consumirServicioEncirptacion } from "services/api";
import {
  ambienteAplicacion,
  empresa,
  urlCambiarContraseniaProceso,
  urlChangePassword,
} from "services/urls";
import { encriptarContrasenia } from "utils/Encriptar";
import { getQueryParams } from "utils/functions";
import { Loading } from "../Loading/Loading";
import "./change-password.scss";
import { FormChangePassword } from "./FormChangePassword";

const ChangePassword = ({ title = "I_FRONT_10" }) => {
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validations, setValidations] = useState(null);
  const history = useNavigate();
  const { validado, id, renew } = getQueryParams();
  const { t } = useTranslation();
  const { addToast } = useToasts();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    if ((!validado || validado === "" || validado === "0" || !id || id === "") && !renew) {
      history.push("/invalid-token");
      setLoading(false);
      return null;
    }
    const body = {
      ambiente: ambienteAplicacion,
      empresa: empresa,
    };
    try {
      const { transaccion, validaciones } = await consumirServicioEncirptacion(
        urlChangePassword,
        body
      );
      (transaccion) ? setValidations(validaciones) : history.push("/invalid-token");

    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onSubmit = async (values) => {
    const passUno = values.passUno;
    const passDos = values.passDos;
    const passActual = values?.passActual || "";

    if (passUno !== passDos) {
      addToast(t("I_FRONT_32").toUpperCase(), {
        appearance: "warning",
      });

      return;
    }

    setLoading(true);
    const Data = {
      cliCodigoCore: id,
      cliPasswordNueva: encriptarContrasenia(passUno),
      cliPasswordNuevaSinEncriptar: passUno,
    };

    renew && (Data.cliPasswordAnterior = passActual);
    const result = await consumirServicioEncirptacion(urlCambiarContraseniaProceso, Data);

    if (result?.mensajes && result.transaccion === false) {
      result.mensajes.forEach((mensaje) => {
        addToast(t(mensaje.textTitulo), {
          appearance: "warning",
        });
      });
    } else if (result.transaccion === true) {
      addToast(t("I_FRONT_33"), {
        appearance: "success",
      });
      history("/");
    } else {
      addToast(t("I_FRONT_34"), {
        appearance: "warning",
      });
    }

    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <Loading message={`${t("I_FRONT_35")}...`} />
      ) : (
        <>
          <div className='container-fluid px-5 pt-3 pb-0 mb-5'>
            <div className='col-12'>
              <HeaderLogo />
            </div>
            {validations !== null && (
              <React.Fragment>
                <div className='row mb-5 mt-3'>
                  <div className='col-12'>
                    <div className='card border rounded-top'>
                      <div className='card-header '>
                        <h3 className='font-aig'>{t(title)}</h3>
                      </div>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-6 order-1 order-md-0 align-self-center'>
                            <FormChangePassword
                              showError={showError}
                              errorMsg={validations.msgInvalidContrasenia}
                              onSubmit={onSubmit}
                              names={{
                                passActual: renew
                                  ? {
                                    name: "passActual",
                                    rules: {
                                      required: t("W_FRONT_12"),
                                    },
                                  }
                                  : null,
                                passUno: {
                                  name: "passUno",
                                  rules: {
                                    required: t("W_FRONT_12"),
                                    validate: validarContraseniaNueva(
                                      validations,
                                      setShowError
                                    ),
                                  },
                                },
                                passDos: {
                                  name: "passDos",
                                  rules: {
                                    required: t("W_FRONT_12"),
                                    validate: validarContraseniaNueva(
                                      validations,
                                      setShowError
                                    ),
                                  },
                                },
                              }}
                            />
                          </div>
                          {validations.msgInformacion && (
                            <div className='col-md-6 order-0 order-md-1  align-self-center'>
                              <div
                                className='alert alert-success mt-4 font-mex'
                                dangerouslySetInnerHTML={{
                                  __html: t(validations.msgInformacion),
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default withValidarCambioContrasenia(ChangePassword);
