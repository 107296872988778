import classNames from "classnames";
import React from "react";
import Card from "react-bootstrap/Card";

const UserCard = ({
  avatar,
  avatarSize,
  title,
  subtitle,
  text,
  children,
  className,
  ...restProps
}) => {
  const classes = classNames("bg-gradient-theme text-white", className);

  return (
    <Card className={classes} {...restProps}>
      <Card.Body className='d-flex justify-content-center align-items-center flex-column'>
        <Card.Title>{title}</Card.Title>
        <Card.Subtitle>{subtitle}</Card.Subtitle>
        <Card.Text>
          <small>{text}</small>
        </Card.Text>
      </Card.Body>
      {children}
    </Card>
  );
};

export default UserCard;
