import { types } from "redux/types/types";

const initialState = {
  loading: false,
  error: null,
  loginError: false,
  language: {},
  languages: [],
  timeSession: 3600,
  rangoVigencia: 5,
  assessmentPercentage: 0.003
};

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case types.ERROR_LOGIN:
      return {
        ...state,
        loginError: true,
      };
    case types.CLEAN_ERRORS:
      return {
        ...state,
        loginError: false,
      };

    case types.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };

    case types.SET_LANGUAGES:
      return {
        ...state,
        languages: [...action.payload],
      };
    case types.SET_TIMESESSION:
      return {
        ...state,
        timeSession: action.payload,
      };

    case types.SET_RANGOVIGENCIA:
      return {
        ...state,
        rangoVigencia: action.payload,
      };
    default:
      return state;
  }
};
