/* eslint-disable react-hooks/exhaustive-deps */
import useCustomToast from "hooks/useCustomToast";
import moment from "moment";
import normalizeText from "normalize-text";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getQueryParams } from "../../utils/functions";
import Seccion from "./components/Seccion";
import ShowOnSeccionWrapper from "./components/ShowOnSeccionWrapper";
import { useDynamicForm } from "./state";
import "./styles/index.scss";
import "./typedefs";

export const validarFechasMinMaxFixed = ({
  min,
  max,
  fixed,
  valueMoment,
  msgMin,
  msgMax,
  msgFixed,
  msgMinMax,
}) => {
  if (min && max) {
    const fechaMin = moment().subtract(min.value, min.unit);
    const fechaMax = moment().subtract(max.value, max.unit);
    const isValidMin = valueMoment.isBefore(fechaMin);
    const isValidMax = valueMoment.isAfter(fechaMax);

    if (isValidMin && isValidMax) {
      return true;
    }
    return msgMinMax?.(fechaMin, fechaMax);
  }

  if (min) {
    const fecha = moment().subtract(min.value, min.unit);
    const isValid = valueMoment.isBefore(fecha);
    if (!isValid) {
      return msgMin?.(fecha);
    }
  }
  if (max) {
    const date = moment(max?.value, "yyyy-MM-DD");
    if (typeof max?.value === "string" && !max.unit && date.isValid()) {
      const isValidBefore = valueMoment.isBefore(date);
      const isValidSame = valueMoment.isBefore(date);
      if (isValidBefore || isValidSame) {
        return true;
      }
      return msgMax?.(date);
    }
    const fecha = moment().subtract(max.value, max.unit);
    const isValid = valueMoment.isAfter(fecha);
    if (!isValid) {
      return msgMax?.(fecha);
    }
  }
  if (fixed) {
    const leadMoment = moment().subtract(fixed.value, "years");
    const anioActual = moment().diff(leadMoment, "years", true);
    const anioReact = moment().diff(valueMoment, "years", true);

    const isValid = Math.floor(anioActual) === Math.floor(anioReact);

    if (isValid) {
      return true;
    }
    return msgFixed?.();
  }

  return true;
};

/**
 *
 * @param {import('./typedefs').Formulario} props
 */
const DynamicForm = (props) => {
  const { secciones, setSecciones } = useDynamicForm();
  const methods = useFormContext();
  const toast = useCustomToast();
  const { addWarningToast } = useCustomToast();
  const { dataUsuario } = useSelector((state) => state.login);
  const [isReloading, setIsReloading] = useState(false);
  const [preload, setPreload] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    methods.reset(props.value);
    props.value !== null && setPreload(true);
  }, [props.value]);

  useEffect(() => {
    const normalizeObject = (object) => {
      return normalizeText(JSON.stringify(object));
    };
    const objectIncludes = (object, keyword) => {
      return normalizeObject(object || {}).includes(keyword);
    };

    
    setSecciones(props?.secciones, {
      getQueryParams,
      moment,
      normalizeText,
      normalizeObject,
      objectIncludes,
      methods,
      toast,
      dataUsuario,
      setIsReloading,
      validarFechasMinMaxFixed,
      isReloading,
      catalogo: props.resources,
      addWarningToast,
      t,
    });
  }, []);

  return (
    <div className='row d-flex flex-wrap justify-content-center mb-5'>
      <div className='col-11 col-md-11 mb-5'>
        {!isReloading &&
          secciones?.map((/** @type {import('./typedefs').Seccion} */ seccion) => {
            const component = <Seccion {...seccion} preload={preload} key={seccion.id} infoCoverageDesired={props.infoCoverageDesired}  infoUmbrella={props.infoUmbrella} aprobRetroDate={props.aprobRetroDate} retroDate={props.retroDate} campo1={props.campo1} campo2={props.campo2} campo3={props.campo3}  />;
            if (seccion?.showOn) {
              return (
                <ShowOnSeccionWrapper
                  name={seccion.codigoCore}
                  showOn={seccion.showOn}
                  key={seccion.id}>
                  {component}
                </ShowOnSeccionWrapper>
              );
            }
            return component;
          })}
      </div>
      <br />
    </div>
  );
};

export default DynamicForm;
