import { useGeneradorPagina } from "../../../../pages/GeneradorPaginas/store";
import { SimpleErrorMessage } from "components/Inputs/inputs";
import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import Campos from "../Campos";

const ID_ATTR = "UNIQUE_ID_FOR_INTERNAL_OPERATIONS";

/**
 *
 * @param {{campo:import("components/DynamicForm/typedefs").Campo}} props
 * @returns
 */
const TablaGroup = ({ campo }) => {
  const [showModal, setShowModal] = useState(false);
  const { pageState } = useGeneradorPagina();
  const toggle = () => {
    methods.reset({
      [ID_ATTR]: null,
      name: pageState?.['tipoProducto'] !== 'fastTrack' && (pageState?.['policyFormData']?.value?.usuNombre || 'No Name')
    });
    setShowModal(!showModal);
  };

  const methods = useForm({ mode: "onChange" });

  const _onSubmit = (field) => (formData) => {
    Object.entries(formData).forEach(([key, value]) => {
      if (typeof value === 'object' && value instanceof Date) {
        formData[key] = moment(value).format('MM/DD/YYYY');
      }
      return true;
    })
    if (formData[ID_ATTR]) {
      field.value = field.value.filter(
        (item) => item[ID_ATTR] !== formData[ID_ATTR]
      );
      formData[ID_ATTR] = uuidv4();
      field.value.push(formData);
    } else {
      formData[ID_ATTR] = uuidv4();
      field.value.push(formData);
    }
    field.onChange(field.value);
    toggle();
  };
  return (
    <Controller
      name={campo.name}
      defaultValue={[]}
      rules={campo.rules}
      render={({ field }) => (



        <div className="col-12 my-5">

        
          <div className="d-flex mb-3">
            <Button
              icon="pi pi-plus"
              // disabled={!campo.editable}
              disabled={campo.disabled}
              className="p-button-sm bg-darkblue"
              style={{
                height: "1.5rem",
                width: "1.5rem",
                borderRadius: "50%",
                border: "none",
                outline: "none",
              }}
              onClick={toggle}
              type="button"
            />
            <label className="ms-2 text-uppercase align-self-center text-darkblue" disabled={campo.disabled}>
              {campo?.label}
            </label>
          </div>
          <div className="w-100">
            <SimpleErrorMessage name={campo.name} />
          </div>



          <table className="table table-bordered ">
            <thead>
              <tr >


                {campo?.subCampos?.map((subCampo) => {

                  return (
                    <th
                      className="thead-center"
                      rowSpan={subCampo.name == 'checkOne' ? 1 : 2}
                      colSpan={subCampo.name == 'checkOne' ? 2 : 1}

                    >{subCampo.label}</th>
                  )
                })}
                <th className="thead-center" rowSpan={2}>Options</th>
              </tr>

              <tr >
                <th className="thead-center"> {'Active'}</th>
                <th className="thead-center">{'Inactive'}</th>
              </tr>
            </thead>

            <tbody>

              


              {field.value.map((field2) => {
     

                return (

                  <tr>

                    {
                      campo?.subCampos?.filter(s => s.name != 'checkOne').map((subCampo) => {



                        return (


                          <th className="thead-center">

                            {(subCampo.type == 'texto' || subCampo.type == 'text-int') &&
                              <p>{(field2[subCampo.name])}</p>
                            }

                            {(subCampo.type == 'select' && subCampo.name == 'desicnationCode') &&
                              <p>{field2[subCampo?.name].label}</p>
                            }
                          </th>
                        )
                      })
                    }

                    <th className="thead-center">
                      <React.Fragment>
                        <input
                          id={field2.name}
                          type="checkbox"
                          className="align-self-center"
                          checked={field2.checkOne.value =='active'}
                        
                          onChange={(e) => {

                            const aux  = [...field.value]

                            const index= aux.findIndex(f=>f==field2)

                            var value = {...field.value[index], checkOne:{ "label": "ACTIVE", "value": "active"}}
                            aux[index] = value

                            field.onChange(aux)

                            

                      
                            /*  field.onChange({
                               ...option?.value,
                               checked: e.target.checked,
                             }); */
                          }}
                        />

                      </React.Fragment>
                    </th>
                    <th className="thead-center">

                    <React.Fragment>
                        <input
                          id={field2.name}
                          type="checkbox"
                          className="align-self-center"
                          checked={field2.checkOne.value =='inactive'}
                        
                          onChange={(e) => {


                            const aux  = [...field.value]

                            const index= aux.findIndex(f=>f==field2)

                            var value = {...field.value[index], checkOne:{"label": "INACTIVE", "value": "inactive"}}
                            aux[index] = value

                         

                            field.onChange(aux)
                          }}
                        />

                      </React.Fragment>
                    </th>
                    <th className="thead-center">

                      <div className="d-flex flex-row flex-wrap justify-content-around">
                        <Button
                          type="button"
                          onClick={() => {
                            toggle();
                            methods.reset(field2);
                          }}
                          icon="pi pi-pencil"
                          className="p-button-sm p-button-text"
                        />
                        <Button
                          type="button"
                          onClick={() => {
                            const newValue = field.value.filter(
                              (item) => item[ID_ATTR] !== field2[ID_ATTR]
                            );
                            field.onChange(newValue);
                          }}
                          icon="pi pi-trash"
                          className="p-button-sm p-button-danger p-button-text"
                        />
                      </div>

                    </th>



                  </tr>
                )

              })}



            </tbody>

          </table>
       

          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body className="m-5">
              <h3 className="text-center my-2">{campo?.label}</h3>
              <FormProvider {...methods}>
                <form>
                  <Campos campos={campo.subCampos} />
                </form>
              </FormProvider>
              <button
                className="btn btn-aigpr text-white mt-3"
                onClick={methods.handleSubmit(_onSubmit(field))}
              >
                SAVE AND CONTINUE
              </button>
            </Modal.Body>
          </Modal>
        </div>
      )}
    />
  );
};

export default TablaGroup;
