/* eslint-disable react-hooks/exhaustive-deps */
import { SimpleErrorMessage } from "components/Inputs/inputs";
import React, { useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Campos from "components/DynamicForm/components/Campos";
import useCustomToasts from "hooks/useCustomToast";
import { htmlMessage } from "components/DynamicForm/utils/funcionesGenerales";
export const SimpleCheckBox = (props) => {
  const {
    preload = false,
    name = "",
    rules,
    id,
    label = "",
    type = "slide",
    subCampos,
    disabled = false,
    columnas = 12,
  } = props;
  const { t } = useTranslation();

  const methods = useFormContext({ mode: "onChange" });
  const selected = methods.watch(name);
  const { addWarningToast } = useCustomToasts();

  useEffect(() => {
    if (preload) {
      methods.setValue("ext." + name, !selected);
    }
  }, [preload]);

  useEffect(() => {
    if (rules?.isValue) {
      if (selected && rules?.isValue?.value === "true") {
        addWarningToast(rules?.isValue.message, "warning");
      }
    }
  }, [selected, t]);

  const value = methods.watch(name);
  const newSubCampos = useMemo(
    () =>
      subCampos
        ?.filter?.((item) => `${item?.whenValue}` === `${value}`)
        ?.map?.((item) => ({
          ...item,
          name: `${name}Fields.${item.name}`,
          containerProps: {
            ...(item?.containerProps || {}),
            name: `${name}Fields.${item.name}`,
          },
        })) || [],
    [value]
  );

  
  return (
    <div
      className={`col-md-${columnas} d-flex flex-wrap my-3 justify-content-between align-items-center`}
    >
      <div className="col-11 my-2">
        <div style={{ opacity: disabled ? 0.7 : 1 }}>{htmlMessage(label)}</div>
        <SimpleErrorMessage name={name} />
      </div>
      <Controller
        name={name}
        rules={rules}
        disabled={disabled}
        render={({ field }) => (
          <div
            className={`col-1 form-check ${
              type === "slide" ? "form-switch" : ""
            }`}
            style={{ marginRight: "-1em" }}
          >
            {/* <label class="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label> */}
            <input
              type="checkbox"
              id={id}
              disabled={disabled}
              className="form-check-input float-end"
              {...field}
            />
            {type === "slide" && (
              <label
                htmlFor={name}
                className="check-type-slide"
                disabled={disabled}
              ></label>
            )}
          </div>
        )}
      />

      {newSubCampos && newSubCampos?.length > 0 && (
        <div className="col-12">
          <div className="row justify-content-center">
            <div className="col-11">
              <div className="row">
                <Campos campos={newSubCampos} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
