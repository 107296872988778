export const types = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    ERROR_LOGIN: 'ERROR_LOGIN',
    CLEAN_ERRORS: 'CLEAN_ERRORS',

    //UI
    LOADING: 'LOADING',
    STOP_LOADING: 'STOP_LOADING',
    SET_LANGUAGE: 'SET_LANGUAGE',
    SET_LANGUAGES:'SET_LANGUAGES',
    SET_TIMESESSION:'SET_TIMESESSION',
    SET_RANGOVIGENCIA:'SET_RANGOVIGENCIA',

    //PRODUCTS
    LOAD_PRODUCTS: 'LOAD_PRODUCTS',
    LOADING_PRODS: 'LOADING_PRODS',
    STOP_LOADING_PRODS: 'STOP_LOADING_PRODS',
    SET_SELECTED_OPTION:'SET_SELECTED_OPTION',
    SET_SELECTED_TERRORISM:'SET_SELECTED_TERRORISM'
}