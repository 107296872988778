import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { loadTexts } from 'redux/actions/uiActions';
import './language.scss'

export const LanguageSelector = () => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const { language, languages } = useSelector(state => state.ui);
    const [langValues, setLangValues] = useState([])
    const changeLanguage = (value) => {
        (value !== language) && dispatch(loadTexts(value || 'us', i18n));
    }

    useEffect(() => {
        setLangValues(languages.map(lng => {
            return {
                label: languages?.find(lang => lang.code === lng.code)?.label,
                value: lng
            }
        }))
    }, [languages])

    return (
        <>
            {
                (langValues.length <= 1) ? '' :
                        <Dropdown onSelect={(evt) => { changeLanguage(evt) }}>
                            <Dropdown.Toggle>
                                <img src={languages?.find(lang => lang.code === language)?.image||languages[0]?.image} className="mx-3" width={30} alt='AIG' />
                            {languages?.find(lang => lang.code === language)?.label?.toUpperCase() || languages[0]?.label?.toUpperCase()}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    langValues.map(lang => {
                                        return <Dropdown.Item
                                            key={lang?.value?.code}
                                            eventKey={lang?.value?.code}
                                        >
                                            <img src={lang?.value?.image} className="mx-3" width={30} alt={lang.label} />
                                            {lang.label}
                                        </Dropdown.Item>
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
             }
        </>
    )
}
