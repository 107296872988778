/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-template-curly-in-string */
import Counter from "components/Counter";
import { HeaderLogo } from "components/Header/HeaderLogo";
import InfoCompra from "components/PlaceToPay/InfoCompra";
import PaymentInformation from "components/PlaceToPay/PaymentInformation";
import { ESTADOS_P2P, ESTADO_P2P_LABELS } from "constants/P2P";
import useCustomToast from "hooks/useCustomToast";
import React, { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { apiGet, consumirServicioEncirptacion } from "services/api";
import { empresa, plataforma, urlCatalogosPoliza, urlSondaP2p } from "services/urls";
import { getQueryParams, strToDateWithTraduction } from "utils/functions";
import { Loading } from "../../pages/Loading/Loading";

const PagoUnico = () => {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [msgLoading,] = useState('I_FRONT_35');
  const qParams = getQueryParams();

  const [data, setData] = useState({});
  const [numPolicy, setNumPolicy] = useState('');

  const [verificandoEstado, setVerificandoEstado] = useState(false);

  const { addWarningToast, addSuccessToast } = useCustomToast();


  const history = useNavigate();

  const init = useCallback(async () => {
    setLoading(true);
    const Data = {
      empresa: empresa,
      plataforma: plataforma,
      Data: {
        idPoliza: qParams?.id,
        pasoCodigo: "paymentReturn",
      },
    };
    const res = await consumirServicioEncirptacion(urlCatalogosPoliza, Data, false, true);
    console.log("RES: ", res);
    res.correo = "pagos.pr@aig.com";
    setData(res);
    let descripcion = res?.transaction?.descripcion;
    const splitDesc = descripcion?.slice(descripcion.indexOf('#') + 1, descripcion?.length - 1);
    setNumPolicy(splitDesc.split(' ')?.[0]);
    try {
      switch (res.paymentStatus) {
        case ESTADOS_P2P.APROBADO:
        case ESTADOS_P2P.APPROVED:
          addSuccessToast(t("Your payment was APPROVED"));
          history(`/generador/${res?.url}/final?id=${qParams?.id}`);
          return;
        case ESTADOS_P2P.RECHAZADO:
        case ESTADOS_P2P.REJECTED:
          
          break;
        case ESTADOS_P2P.PENDING:
        case ESTADOS_P2P.PENDIENTE:
          
          break;
        case ESTADOS_P2P.FALLO:
        case ESTADOS_P2P.ERROR:
          
          break;
        default:
          addWarningToast(t("Unknown status"));
          break;
      }
    } catch (error) { }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  const onFinish = async () => {
    setVerificandoEstado(true);
    await apiGet(urlSondaP2p(qParams.id));
    init();
    setVerificandoEstado(false);
  };

  const onClickTryAgain = () => {
    history(`/generador/${data?.url}/payment?id=${qParams?.id}`);
  };

  return (
    <Loading isLoading={loading} message={msgLoading}>
      <main className='container'>
        <HeaderLogo />
        <div className='row justify-content-center mb-5'>
          <div className='col-11 col-md-8 col-xl-6 my-5'>
            <h1 className='text-center'>{t("Payment status")}</h1>
            {ESTADOS_P2P.PENDING === data.paymentStatus && (
              <React.Fragment>
                <Trans
                  i18nKey='p2pPaymentStatusPendingLegend'
                  defaults='<div>At this time, your order <bold>{{idPago}}</bold> for the amount of <bold>${{total}} USD</bold> is found in a status of <bold>{{status}}</bold>
                   receipt of confirmation by your financial entity. 
                  Please wait a few minutes to confirm if your payment was successful. 
                   If you need additional information about the current status of your payment, please send a request to <email>{{email}}</email></div>'
                  values={{
                    idPago: qParams.idPago,
                    total: data?.total,
                    email: data?.correo,
                    status: t(ESTADO_P2P_LABELS[data?.paymentStatus]),
                  }}
                  components={{
                    bold: <strong />,
                    email: (
                      <a
                        href={`mailto:${data?.correo}?Subject=${t("Payment%20status")}`}
                        className='text-bold'
                      />
                    ),
                    div: <div className='mb-5' />,
                  }}
                />

                {/* <InfoCompra total={data?.total} periocidad='unico' /> */}

                {!verificandoEstado && (
                  <React.Fragment>
                    <PaymentInformation
                      resultValue={data.paymentStatus}
                      policyRefValue={numPolicy}
                      paymentRefValue={qParams.idPago}
                      paymentDateValue={strToDateWithTraduction(
                        data?.paymentDate,
                        t,
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                      formatDate={false}
                    />
                    <h4>
                      {t("Consulting again in")}{" "}
                      <Counter
                        direction='backward'
                        initialTime={60000}
                        // initialTime={6000}
                        // initialTime={10000}
                        onFinish={onFinish}
                      />{" "}
                      {t("seconds")}
                    </h4>
                  </React.Fragment>
                )}

                {verificandoEstado && (
                  <h4>
                    <i
                      className='pi pi-spin pi-spinner me-1'
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    />
                    {t("We are verifying your payment status")}...
                  </h4>
                )}
              </React.Fragment>
            )}

            {[ESTADOS_P2P.REJECTED, ESTADOS_P2P.ERROR].includes(data.paymentStatus) && (
              <React.Fragment>
                {ESTADOS_P2P.REJECTED === data?.paymentStatus && (
                  <p>{t("Your payment was rejected")}.</p>
                )}

                {ESTADOS_P2P.ERROR === data?.paymentStatus && (
                  <p>
                    {t("An error has occurred.")}
                    <br />
                    {t("Do you wish to try again?")}
                  </p>
                )}

                <InfoCompra total={data?.total} periocidad='unico' />
                <PaymentInformation
                  resultValue={data.paymentStatus}
                  policyRefValue={numPolicy}
                  paymentRefValue={qParams.idPago}
                  paymentDateValue={strToDateWithTraduction(
                    data?.paymentDate,
                    t,
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                  formatDate={false}
                />
                <button
                  className='btn btn-aigpr text-white mt-5'
                  onClick={onClickTryAgain}>
                  {t("Try payment again")}
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
      </main>
    </Loading>
  );
};

export default PagoUnico;
