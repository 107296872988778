import React from "react";
import { useTranslation } from "react-i18next";

const TipoPagoRadioButton = ({ id, name, label, valor, value, onChange }) => {
  const { t } = useTranslation();
  return (
    <div style={{ width: "150px" }}>
      <input
        type='radio'
        className='align-self-center'
        name={name}
        value={valor}
        id={id}
        checked={value === valor}
        onChange={(evt) => onChange(evt.target.value)}
      />
      <label htmlFor={id} className='ms-1 no-text-selection cpointer'>
        {t(label)}
      </label>
    </div>
  );
};

export default TipoPagoRadioButton;
