/* eslint-disable no-eval */
import _ from "lodash";
import {
  createContainer,
  createHook,
  createStore,
  createSubscriber,
  defaults,
} from "react-sweet-state";

defaults.devtools = true;
defaults.batchUpdates = true;
/**
 * @typedef {object} GeneradorPaginaState
 *
 */
export const initialState = {
  callbacks: {
    onInit: () => {
      console.log("DEFAULT onInit");
    },
  },
  isLoading: false,
  generandoPagina: true,
  components: [],
  mappedComponents: {},
  pageState: {},
  resources: {},
  container: {},
};

/**
 *
 * @param {string} stringCode
 * @returns
 */
const mapFunctions = (objectReference, resources) => {
  return ([entryName, val]) => {
    if (typeof val === "string") {
      const callback = eval(val);
      objectReference[entryName] = callback(resources);
    }
  };
};

const mappProps = (props = {}, resources = {}) => {
  const newProps = {};
  Object.entries(props).forEach(([prop, val]) => {
    let name = prop;
    let value = val;
    if (name.startsWith("fn_")) {
      name = name.replace("fn_", "");
      const callback = eval(val);
      value = callback(resources);
    }
    newProps[name] = value;
  });

  return newProps;
};

const componentsCallback = (mappedComponents, pageState, resources = {}) => {
  return (component) => {
    component.props = mappProps(component?.props, resources);
    mappedComponents[component.id] = component;
    pageState[component.id] = { value: null, ...component?.preload };

    switch (component.type) {
      case "container":
      case "mainContainer":
      case "mainContainerChecks":
      case "formContainer":
      case "div":
        component.childrens = component.childrens.map(
          componentsCallback(mappedComponents, pageState, resources)
        );
        break;

      default:
        break;
    }

    return {
      ...component,
    };
  };
};

const GeneradorPaginasStore = createStore({
  name: "GeneradorPaginas",
  initialState,
  actions: {
    setState: (state) => {
      return ({ setState }) => {
        setState(state);
      };
    },
    resetState: (state = initialState) => {
      return ({ setState }) => {
        setState(state);
      };
    },
    generarPagina: (state, resources) => {
      return ({ setState, getState, dispatch }) => {
        if (!state) {
          return null;
        }
        // console.log(state)
        resources.setState = setState;
        resources.getState = getState;
        resources.dispatch = dispatch;

        resources.getPageState = (path = null, defaultValue = null) => {
          const values = getState();
          if (path) {
            return _.get(values, `pageState.${path}`, defaultValue);
          }
          return values?.pageState;
        };

        setState({
          generandoPagina: true,
          container: state?.pagContainer,
        });

        const mappedFunctions = {};
        if (state.pagFunctions) {
          Object.entries(state.pagFunctions).forEach(
            mapFunctions(mappedFunctions, resources)
          );
          resources.functions = mappedFunctions;
          Object.entries(state.pagFunctions).forEach(
            mapFunctions(mappedFunctions, resources)
          );
        }
        const mappedCallbacks = {};
        Object.entries(state?.pagCallbacks).forEach(([entryName, value]) => {
          if (typeof value === "string") {
            mappedCallbacks[entryName] = eval(value)(resources);
          }
        });

        const mappedComponents = {};

        const pageState = {};

        const newComponents = state.pagComponents.map(
          componentsCallback(mappedComponents, pageState, resources)
        );

        setState({
          generandoPagina: false,
          pageState,
          callbacks: mappedCallbacks,
          components: newComponents,
          mappedComponents,
          functions: mappedFunctions,
          resources: resources,
        });
      };
    },
    setPageState: (path, value) => {
      return ({ setState, getState }) => {
        const oldState = getState();
        const newState = _.set(oldState?.pageState, path, value);
        setState({
          pageState: {
            ...newState,
          },
        });
      };
    },
    setLoading: (state = true) => {
      return ({ setState }) => {
        setState({ isLoading: state });
      };
    },
  },
});

export const hookGenerador = createHook(GeneradorPaginasStore);

export const GeneradorSuscriber = createSubscriber(GeneradorPaginasStore);

export const GeneradorContainer = createContainer(GeneradorPaginasStore);

export const useGeneradorPagina = () => {
  return hookGenerador()[0];
};

export const useGeneradorPaginaActions = () => {
  return hookGenerador()[1];
};
