import { useDynamicForm } from "components/DynamicForm/state";
import {
  SimpleContainer,
  SimpleContainerHorizontal,
} from "components/Inputs/containers";

import {
  DropDown,
  DropDownDate,
  DropDownFilter,
  NumberInput,
  PrimeTextInput,
  SimpleCheckBox,
  TextInputMask,
  DivText,
  SimpleRadio,
  SimpleErrorMessage,
  SimpleCheckBoxHorizontal,
  SimpleCheckBoxHorizontalBasic,
  SimpleRadioSeparator,
  NumberInputDisabled,
} from "components/Inputs/inputs";
import React from "react";
import { Controller } from "react-hook-form";
import Archivos from "../Archivos";
import Geolocalizacion from "../Geolocalizacion";
import ShowOnFieldWrapper from "../ShowOnFieldWrapper";
import Tabla from "../Tabla";
import TermsConditions from "../TermsConditions";
import UbicacionInput from "../Ubicacion";
import { YesNoComponent } from "../YesNoComponent/YesNoComponent";
import { SimpleCheckBox as SimpleCheck } from "components/DynamicForm/components/CheckBox/CheckBoxSimple";
import { useGeneradorPagina } from "pages/GeneradorPaginas/store";
import TablaOptions from "../TablaOptions";
import { NumberDecimalQuestion } from "../NumberDecimalQuestion/NumberDecimalQuestion";
import TablaInputs from "../TablaInputs";
import TablaOptionsPercentage from "../TablaOptionsPercentage";
import TablaGroup from "../TablaGroup";
import TablaDirection from "../TablaDirection";
import TablaOptionsGross from "../TablaOptionsGross";
import { TextoQuestion } from "../TextoQuestion/TextoQuestion";
import { htmlMessage } from "components/DynamicForm/utils/funcionesGenerales";
import { Col, Row } from "react-bootstrap";
import { DateQuestion } from "../DateQuestion/DateQuestion";
import TablaOptionsHeader from "../TablaOptionsHeader";
import TablaOptionsUmbrella from "../TablaOptionsUmbrella";
import TablaOptionsGrossConsultants from "../TablaOptionsGrossConsultants";
import TablaOptionsGrossLawyers from "../TablaOptionsGrossLawyers";

/**
 *
 * @param {{campos:import("components/DynamicForm/typedefs").Campo[]}} param0
 * @returns
 */
const SimpleCheckBox2 = (campo) => <SimpleCheck {...campo} id={campo.name} />;
const Texto2 = (campo) => (
  <div
    className={`col${campo.columnas ? "-md-" + campo.columnas : ""
      } col-sm-12 mt-2`}
  >
    <SimpleContainerHorizontal {...campo.containerProps} {...campo}>
      <PrimeTextInput {...campo} />
    </SimpleContainerHorizontal>
  </div>
);

const Hr = () => (
  <div className="col-12 my-3">
    <hr style={{ opacity: 0.5 }} />
  </div>
);

const Br = () => (
  <div className="col-12 my-3">
    <br style={{ opacity: 0.5 }} />
  </div>
);

const Campos = ({ campos, preload = false, infoCoverageDesired , infoUmbrella, aprobRetroDate, retroDate, campo1, campo2, campo3}) => {




  const { fields } = useDynamicForm();
  const { pageState } = useGeneradorPagina();

  return (
    campos?.map?.((campo) => {
      const {
        containerProps,
        isShowing,
        mensajeError,
        submitRules,
        showOn,
        columnas,
        ...rest
      } = campo;

      const componentes = {
        checkbox2: SimpleCheckBox2(campo),
        texto2: Texto2(campo),
        hr: Hr(),
        br: Br(),
        divText: DivText(campo),
      };

      let component = componentes[campo.type];

      if (component === undefined) {
        switch (campo.type) {
          case "email":
            component = (
              <div
                className={`col${columnas ? "-md-" + columnas : ""
                  } col-sm-12 mt-2`}
              >
                <SimpleContainer {...containerProps}>
                  <PrimeTextInput {...rest} type="email" />
                </SimpleContainer>
              </div>
            );
            break;

          case "string":
          case "text":
          case "texto":
          case "password":
            (campo.name === 'name') && console.log(rest);
            component = (
              <div
                className={`col${columnas ? "-md-" + columnas : ""
                  } col-sm-12 mt-2`}
              >
                <SimpleContainer {...containerProps}>
                  <PrimeTextInput {...rest} tipoProducto={pageState?.['tipoProducto']} disabledTravel={campo.name == 'nameListTravelAgents' && pageState?.['tipoProductoTravel'] == 'individual'} />
                </SimpleContainer>
              </div>
            );
            break;

          case "text-int":
            component = (
              <div
                className={`col${columnas ? "-md-" + columnas : ""
                  } col-sm-12 mt-2`}
              >
                <SimpleContainer {...containerProps}>
                  <PrimeTextInput keyfilter="int" {...rest} />
                </SimpleContainer>
              </div>
            );
            break;

          case "number":
          case "numberInt":
          case "numberDecimal":
          case "entero":
            component = (
              <div
                className={`col${columnas ? "-md-" + columnas : ""
                  } col-sm-12 mt-2`}
              >
                <SimpleContainer {...containerProps}>
                  <NumberInput {...rest} />
                </SimpleContainer>
              </div>
            );
            break;
          case "entero-disabled":
            component = (
              <div
                className={`col${columnas ? "-md-" + columnas : ""
                  } col-sm-12 mt-2`}
              >
                <SimpleContainer {...containerProps}>
                  <NumberInputDisabled {...rest} />
                </SimpleContainer>
              </div>
            );
            break;

          case "select":
          case "dropdown":
          case "lista":
            component = (
              <div
                className={`col${columnas ? "-md-" + columnas : ""
                  } col-sm-12 mt-2`}
              >
                <SimpleContainer {...containerProps}>
                  <DropDownFilter
                    {...rest}
                    options={fields[rest.id]?.options}
                  />
                </SimpleContainer>
              </div>
            );
            break;

          case "YesNoSelect":
          case "SiNoSelect":
            component = (
              <div
                className={`col${columnas ? "-md-" + columnas : ""
                  } col-sm-12 mt-2`}
              >
                <SimpleContainer {...containerProps}>
                  <DropDown
                    {...rest}
                    options={[
                      { label: "SI", value: "SI" },
                      { label: "NO", value: "NO" },
                    ]}
                  />
                </SimpleContainer>
              </div>
            );
            break;

          case "tabla":
            component = (
              <React.Fragment>
                <Tabla campo={campo} />
              </React.Fragment>
            );
            break;
          case "tabla-group":
            component = (
              <React.Fragment>
                <TablaGroup campo={campo} />
              </React.Fragment>
            );
            break;

          case "tabla-direction":
            component = (
              <React.Fragment>
                <TablaDirection campo={campo} />
              </React.Fragment>
            );
            break;
          case "tabla-inputs":
            component = (
              <React.Fragment>
                <TablaInputs campo={campo} />
              </React.Fragment>
            );
            break;

          case "fecha":
          case "date":
            component = (
              <div
                className={`col${columnas ? "-md-" + columnas : ""
                  } col-sm-12 mt-2`}
              >
                <SimpleContainer {...containerProps}>
                  <DropDownDate {...rest} />
                </SimpleContainer>
              </div>
            );
            break;

          case "ubicacion":
            component = (
              <UbicacionInput configuracion={rest?.configuracion} {...rest} />
            );
            break;

          case "mask":
            component = (
              <div
                className={`col${columnas ? "-md-" + columnas : ""
                  } col-sm-12 mt-2`}
              >
                <SimpleContainer {...containerProps}>
                  <TextInputMask {...rest} />
                </SimpleContainer>
              </div>
            );
            break;
          case "checkbox":
            component = (
              <div
                className={`col${columnas ? "-md-" + columnas : ""
                  } col-sm-12 mt-2`}
              >
                <SimpleCheckBox
                  id={rest.name}
                  name={rest.name}
                  rules={rest.rules}
                  label={rest.label}
                />
              </div>
            );
            break;

          case "checkbox-options":
            component = (
              <React.Fragment>
                <label className="mt-3">{campo?.label}</label>
                {campo?.options?.map((option) => (
                  <div className="d-flex flex-row my-3" key={option.label}>
                    <Controller
                      name={`${campo.name}.${option?.value.value}`}
                      defaultValue={{
                        ...option?.value,
                        checked: false,
                      }}
                      render={({ field }) => {
                        return (
                          <React.Fragment>
                            <input
                              id={field.name}
                              type="checkbox"
                              className="align-self-center"
                              checked={field.value?.checked}
                              value={field.value?.checked}
                              onChange={(e) => {
                                field.onChange({
                                  ...option?.value,
                                  checked: e.target.checked,
                                });
                              }}
                            />
                            <label
                              htmlFor={field.name}
                              className="align-self-center ms-2"
                            >
                              {option.label}
                            </label>
                          </React.Fragment>
                        );
                      }}
                    />
                  </div>
                ))}
              </React.Fragment>
            );
            break;
          case "checkbox-options-lista":
            component = (
              <React.Fragment>
                <Col md={12}>
                  <label className="mt-12">{htmlMessage(campo?.label)}</label>
                </Col>

                {campo?.options?.map((option) => (
                  <Col md={12} className="mt-12" key={option.label}>
                    <Controller
                      name={`${campo.name}.${option?.value.value}`}
                      defaultValue={null}
                      render={({ field }) => {

                        if (campo.name == 'c8Umbrella' && field.value == null) {
                          console.log('c8Umbrella', field.value)

                          field.onChange(
                            {
                              "label": "Manual Total",
                              "value": "manualTotal",
                              "checked": false
                            }
                          )
                        }
                        return (
                          <React.Fragment>
                            <div style={{ paddingLeft: 35 }}>
                              <input
                                id={field.name}
                                type="checkbox"
                                className="align-self-center"
                                checked={field.value?.checked}
                                value={field.value?.checked}
                                onChange={(e) => {
                                  field.onChange({
                                    ...option?.value,
                                    checked: e.target.checked,
                                  });
                                }}
                              />
                              <label
                                htmlFor={field.name}
                                className="align-self-center ms-2 text-secondary"
                              >
                                {option.label}
                              </label>

                            </div>

                          </React.Fragment>
                        );
                      }}
                    /><br />
                  </Col>
                ))}
              </React.Fragment>
            );
            break;

          case "radio-button-options":
            component = (
              <div
                className={`col${columnas ? "-md-" + columnas : ""
                  } col-sm-12 mt-2`}
              >
                <SimpleRadio
                  infoCoverageDesired={infoCoverageDesired}
                  id={rest.name}
                  infoUmbrella={infoUmbrella}
                  name={rest.name}
                  rules={rest.rules}
                  label={rest.label}
                  options={rest.options}
                />
              </div>
            );
            break;

          case "radio-button-options-separator":
            component = (
              <div
                className={`col${columnas ? "-md-" + columnas : ""
                  } col-sm-12 mt-2`}
              >
                <SimpleRadioSeparator
                  infoCoverageDesired={infoCoverageDesired}
                  id={rest.name}
                  name={rest.name}
                  rules={rest.rules}
                  label={rest.label}
                  options={rest.options}
                />
              </div>
            );
            break;

          case "radio-options":
            component = (
              <React.Fragment>
                <p>{campo.label}</p>

                {campo?.options?.map((option, index) => (

                  <Controller
                    key={option.label}
                    name={`${campo.name}.${option?.value.value}`}
                    defaultValue={{
                      ...option?.value,
                      checked: index == 0 ? false : false,
                    }}
                    render={({ field }) => {

                      return (
                        <React.Fragment>

                          <input
                            id={field.name}
                            type="radio"
                            name={campo.name}
                            className="align-self-center"
                            checked={field.value?.checked}
                            value={field.value?.checked}
                            onChange={(e) => {
                              field.onChange({
                                ...option?.value,
                                checked: e.target.checked,
                              });
                            }}
                          />
                          <label
                            htmlFor={field.name}
                            className="align-self-center ms-2"
                          >
                            {option.label}
                          </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </React.Fragment>
                      );
                    }}
                  />

                ))}
              </React.Fragment>
            );
            break;

          case "checkbox-options-horizontal":
            component = (
              <div
                className={`col${columnas ? "-md-" + columnas : ""
                  } col-sm-12 mt-2`}
              >
                <SimpleCheckBoxHorizontal
                  infoCoverageDesired={infoCoverageDesired}
                  id={rest.name}
                  name={rest.name}
                  rules={rest.rules}
                  label={rest.label}
                  options={rest.options}
                />
              </div>
            );
            break;

          case "checkbox-options-horizontal-basic":
            component = (
              <div
                className={`col${columnas ? "-md-" + columnas : ""
                  } col-sm-12 mt-2`}
              >
                <SimpleCheckBoxHorizontalBasic
                  infoCoverageDesired={infoCoverageDesired}
                  id={rest.name}
                  name={rest.name}
                  rules={rest.rules}
                  label={rest.label}
                  options={rest.options}
                  onChangeMain={(values) => {

                  }}
                />
              </div>
            );
            break;

          case "archivos":
            component = <Archivos campo={campo} aprobRetroDate={aprobRetroDate} retroDate={retroDate} campo1={campo1} campo2={campo2} campo3={campo3} />;
            break;

          case "geolocalizacion":
            component = <Geolocalizacion {...rest} />;
            break;
          case "YesNoQuestion":
          case "YesNo":
            component =<React.Fragment>
               <YesNoComponent preload={preload} {...rest} aprobRetroDate={aprobRetroDate} retroDate={retroDate} campo1={campo1} campo2={campo2} campo3={campo3}  />
            </React.Fragment>;
            break;

          case "termsConditions":
            component = <TermsConditions {...rest} />;
            break;

          case "tabla-options":
            component = (
              <React.Fragment>
                <TablaOptions campo={campo} />
              </React.Fragment>
            );
            break;

          case "tabla-options-umbrella":
            component = (
              <React.Fragment>
                <TablaOptionsUmbrella campo={campo} />
              </React.Fragment>
            );
            break;

          case "tabla-options-header":
            component = (
              <React.Fragment>
                <TablaOptionsHeader campo={campo} />
              </React.Fragment>
            );
            break;

          case "tabla-options-gross":
            component = (
              <React.Fragment>
                <TablaOptionsGross campo={campo} />
              </React.Fragment>
            );
            break;
          case "tabla-options-gross-consultants":
            component = (
              <React.Fragment>
                <TablaOptionsGrossConsultants campo={campo} />
              </React.Fragment>
            );
            break;
          case "tabla-options-gross-lawyers":
            component = (
              <React.Fragment>
                <TablaOptionsGrossLawyers campo={campo} />
              </React.Fragment>
            );
            break;

          case "tabla-options-percentage":
            component = (
              <React.Fragment>
                <TablaOptionsPercentage campo={campo} />
              </React.Fragment>
            );
            break;

          case "numberDecimalQuestionPercentage":
            case "numberDecimalQuestionPercentageCheck":
          case "numberDecimalQuestion":
            component = <NumberDecimalQuestion containerProps={{ ...containerProps }} preload={preload} {...rest} type={campo.type} />;
            break;

          case "textoQuestion":
            component = <TextoQuestion containerProps={{ ...containerProps }} preload={preload} {...rest} />;
            break;

          case "dateQuestion":
            component = <DateQuestion containerProps={{ ...containerProps }} preload={preload} {...rest} />;
            break;

          default:
            break;
        }
      }
      if (!showOn) {
        return <React.Fragment key={rest.id}>{component}</React.Fragment>;
      }

      return (
        <ShowOnFieldWrapper {...{ ...rest, showOn }} key={rest.id}>
          {component}
        </ShowOnFieldWrapper>
      );
    }) || null
  );
};

export default Campos;
