import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useGeneradorPaginaActions } from "../store";
import { scrollTop } from '../../../utils/Funciones/index'

const FormContainer = ({ props, children, id, formProps, onSubmit = () => { console.log('onSubmit') }, onSubmitError = (values) => { console.log(values); scrollTop() } }) => {
  const { setPageState } = useGeneradorPaginaActions();

  const methods = useForm(formProps);

  useEffect(() => {
    setPageState(`${id}.methods`, methods);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <FormProvider {...methods}>
      {/* <input
        type='text'
        name='nombre'
        id='nombre'
        {...methods.register("nombre", {
          required: "Obligatorio",
        })}
      />
      <ErrorMessage name='nombre' /> */}
      <form
        id={id}
        noValidate
        autoComplete="off"
        className="d-flex justify-content-center col-12"
        onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}
      >
        <div {...props}>{children}</div>
      </form>
    </FormProvider>
  );
};

export default FormContainer;
