
const COLORS={
  primary: '#001871',

  coreBlue: '#001871',
  cobalt: '#1352DE',
  paleBlue: ' #C7DBF4',

  cyan: '#00A4E4',
  cyan50: '#80D2F2',
  paleCyan: '#C0E7F7',
  paleCyan50: '#E0F3FB',

  red: ' #EC1944',
  red50: ' #F68CA2',
  paleRed: ' #F7CED7',
  paleRed50: ' #FBE7EB',

  orange: '#FF8200',
  orange60: '#FFB466',
  paleOrange: '#FECB8B',
  paleOrange50: '#FFE5C5',

  yellow: '#FFBF3F',

  purple: ' #904FD8',
  purple60: '#BC95E8',
  palePurple: '#C5B4E3',
  palePurple50: '#E2DAF1',

  teal: ' #00BFB3',
  teal50: ' #80DFD9',
  paleTeal: ' #C1F0E8',
  paleTeal50: ' #E0F8F3',

  gold: '#ffbf3f',
  amplia: '#05adee',
  limitada: '#7030a0',
  basica: '#7f7f7f',
};

export default COLORS;
