import { types } from "redux/types/types";

export const initialState = {
    producto: null,
    products: [],
    isLoading: false,
    selectedTerrorism: false
}

export const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_PRODUCTS:
            return {
                ...state,
                products: [...action.payload],
            }

        case types.LOADING_PRODS:
            return {
                ...state,
                isLoading: true
            }
        case types.STOP_LOADING_PRODS:
            return {
                ...state,
                isLoading: false
            }
        case types.SET_SELECTED_OPTION:
            return {
                ...state,
                selectedOption: action.payload,
            };
        case types.SET_SELECTED_TERRORISM:
                return {
                    ...state,
                    selectedTerrorism: action.payload,
                };


        default:
            return state;
    }
}