import React from "react";
import Timer from "react-compound-timer";

const Counter = (props) => {
  const validarCero = (value) => {
    if (value < 10) {
      return `0${value}`;
    }
    return value;
  };

  return (
    <Timer
      {...props}
      checkpoints={[
        {
          time: 0,
          callback: () => props.onFinish && props.onFinish(),
        },
      ]}>
      <Timer.Minutes formatValue={validarCero} />
      {":"}
      <Timer.Seconds formatValue={validarCero} />
    </Timer>
  );
};

export default Counter;
