import { useGeneradorPagina } from "../../../../pages/GeneradorPaginas/store";
import { NumberInput, SimpleErrorMessage } from "components/Inputs/inputs";
import moment from "moment";
import React, { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { SimpleContainer } from "components/Inputs/containers";
import Campos from "../Campos";
import { InputNumber } from "primereact/inputnumber";
import { values } from "lodash";
import { InputText } from "primereact/inputtext";

const ID_ATTR = "UNIQUE_ID_FOR_INTERNAL_OPERATIONS";

/**
 *
 * @param {{campo:import("components/DynamicForm/typedefs").Campo}} props
 * @returns
 */
const TablaOptionsGross = ({ campo }) => {

  const [showModal, setShowModal] = useState(false);
  const { pageState } = useGeneradorPagina();
  const toggle = () => {
    methods.reset({
      [ID_ATTR]: null,
      name: pageState?.['policyFormData']?.value?.usuNombre || 'No Name'
    });
    setShowModal(!showModal);
  };

  const methods = useForm({ mode: "onChange" });

  const _onSubmit = (field) => (formData) => {
    Object.entries(formData).forEach(([key, value]) => {
      if (typeof value === 'object' && value instanceof Date) {
        formData[key] = moment(value).format('MM/DD/YYYY');
      }
      return true;
    })
    if (formData[ID_ATTR]) {
      field.value = field.value.filter(
        (item) => item[ID_ATTR] !== formData[ID_ATTR]
      );
      formData[ID_ATTR] = uuidv4();
      field.value.push(formData);
    } else {
      formData[ID_ATTR] = uuidv4();
      field.value.push(formData);
    }
    field.onChange(field.value);
    toggle();
  };

  const htmlMessage = (mssg) => {
    return (
      <div
        style={{
          // fontSize: "1rem",
          fontFamily: "Futura-Medium"
        }}
        dangerouslySetInnerHTML={{
          __html: mssg,
        }}
      />
    );
  };
  return (
    <Controller
      name={campo.name}
      defaultValue={[]}
      rules={campo.rules}
      render={({ field: { value, onChange, ref } }) => (
        <div className="col-12 my-5">
          <div className="d-flex mb-3">
            <div className="col-md-8 col-sm-12">
              {htmlMessage(campo.label)}

            </div>
          </div>
     

          <table className="table table-bordered table-responsive-lx table-responsive-l table-responsive-md">
            <thead>
              <tr>
                {campo?.options?.filter(o => o.value.tipo == 'columna').map((opcion) => {
                  return (
                    <th key={opcion.label} style={{fontSize:13}} className="thead-center">{opcion.label}</th>

                  )

                })}



              </tr>
            </thead>
            <tbody>
              {campo?.options?.filter(o => o.value.tipo == 'fila').map((opcion) => {







                return (
                  <tr key={opcion.label}>



      
                      <th className={opcion.label == 'Total' ? "thead-right":"thead-left"} style={{paddingLeft:"20px", paddingRight:"20px"}}>{opcion.label}</th>
                

                    <FormProvider {...methods}>




                      {campo?.subCampos?.filter(c => c.name != opcion.value.value).map((subCampo) => {





                        let codigoOpcion = opcion.value.value.split('_').pop()
                        let codigoSubcampo = subCampo.name.split('_').pop()
                        let sub = []

                        sub.push(subCampo)



                        return (
                          codigoOpcion == codigoSubcampo ?
                            <td key={subCampo.name} className={"text-center"}>


                              {subCampo.type == 'checkbox' &&

                                <React.Fragment>

                               
                                  <br></br>

                                  <input
                                    id={subCampo.name}
                                    type="checkbox"
                                    className="align-self-center"
                                    checked={value[subCampo.name] ? value[subCampo.name].checked : false}
                                    value={value[subCampo.name] ? value[subCampo.name].checked : false}
                                    onChange={(e) => {

                                      const valueAux = { ...value }

                                      valueAux[subCampo.name] = {
                                        ...value[subCampo.name]?.value,
                                        checked: e.target.checked,
                                      }



                                      onChange(valueAux);



                                    }}
                                  />

                                </React.Fragment>




                              }

                              {(subCampo.type == 'texto' || subCampo.type == 'numberDecimal') &&
                                <SimpleContainer {...subCampo.containerProps} disabled={opcion.value.disabled}>

                                  {subCampo.type == 'texto' &&

                                    <InputText
                                      ref={ref}

                                      value={value[subCampo.name]}

                                      onChange={(e) => {
                                        const valueAux = { ...value }

                                        valueAux[subCampo.name] = e.value



                                        onChange(valueAux);
                                      }}
                                      name={subCampo.name}
                                      style={{
                                        border: "none",
                                        outline: "none",
                                        fontFamily: "SourceSansPro-Regular",
                                      }}
                                      type={"text"}


                                    />
                                  }






                                  {subCampo.type == 'numberDecimal' &&

                                    <InputNumber
                                      className='input__mask__mex'
                                      value={value[subCampo.name]}
                                      disabled={opcion.value.disabled}

                                      onChange={(e) => {

                                        const valueAux = { ...value }

                                        valueAux[subCampo.name] = e.value

                                

                                        if (campo.name == 'questions.fRealEstate') {


                                          const code = subCampo.name.split('_')[0]

                               

                                          var suma = 0

                                          for (const key in valueAux) {

                                            console.log('code1',key)

                                            const codeSubcampo = key.split('_')[0]
                                            if (codeSubcampo == code && !key.includes('_TG')) {
                                              console.log('resttt', key, valueAux[key])
                                              suma += parseFloat(valueAux[key] != null ? valueAux[key] : 0)
                                            }

                                          }

                                          console.log('code',suma)



                                          valueAux[`${code}_TG`] = suma

                                        }

                                        if (campo.name == 'questions.bLawyers') {


                                          const code = subCampo.name.split('_')[0]

                               

                                          var suma = 0

                                          for (const key in valueAux) {

                                            console.log('code1',key)

                                            const codeSubcampo = key.split('_')[0]
                                            if (codeSubcampo == code && !key.includes('_TO')) {
                                              console.log('resttt', key, valueAux[key])
                                              suma += parseFloat(valueAux[key] != null ? valueAux[key] : 0)
                                            }

                                          }

                                          console.log('code',suma)



                                          valueAux[`${code}_TO`] = suma

                                        }

                                        onChange(valueAux);
                                      }}
                                      name={subCampo.name}
                                      style={{
                                        // border: "none",
                                        outline: "none",
                                        fontFamily: "SourceSansPro-Regular",

                                      }}
                                      inputStyle={{

                                        width: '100px',
                                        backgroundColor:opcion.value.disabled ?'#dddddd':null
                                      }}

                                      // mode="decimal"

                                      inputId={subCampo.name}

                                    />
                                  }



                                </SimpleContainer>
                              }



                            </td>
                            : null
                        )

                      })}
                    </FormProvider>


                  </tr>

                )

              })}
            </tbody>
          </table>
          <div className="w-100">
            <SimpleErrorMessage name={campo.name} />
          </div>



        </div>
      )}
    />
  );
};

export default TablaOptionsGross;
