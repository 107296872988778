import throttle from "lodash/throttle";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { createReducer } from "../redux/reducers/rootReducer";
import { loadState, saveState } from "../utils/localStorage";

const persistedState = loadState();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/**
 * @type {{
 *  dispatch:import("redux").Dispatch,
 *  subscribe:(listener:CallableFunction)=>void,
 *  getState:()=>any;
 *
 * }}
 */
export const store = createStore(
  createReducer(),
  persistedState,
  composeEnhancers(applyMiddleware(thunk))
);

store.asyncReducers = {};

store.injectReducer = (key, reducer) => {
  store.asyncReducers[key] = reducer;
  store.replaceReducer(createReducer(store.asyncReducers));
  return store;
};

store.subscribe(
  throttle(() => {
    let estado = store.getState();
    saveState({ login: estado.login });
  }, 1)
);
