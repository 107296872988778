import axios from "axios";
import moment from "moment";
import queryString from "query-string";
import { urlGetMyIp } from "services/urls";

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const toB64 = (obj) => Buffer.from(JSON.stringify(obj)).toString("base64");

export const fromB64 = (b64) => JSON.parse(Buffer.from(b64, "base64").toString());

export const getQueryParams = () => {
  const qParams = queryString.parse(window.location.search);

  if (qParams?.query) {
    const query = fromB64(qParams?.query);
    return {
      ...qParams,
      ...query,
    };
  }
  return qParams;
};
export const getIp = async () => {
  try {
    const res = await axios.get(urlGetMyIp);
    if (res?.status === 200) {
      return res?.data;
    }
    throw new Error();
  } catch (error) {
    return (
      error?.response?.data?.errorDescripcion || "Error al consultar la IP del cliente"
    );
  }
};

export const downloadFileFromUrl = (url, filename = "") => {
  let anchor = document.createElement("a");
  anchor.style.display = "none";
  document.body.appendChild(anchor);
  anchor.href = url;
  anchor.target = "_blank";
  anchor.download = filename;
  anchor.click();
  document.body.removeChild(anchor);
};

export const dollarFormater = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

/**
 *
 * @param {string} date
 * @returns {string}
 */
export const strToDateFormat = (date, inputFormat = "yyyy-MM-DD HH:mm:ss") => {
  if (moment(date, inputFormat).isValid()) {
    return moment(date).format("MM-DD-YYYY hh:mm:ss");
  }

  return null;
};

export const strToDateWithTraduction = (date, t, inputFormat = "yyyy-MM-DD HH:mm:ss") => {
  const dateFormat = t("dddd[,] MMMM DD[,] YYYY, [at] ");
  const mDate = moment(date, inputFormat);
  const hora = moment(date, inputFormat);

  return mDate.format(dateFormat).toString().toUpperCase() + hora.format("hh:mm:ss a");
};


export const sortNumberStrings = (arr = []) => {
  return arr.sort((a, b) => {
    let aParse = a.label.substring(a.label.indexOf("$") + 1, a.label.indexOf("/")).replaceAll(",", "");
    let bParse = b.label.substring(a.label.indexOf("$") + 1, a.label.indexOf("/")).replaceAll(",", "");;
    if (aParse.match(/^[0-9]*$/) && bParse.match(/^[0-9]*$/)) {
      return parseInt(aParse) - parseInt(bParse)
    }
    if (aParse.match(/^[0-9]*$/)) {
      return -1
    }
    if (bParse.match(/^[0-9]*$/)) {
      return 1
    }
    return aParse.localeCompare(bParse)
  })
}
