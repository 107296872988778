import sha512 from "./crypto/sha512";
import rsa from "./crypto/rsa";



export function encriptarRsa(tramaEntrada) {
  var rsa_ = new rsa();
  rsa_.setPublicCert(PublicKeyWeb);
  return rsa_.encrypt_b64(tramaEntrada);
}

export function encriptarContrasenia(contrasenia) {
  return sha512.sha512(contrasenia);
}

export const desencriptarRsa = trama => {
  try {
    var rsa_ = new rsa();
    rsa_.setPrivateCert(PrivateKeyMiddleware);
    return rsa_.decrypt(trama);
  } catch (error) {
    return "error";
  }
};

export function desencriptarTramaAES(tramaEntrada, key, iv) {
  try {
    let llave = desencriptarRsa(key);
    let cuatro = desencriptarRsa(iv);
    llave = llave.replace(new RegExp('"', "g"), "");
    cuatro = cuatro.replace(new RegExp('"', "g"), "");
    var noencriptado = decryptAES(tramaEntrada, llave, cuatro);
  } catch (error) {
    console.log(error)
  }

  return JSON.parse(noencriptado);
}

function decryptAES(data, key, iv) {
  var crypto = require("crypto");
  var decipher = crypto.createDecipheriv("aes-256-cbc", key, iv),
    buffer = Buffer.concat([
      decipher.update(Buffer.from(data, "base64")),
      decipher.final()
    ]);
  return buffer.toString();
}


function makellaves(length) {
  var result = '';
  var characters = '0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

// /************* */
export function encriptarTramaAES(tramaEntrada) {
  try {
    let key = makellaves(32)
    let iv = makellaves(16)
    var trama = encryptAES(tramaEntrada, key, iv);
    key = encriptarRsa(key)
    iv = encriptarRsa(iv)
    return { trama, iv, key };
  } catch (error) {
    console.log(error);
  }
}


function encryptAES(text, key, iv) {
  var crypto = require("crypto");
  var alg = "aes-256-cbc";
  key = new Buffer(key);
  iv = new Buffer(iv);
  var cipher = crypto.createCipheriv(alg, key, iv);
  var encoded = cipher.update(text, "ascii", "base64");
  encoded += cipher.final("base64");
  return encoded;
}

const PrivateKeyMiddleware = `-----BEGIN RSA PRIVATE KEY-----
MIIJKQIBAAKCAgEA8Df40sRBUL+PbE+GJbJG1d1zNPbnkgyg2f3ax1TSxsrKZD6l
6IxO7bkdLrGB9e5wYRa8oqIUXEKo7qe9N+dtzaOg0PAmEyDVcLk/iXjlaj73uyjn
IQEbh/TcoEk8UnZPmOytg0QnZwWcV+tQG6hVQ2VdEHOSQDLyINs9i5NXvncfx6UP
i8SoK7DrLtNeKBPhGfCpc5fLZBuzPvhmUx4JKItdSieUhKhdfkOz3ZkTAfCU64/X
keTYxhWi25L06i+cr5CUo4OJ1Ai/fOxTA5O/cR+/akEty697Tw+cGXhnHEzV41fW
6v/9NMd5l4KEbjaBFjBaEFuuMOw99ViTX0s/meF64WgEjKxSruGlrwM+3fdKTkPk
oVx7YauHH5zGNoozJXit4i4+YkYIvNNY8qbEfH4D3pRyh4n7QyisYaHJapcHr8Jw
tZxpjYxm6mNY1d4qCx9aGFJGUcwYRvi8JSW/aiHSrjSBTZ3UKf+rzmHYw8uAnunK
JqQKYUvxQSeYd1tXkZyGOwM6PKxpPUtd+vq+I0jVG3HRo1aSBjkrp86ej+Y6/GI7
RHR1jfekxMDduJb7Rsol5VdqVHBalIS4Wg6T5n2UKEe60nBj4zXr/6Arc/fI8b7r
3dD2STuKdwyYwGKZbzxk/E5BznL79TOLvGC5qnadWKZdWmI+rtvVireF6GkCAwEA
AQKCAgB6Wcfech9iZW9Dl22YHal+nIBlZayk8tGqtmWMSWPh5MAZNt+CBpTc+AAp
HDg5buzGRrJlk3ATXdB1XfI+Wx3OM51hTi/WZk7QT0pRVsZiyxeauEagxNOk9863
xRV4WtJH7aSiyz/WdUEgdrYioCZtYV95VaQUFt7EZWZ/hNiYJkyFf0jfw5Z0SnQ/
MWEvwJBSigWE3re1kXZGiHZ1t0Kq5/DoKCi5OJgGC629HI5V2pe/M1B0+r5+hd6h
P8SG0yFXOqi6b/DvklUV57iQhdxoXec8013j9Mk1h5nWT0EVZP08Ai1xHFl3XHJN
/Sg+inxNNOtqkYezW2Bjam4Fh2ooldPNCirsHAtnXF2hneM+C8+mFpeeDV4Qyx9J
0krzvtYJkaLxU4h1LCPFKQ/gPaO8WiMTRCapCM3BEKanTCFGTqf5GZmiVG6hKG/S
WIBDYckWTNZhmg8WMem0sIy54vXOsxqLP8d4EuTF98H6asY8GQIH5+1NVPxfPyOW
9h57PQkZirBBDZGLe0oSSla4sSq35HJgjRzW0YRLl/ab7v+m0/TDSudE09TosMwg
uh1HAYmBsSSdNmm5jpG2vVpnennvBxae53em4g3yLOpwuTTc/cpQbhPOiTuXVZpJ
d5yBGUPJZSgicUfTnDMOVJIwJjJkv5LaXOlXFsNp+MobYYMW0QKCAQEA+jOZ8lBj
6vJNV2aw3JStHoK4mxVwtOWGExRdzgXARcIOmFkj9ZUoCxAyvWg6aIjv/1JVKoz/
GYeVxP8PYxWqvUN6jMmGj6JxZnV6nM3BTxO459ypFQ776Jj2ySEePF9G6dOGVfDH
uV/Zr5BLoasVqSGDO4ze9G3iLlhoBfI6WBW8oK9mmDctCen82Zz1Zb1MTqBpyh8u
vE4z+1t4IUqot5naoILgjNYAPb82dt/7EWhmYWhQf2NGT9/59HZA+T7v0sUOMPgS
bQOmIQT49cs70KUEx7S8YW2fEkyI3J4VWCUA8yI1IdHGBqsj8+XW2j266OpK8Y4E
ugqWEbartHLdtwKCAQEA9ckkz2813MZbKTT6Ex3vksL37na+QJoDZ7uECudQbcff
I3qq98PxF3hkMXMeK9tz+DWuyMQV/L9QBUkxF6VX2yEhaStnBQowP7ROfQ0+crUT
Adx758Z5AZeew2WRorqHtbOkN2JqohAN9FJ3o8wTEUx66vh+DxCzDjyd6WBoLdWV
ZE+64XeAy8bHTCq2MNEJarY8SeBBwK3strmrieYgZdnrvKtqegA2h2VgGlWXPvOD
5cM1Ox9h33rW0gVhuEcOKHMWDeAe4lxu0WCl4dPqlLleAfNUYd3CLA1AyUHdNwWS
/9bEZtwppWp5g96snc9S9nbxPWLsASXTjMQQ1Z9q3wKCAQByk8bgDKh1I1j/uWss
2HkfC8X2Xy8p6CIqAp2ih0exqlh8s/WftRqNVnGmA2EcFfmx9sZBoigATPLtL9Cg
E4AJBCnO50rT5BQCU7I+tIrd5645n/deSWpa7hG1sZDjHM6grdlK6eTMo3RQzceU
1YAp38r1LmG7lcaKarnYX0EQrYOgIjLdagcaDylXjmVinfmf2B4aDYWdfkKXzR+K
P2JcEfzonIaweB/MJxCOB6w2IJUh7l9OtuuwEGWq6ax/d67j2pHRIwltGsqtPFW2
WceemLNDsSTDiSvbeWkDfvFPWELDFNs4DZSd4Smpu4tUjghWOVTwoZTQjaCDpB+l
lySpAoIBAQC+rppcRA8phMncojkQ87yVw6TN4FmhjNrvc07xlpuTT+kRgW16Yw18
A+7XXlQd+ldyTVOYdmgIefP+5dWsXp5WZtGg4gc+pbTg92WdJtU38LsaSo/mNNhU
H+kP9pCSCtyWXxQb08KekI4C6qfe78grN4Z1HOvPjEkd1nE8SYsJZBZOiHyXxzqo
3UJpGQnXvZTssQz803qvnT90U399sIeZ/QMikGo+1EN5r3BrfdOlBw9hBZ0ajTJF
Suu2bG5vpNCbGQ4XHbVYMBXZYn3PQbVQRK9mG/1vH7gwrOeCPBL/SNfOnDQrCMtW
hdBYOjz5AYt/oq9tzH0ENvB02Pemg9NJAoIBAQDnIMWLU9pblt5zWgtUdMStdyn2
wBgJFSnG/Vm8D7FtshDqzrmNbCqfQ0YCxf13kajJE5Iq8pOlOMD2pDhdr6R0o853
ATkUtwDCYq2OMQHo5tpMD0ynAyVfv/yUcGcpGsBddLurAsK+5e5cBL+sFoJ5ufTe
7Vp2ayFkC57LAmH06lSc2ILD++uunHpO6y7Z4oUgdRw8h+7v2dGkJx0ujw6bdKUp
IgtHbHKggQ37JX4gGNwluzsaXZ6VUAvJ3nVHN3+ojkonGCx1DizoSZZgeETMRHyZ
o4k3/juvmlMvTIRC72wh425ZTNMP0gX7w4dzJZDQYTGAM8MbQ5VoJz6jl5sb
-----END RSA PRIVATE KEY-----`

// //encriptar en react hacia el middleware // Encriptar React -> Middleware
const PublicKeyWeb = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA8Df40sRBUL+PbE+GJbJG
1d1zNPbnkgyg2f3ax1TSxsrKZD6l6IxO7bkdLrGB9e5wYRa8oqIUXEKo7qe9N+dt
zaOg0PAmEyDVcLk/iXjlaj73uyjnIQEbh/TcoEk8UnZPmOytg0QnZwWcV+tQG6hV
Q2VdEHOSQDLyINs9i5NXvncfx6UPi8SoK7DrLtNeKBPhGfCpc5fLZBuzPvhmUx4J
KItdSieUhKhdfkOz3ZkTAfCU64/XkeTYxhWi25L06i+cr5CUo4OJ1Ai/fOxTA5O/
cR+/akEty697Tw+cGXhnHEzV41fW6v/9NMd5l4KEbjaBFjBaEFuuMOw99ViTX0s/
meF64WgEjKxSruGlrwM+3fdKTkPkoVx7YauHH5zGNoozJXit4i4+YkYIvNNY8qbE
fH4D3pRyh4n7QyisYaHJapcHr8JwtZxpjYxm6mNY1d4qCx9aGFJGUcwYRvi8JSW/
aiHSrjSBTZ3UKf+rzmHYw8uAnunKJqQKYUvxQSeYd1tXkZyGOwM6PKxpPUtd+vq+
I0jVG3HRo1aSBjkrp86ej+Y6/GI7RHR1jfekxMDduJb7Rsol5VdqVHBalIS4Wg6T
5n2UKEe60nBj4zXr/6Arc/fI8b7r3dD2STuKdwyYwGKZbzxk/E5BznL79TOLvGC5
qnadWKZdWmI+rtvVireF6GkCAwEAAQ==
-----END PUBLIC KEY-----`