import queryString from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { fromB64 } from 'utils/functions';


const useQueryParams = () => {
  const location = useLocation();

  const params = useMemo(() => {
    const qParams = queryString.parse(location.search);

    if (qParams?.query) {
      const query = fromB64(qParams?.query);
      return {
        ...qParams,
        ...query,
      };
    }
    return qParams;
  }, [location.search]);

  return {
    ...params,
  };
};

export default useQueryParams;
