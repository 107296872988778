import { useGeneradorPagina } from "../../../../pages/GeneradorPaginas/store";
import { NumberInput, SimpleErrorMessage } from "components/Inputs/inputs";
import moment from "moment";
import React, { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { SimpleContainer } from "components/Inputs/containers";
import Campos from "../Campos";
import { InputNumber } from "primereact/inputnumber";
import { Col, Row } from "react-bootstrap";

const ID_ATTR = "UNIQUE_ID_FOR_INTERNAL_OPERATIONS";

/**
 *
 * @param {{campo:import("components/DynamicForm/typedefs").Campo}} props
 * @returns
 */
const TablaInputs = ({ campo }) => {

  console.log('campo', campo)

  const [showModal, setShowModal] = useState(false);
  const { pageState } = useGeneradorPagina();
  const [options] = useState(
    [
      {
        id: 'audits',
        title: 'Audits',
        subCampo: 'audits'
      },
      {
        id: 'reviews',
        title: 'Reviews',
        subCampo: null
      },
      {
        id: 'mergetsAndAcquisitions',
        title: 'Mergers & Acquisitions',
        subCampo: null
      },
      {
        id: 'bookkeepingWriteUps',
        title: 'Bookkeeping/ write-ups',
        subCampo: null
      }, {
        id: 'fiduciaryServices',
        title: 'Fiduciary services',
        subCampo: 'fiduciaryServices',

      },
      {
        id: 'managementServices',
        title: 'Management services (please attach description)',
        subCampo: null
      },
      {
        id: 'businessInvestmentAdvice',
        title: 'Business investment advice',
        subCampo: null
      },
      {
        id: 'taxServices',
        title: 'Tax services',
        subCampo: 'taxServices'
      },
      {
        id: 'securitiesActivities',
        title: 'Securities activities',
        subCampo: null
      },
      {
        id: 'other',
        title: 'Other (Please attach description )',
        subCampo: null
      },
    ]
  )
  const toggle = () => {
    methods.reset({
      [ID_ATTR]: null,
      name: pageState?.['policyFormData']?.value?.usuNombre || 'No Name'
    });
    setShowModal(!showModal);
  };

  const methods = useForm({ mode: "onChange" });

  const _onSubmit = (field) => (formData) => {
    Object.entries(formData).forEach(([key, value]) => {
      if (typeof value === 'object' && value instanceof Date) {
        formData[key] = moment(value).format('MM/DD/YYYY');
      }
      return true;
    })
    if (formData[ID_ATTR]) {
      field.value = field.value.filter(
        (item) => item[ID_ATTR] !== formData[ID_ATTR]
      );
      formData[ID_ATTR] = uuidv4();
      field.value.push(formData);
    } else {
      formData[ID_ATTR] = uuidv4();
      field.value.push(formData);
    }
    field.onChange(field.value);
    toggle();
  };

  const htmlMessage = (mssg) => {
    return (
      <div
        style={{
          // fontSize: "1rem",
          fontFamily: "Futura-Medium"
        }}
        dangerouslySetInnerHTML={{
          __html: mssg,
        }}
      />
    );
  };
  return (
    <Controller
      name={campo.name}
      defaultValue={[]}
      rules={campo.rules}
      render={({ field: { value, onChange, ref } }) => (
        <div className="col-12 my-5">
          <div className="d-flex mb-3">
            <div className="col-md-8 col-sm-12">
              {htmlMessage(campo.label)}

            </div>
          </div>
          <div className="w-100">
            <SimpleErrorMessage name={campo.name} />
          </div>

          <table className="table table-bordered" >
            <tbody>
              {options.map(option => {
                return (
                  <tr key={option.id}>
                    <td>
                      <p>{option.title}</p>
                      {
                        option.subCampo &&
                        <Campos campos={campo.subCampos.filter(sv => sv.name == option.subCampo)} />
                      }

                    </td>
                    <td>


                      <Row>
                        <Col sm={10}>
                          <SimpleContainer >


                            <InputNumber
                              className='input__mask__mex'
                              value={value[option.id]}
                              onChange={(e) => {
                                const valueAux = { ...value }
                                valueAux[option.id] = e.value


                                onChange(valueAux);
                              }}
                              name={option.id}
                              style={{
                                border: "none",
                                outline: "none",
                                fontFamily: "SourceSansPro-Regular",
                              }}
                              // mode="decimal"

                              inputId={option.id}

                            />
                          </SimpleContainer>

                        </Col>
                        <Col sm={2}>
                          <br></br>
                          <p>%</p>
                        </Col>
                      </Row>


                    </td>
                  </tr>
                )
              })}


            </tbody>


          </table>



        </div>
      )}
    />
  );
};

export default TablaInputs;
