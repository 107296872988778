import { useGeneradorPagina } from "../../../../pages/GeneradorPaginas/store";
import { SimpleErrorMessage } from "components/Inputs/inputs";
import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import Campos from "../Campos";
import { htmlMessage } from "components/DynamicForm/utils/funcionesGenerales";

const ID_ATTR = "UNIQUE_ID_FOR_INTERNAL_OPERATIONS";

/**
 *
 * @param {{campo:import("components/DynamicForm/typedefs").Campo}} props
 * @returns
 */
const Tabla = ({ campo }) => {
  const [showModal, setShowModal] = useState(false);
  const { pageState } = useGeneradorPagina();
  const toggle = () => {
    methods.reset({
      [ID_ATTR]: null,
      name: pageState?.['tipoProducto'] !== 'fastTrack' && (pageState?.['policyFormData']?.value?.usuNombre || 'No Name')
    });
    if (campo.name == 'hPropertyManager') {
      methods.setValue('ownershipHProperty', {
        label: 'No',
        value: 'no'
      })

    }
    if (campo.name == 'eTravelAgents') {
      if (pageState?.['tipoProductoTravel'] == 'individual') {
        methods.setValue('nameListTravelAgents', pageState?.['policyFormData']?.value?.usuNombre ? pageState?.['policyFormData']?.value?.usuNombre : null)
      }
      methods.setValue('tipoProductoTravel',pageState?.['tipoProductoTravel'])
    }
    setShowModal(!showModal);
  };

  const methods = useForm({ mode: "onChange" });

  const _onSubmit = (field) => (formData) => {
    Object.entries(formData).forEach(([key, value]) => {
      if (typeof value === 'object' && value instanceof Date) {
        formData[key] = moment(value).format('MM/DD/YYYY');
      }
      return true;
    })
    if (formData[ID_ATTR]) {
      field.value = field.value.filter(
        (item) => item[ID_ATTR] !== formData[ID_ATTR]
      );
      formData[ID_ATTR] = uuidv4();
      field.value.push(formData);
    } else {
      formData[ID_ATTR] = uuidv4();
      field.value.push(formData);
    }
    field.onChange(field.value);
    toggle();
  };
  return (
    <Controller
      name={campo.name}
      defaultValue={[]}
      rules={campo.rules}
      render={({ field }) => (
        <div className="col-12 my-5">
          <div className="d-flex mb-3">
            <small>{htmlMessage(campo.info)}</small>

          </div>
          <div className="d-flex mb-3">
            <Button
              icon="pi pi-plus"
              // disabled={!campo.editable}
              disabled={campo.disabled}
              className="p-button-sm bg-darkblue"
              style={{
                height: "1.5rem",
                width: "1.5rem",
                borderRadius: "50%",
                border: "none",
                outline: "none",
              }}
              onClick={toggle}
              type="button"
            />
            <label className="ms-2 text-uppercase align-self-center text-darkblue" disabled={campo.disabled}>
              {campo?.label}
            </label>
          </div>
          <div className="w-100">
            <SimpleErrorMessage name={campo.name} />
          </div>
          <DataTable

            showGridlines
            autoLayout
            disabled={campo.disabled}
            className="p-datatable-gridlines"
            value={[...field.value]}
          >
            {campo?.subCampos?.map((subCampo) => {
              if (subCampo.type === "checkbox-options") {
                return (
                  <Column
                    header={subCampo.label}
                    field={subCampo.name}
                    key={subCampo.name}
                    headerClassName="dynamic_form_table_header"
                    // key={subCampo.id}
                    body={(rowData) => {
                      return Object.values(rowData[subCampo.name])
                        .filter((item) => item.checked)
                        .map((item) => (
                          <p className="p-0 m-0" key={item.label}>
                            {item.label}
                          </p>
                        ));
                    }}
                  />
                );
              }
              if (subCampo.type === "select") {
                return (
                  <Column
                    header={subCampo.label}
                    field={subCampo.name}
                    key={subCampo.name}
                    headerClassName="dynamic_form_table_header"
                    // key={subCampo.id}
                    body={(rowData) => {
                      return <p>{rowData[subCampo.name].label}</p>
                    }}
                  />
                );
              }
              return (
                <Column
                  headerClassName="dynamic_form_table_header"
                  header={subCampo.label}
                  field={subCampo.name}
                  key={subCampo.id}
                />
              );
            })}
            <Column
              header="Options"
              headerClassName="dynamic_form_table_header"
              body={(rowData) => (
                <div className="d-flex flex-row flex-wrap justify-content-around">
                  <Button
                    type="button"
                    onClick={() => {
                      toggle();
                      methods.reset(rowData);
                    }}
                    icon="pi pi-pencil"
                    className="p-button-sm p-button-text"
                  />
                  <Button
                    type="button"
                    onClick={() => {
                      const newValue = field.value.filter(
                        (item) => item[ID_ATTR] !== rowData[ID_ATTR]
                      );
                      field.onChange(newValue);
                    }}
                    icon="pi pi-trash"
                    className="p-button-sm p-button-danger p-button-text"
                  />
                </div>
              )}
            />
          </DataTable>

          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body className="m-5">
              <h3 className="text-center my-2">{campo?.label}</h3>
              <FormProvider {...methods}>
                <form>
                  <Campos campos={campo.subCampos} />
                </form>
              </FormProvider>
              <button
                className="btn btn-aigpr text-white mt-3"
                onClick={methods.handleSubmit(_onSubmit(field))}
              >
                SAVE AND CONTINUE
              </button>
            </Modal.Body>
          </Modal>
        </div>
      )}
    />
  );
};

export default Tabla;
