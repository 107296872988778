/* eslint-disable jsx-a11y/anchor-is-valid */
import useHistory from "hooks/useHistory";
import React, { useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { login, logout } from "redux/actions/loginActions";
import { urlsPages } from "../../router/urlsPages";
import { UserCard } from "../Card";
import "./popover.scss";

const MiPerfilPopover = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { dataUsuario } = useSelector((state) => state.login);
  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const togglePopover = () => {
    setIsOpenPopover(!isOpenPopover);
  };

  const { cliCodigoCore } = dataUsuario;

  const onClickSalir = (event) => {
    event.preventDefault();
    event.stopPropagation();
    togglePopover();
    dispatch(logout());
    history.push(urlsPages.login);
  };

  const redirectToChange = () => {
    togglePopover();
    dispatch(login({ ...dataUsuario, cambiarContrasenia: true }));
    history.push(
      `${urlsPages.actualizarContrasenia}?validado=1&id=${cliCodigoCore}&renew=true`
    );
  };

  const popover = (
    <Popover>
      <UserCard
        title={`${dataUsuario.nombres} `}
        subtitle={dataUsuario.correo}
        style={{
          backgroundImage: "linear-gradient(to bottom, #0035aa, #001871)",
        }}>
        <ListGroup>
          <Link
            to={urlsPages.listQuotes}
            className='text-decoration-none'
            onClick={togglePopover}>
            <ListGroup.Item
              tag='button'
              action
              className='set-cursor lista-menu-user-izquierda'>
              {t("I_FRONT_16")}
            </ListGroup.Item>
          </Link>

          <Link
            to={urlsPages.listPolicies}
            className='text-decoration-none'
            onClick={togglePopover}>
            <ListGroup.Item
              tag='button'
              action
              className='set-cursor lista-menu-user-izquierda'>
              {t("I_FRONT_17")}
            </ListGroup.Item>
          </Link>
          <ListGroup.Item
            tag='button'
            onClick={redirectToChange}
            action
            className='set-cursor lista-menu-user-izquierda'>
            {t("I_FRONT_10")}
          </ListGroup.Item>
          <ListGroup.Item
            tag='button'
            onClick={onClickSalir}
            action
            className='set-cursor text-center lista-menu-user-izquierda'>
            {t("I_FRONT_18")}
          </ListGroup.Item>
        </ListGroup>
      </UserCard>
    </Popover>
  );

  return (
    <React.Fragment>
      <div className='ml-auto my-auto mr-2 item-popover'>
        <OverlayTrigger trigger='click' placement='bottom' overlay={popover}>
          <a id='Popover1' className='cpointer text-white'>
            {dataUsuario?.nombres} {dataUsuario?.apellidos}
          </a>
        </OverlayTrigger>
      </div>
    </React.Fragment>
  );
};

export default MiPerfilPopover;
