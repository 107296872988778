import { types } from "redux/types/types"


export const loadingProducts = () => {
    return {
        type: types.LOADING_PRODS
    }
}

export const stopLoadingProducts = () => {
    return {
        type: types.STOP_LOADING_PRODS
    }
}

export const loadProducts = (productos) => {
    return {
        type: types.LOAD_PRODUCTS,
        payload: productos
    }
}

export const selectProduct = (product) => {
    return {
        type: types.SET_SELECTED_OPTION,
        payload: product
    }
}

export const selectTerrorism = (terrorism) => {
    return {
        type: types.SET_SELECTED_TERRORISM,
        payload: terrorism
    }
}