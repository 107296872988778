import { LanguageSelector } from "components/LanguageSelector/LanguageSelector";
import React from "react";
import { useSelector } from "react-redux";
import MiPerfilPopover from "./MiPerfilPopover";
import "./navbar.scss";

export const Navbar = () => {
  const {
    dataUsuario: { userIsLoggedIn },
  } = useSelector((state) => state.login);

  return (
    <nav className={`d-flex flex-row nav__container justify-content-end`}>
      {/*  <div className="nav__font__source__sans__pro navbar-link text-white">
                <h4 onClick={() => { changeLanguage('ES'); }}>ES</h4>
            </div>

            <div className="nav__font__source__sans__pro navbar-link text-white">
                <h4 onClick={() => { changeLanguage('EN'); }}>EN</h4>
            </div> */}
      <div className='nav__font__source__sans__pro navbar-link w-100px'>
        <LanguageSelector />
      </div>
      {userIsLoggedIn && (
        <div className='nav__font__source__sans__pro navbar-link '>
          <MiPerfilPopover />
        </div>
      )}
    </nav>
  );
};
