import { InputContainer } from "components/Inputs/containers";
import { TextInput } from "components/Inputs/inputs";
import { emailRegex } from "components/Inputs/validaciones";
import useQueryParams from "hooks/useQueryParams";
import React, { useCallback, useEffect, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { loginAES } from "redux/actions/loginActions";
import { cleanErrors } from "redux/actions/uiActions";
import { urlAutenticacionOkta } from "services/urls";
import { encriptarContrasenia } from "utils/Encriptar";
import imgLogo from "../../assets/img/logo-page.png";
import { Loading } from "../Loading/Loading";

import "./login.scss";

const Login = () => {
  const methods = useForm({ mode: "onChange" });
  const { user, submited } = useQueryParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { loginError, loading } = useSelector((state) => state.ui);

  const [error, setError] = useState(false);
  const { addToast } = useToasts();

  const history = useNavigate();
  const { t } = useTranslation();

  const onSubmit = useCallback(async ({ email, password }) => {
    const dataLogin = {};
    dataLogin["cliUsuario"] = email;
    dataLogin["cliPassword"] = encriptarContrasenia(password);
    dataLogin["carHash"] = "";

    const msg = await dispatch(loginAES({ ...dataLogin }, t));
    if (msg?.description) {
      addToast(msg?.description, {
        appearance: "warning",
      });
    }
    methods.reset({ email, password });
    if (msg?.expires) {
      history(`/actualizar-contrasenia?validado=1&id=${msg.cliCodigoCore}&renew=true`);
    }
  }, [addToast, dispatch, history, methods, t]);



  useEffect(() => {
    if (user && !submited) {

      history(`${location.pathname}&submited=true`);
      onSubmit({
        email: Buffer.from(user, "base64").toString(),
        password: "THIS_IS_A_USER_FROM_OKTA",
      });
    }
  }, [history, onSubmit, user, submited, location]);

  const onSubmitError = (values) => {
    setError(true);
  };

  const onDismiss = () => {
    dispatch(cleanErrors());
    setError(false);
  };

  const redireccionarOkta = async () => {
    try {
      window.location.assign(urlAutenticacionOkta);
    } catch (err) {
      addToast(
        'Ha ocurrido un problema al redireccionar al ambiente de OKTA',
        {
          appearance: "warning",
        });
    }
  };

  return (
    <Loading loading={loading}>
      <div className='login-page container-fluid d-flex'>
        <Container className='d-flex flex-column  align-items-center mt-3'>
          <Col
            md={9}
            lg={7}
            xl={6}
            className='d-flex justify-content-center align-self-center p-5'>
            <img src={imgLogo} alt='' style={{ width: "300px" }} />
          </Col>
          <Col md={9} lg={7} xl={6}>
            <div className='card-login container-fluid mb-5'>
              <div className='container mt-5'>
                <center>
                  <h5>{t("I_FRONT_5")}</h5>
                </center>
              </div>
              <div className="col-12 text-center p-2">
                <h5>Are you an OKTA user?</h5>
                <div className="mx-4">
                  <button
                    className="btn btn-block"
                    style={{
                      backgroundColor: 'white',
                      border: '1px solid #2B7DE9',
                    }}
                    type="button"
                    onClick={redireccionarOkta}
                  >
                    Login with OKTA
                  </button>
                </div>
              </div>
              <div className='card-login-frm'>
                <h5 className='mb-3 ml-0'>{t("I_FRONT_1")}</h5>
                <FormProvider {...methods}>
                  <form
                    noValidate
                    autoComplete='off'
                    onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}>
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <InputContainer label={t("I_FRONT_2")} name='email'>
                          <TextInput
                            extrasClasses={"login-input input-login-form"}
                            inputProps={{
                              type: "email",
                              name: "email",
                              placeholder: "juan.ma@aig.com",
                              autoComplete: "off",
                            }}
                            rules={{
                              required: t("W_FRONT_62"),
                              pattern: {
                                value: emailRegex,
                                message: t("W_FRONT_13"),
                              },
                            }}
                          />
                        </InputContainer>
                      </Col>

                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className='text-left mt-3 mb-3'>
                        <InputContainer label={t("I_FRONT_3")} name='password'>
                          <TextInput
                            extrasClasses={"login-input input-login-form"}
                            inputProps={{
                              type: "password",
                              name: "password",
                              placeholder: "**************",
                            }}
                            rules={{
                              required: t("W_FRONT_61"),
                            }}
                          />
                        </InputContainer>
                        <div className='text-right mr-5 mt-3'>
                          <Link to='/forgot-password' style={{ fontSize: "14px" }}>
                            {t("I_FRONT_4")}
                          </Link>
                        </div>
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        {(error || loginError) && (
                          <Alert
                            variant='danger'
                            dismissible
                            closeLabel=''
                            onClose={onDismiss}>
                            <p>{t("E_FRONT_6")}</p>
                          </Alert>
                        )}
                      </Col>
                      <Col>
                        <div className='form-contenedor-type1'>
                          <button
                            id='btn-login-ingresar'
                            className='btn btn-primary login-btn'
                            type='submit'>
                            {t("I_FRONT_1")}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </FormProvider>
              </div>

            </div>
          </Col>
        </Container>
      </div>
    </Loading>
  );
};
export default Login;
