import { format } from 'helpers/functions'
import { CabeceraPlanes, stripColors } from 'pages/GeneradorPaginas/components/ProductOptions/ProductOptions'
import React, { useEffect } from 'react'

export const PlansCasualty = ({
    panelesVisibles,
    visiblePlans,
    classNames,
    options,
    showBack,
    showMoreLess,
    showForward,
    nameValues,
    onChange

}) => {
    

    useEffect(() => {
      

        var nuevoArreglo = {}

        options.forEach(option => {
            nuevoArreglo[option.planCodigo] = {...option}
            
        });
        console.log('options', nuevoArreglo)
        onChange(nuevoArreglo)
    }, [])


    return (
        <div className={`container-planes-${panelesVisibles} position-relative`}>
            <div className="d-flex flex-column justify-content-end font-source-sans-regular text-aigpr font-19px col text-left mb-3">
                Premium
            </div>
            {visiblePlans?.map((opcion, index) => (
                <React.Fragment key={`product-plan-${index}`}>{(opcion.planNombre !='Option 0') ?
                    <div
                        className={classNames('plan-pago-container')}

                    >

                        <CabeceraPlanes
                            opcion={opcion}
                            stripColor={stripColors[options.indexOf(options.find(opt => opt.planNombre == opcion.planNombre))]}
                        />

                    </div> : <div ></div>}

                    {
                        (index === 0 && options?.length > panelesVisibles && showBack) && <div
                            key={`back${index}`}
                            className={`${(options?.length === 0) && 'd-none'} d-flex align-content-center pagination-button-back `}
                            onClick={() => { showMoreLess(false) }}><span className="arrow mt-3">❬</span>
                        </div>
                    }
                    {
                        (index === visiblePlans?.length - 1 && options?.length > panelesVisibles && showForward) && <div
                            key={`forward${index}`}
                            className={`${(options?.length === 0) && 'd-none'} d-flex align-content-center pagination-button-forward `}
                            onClick={() => { showMoreLess(true) }}><span className="arrow mt-3">❭</span>
                        </div>
                    }

                </React.Fragment>
            ))}

            {nameValues.map(name => {
                return (
                    <React.Fragment>
                        <div className="font-source-sans-regular font-19px col text-left py-3">
                            {name}
                        </div>
                        {visiblePlans.map((opcion, j) => (
                            (opcion.planNombre !='Option 0') ? <div
                                key={`opci2${j}`}
                                className={`d-flex justify-content-center px-3 py-3 card-border-lateral`}
                                style={{
                                    borderTop: '0px !important',
                                    borderBottom: '0px !important',
                                }}
                            >
                                <div className="font-source-sans-regular font-19px text-lg-center text-left">{`${ typeof opcion[name] =='number'? format( opcion[name]) :opcion[name]}`}</div>
                            </div> : <div key={`divSpace${j}`}></div>
                        ))}

                    </React.Fragment>
                )
            })}

        </div>
    )
}