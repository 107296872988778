import withValidarCambioContrasenia from "HOC/withValidarCambioContrasenia";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { scrollTop } from "utils/Funciones";

const TokenNoValido = () => {
  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-sm-10 col-md-9'>
            <div className='card-login-frm'>
              <div className='login-card-title text-center'>
                <h1 className='font-aig'>Invalid token</h1>
              </div>
              <div className='card-login-frm'>
                <div className='row justify-content-around text-center'>
                  <div className=' col-md-5 col-lg-4 my-1'>
                    <Link className='btn login-btn text-white' to='/'>
                      Login
                    </Link>
                  </div>

                  <div className='col-md-5 col-lg-4 my-1'>
                    <Link className='btn login-btn text-white' to='/forgot-password'>
                      Try Again
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withValidarCambioContrasenia(TokenNoValido);
