import { SimpleErrorMessage } from "components/Inputs/inputs";
import useCustomToast from "hooks/useCustomToast";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { urlUploadFile } from "services/urls";
import { v4 as uuidv4 } from "uuid";

/**
 *
 * @param {{campo:import("components/DynamicForm/typedefs").Campo}} props
 * @returns
 */
const Archivos = ({ campo, aprobRetroDate, retroDate, campo1, campo2, campo3 }) => {
  const ref = useRef(null);

  const [uploading, setUploading] = useState(false);
  const { addWarningToast } = useCustomToast();
  const { setValue } = useFormContext();
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const uploadFile = () => {
    ref.current.click();
  };

  const onChangeFiles = (field) => async (e) => {
    if (e.target.files.length !== 0) {
      try {
        const formData = new FormData();

        const file = e.target.files[0];
        if (file.size / 1000 > campo?.maxFileSize) {
          addWarningToast(
            `The maximum size allowed is: ${campo.maxFileSize}KB`

          );
          return;
        }

        const splits = file.name?.split(".");

        const lastSplit = splits.length;

        const type = splits[lastSplit - 1];

        const filename = file.name
          .toLowerCase()
          .replace(`.${type}`, `${uuidv4()}.${type}`);

        formData.append("file", e.target.files[0]);
        formData.append("filename", filename);

        setUploading(true);
        setError(false)
        await fetch(urlUploadFile, {
          method: "POST",
          body: formData,
        })
          .then((res) => res.json())
          .then((res) => {
            e.target.value = null;
            setUploading(false);
            console.log('UPLOAD', res);
            if (res?.transaccion) {
              field.value.push(res?.url);
              setValue(campo.name, field.value, { shouldValidate: true });

            } else {
              setError(true)
              setErrorMessage(res.errors?.file[0])
            }
          }).catch(err => console.log);
        setUploading(false)
      } catch (error) {
        console.log(error);
        setUploading(false);
      }
    }
  };

  const htmlMessage = (mssg) => {
    return (
      <div
        style={{}}
        dangerouslySetInnerHTML={{
          __html: mssg,
        }}
      />
    );
  };

  const value = useWatch({ name: campo.name });

  return (
    <React.Fragment>
      <Controller
        name={campo.name}
        rules={campo.rules}
        defaultValue={[]}
        render={({ field }) => {
          return (
            <React.Fragment>
              <div className="d-flex justify-content-between align-items-center flex-row w-100 mt-3">
                <div className="d-flex flex-column">
                  <label className="align-self-center">
                    {htmlMessage(campo.label)}
                  </label>
                  <SimpleErrorMessage name={campo.name} />

                </div>
                <button
                  className="btn btn-aigpr-outlined text-darkblue align-self-center"
                  type="button"
                  disabled={uploading || (aprobRetroDate && (campo.name ==campo2 || campo.name ==campo3))}
                  onClick={uploadFile}
                  style={{ width: "150px", whiteSpace: "nowrap" }}
                >
                  {!uploading && "UPLOAD FILE"}
                  {uploading && "UPLOADING..."}
                </button>
                <input
                  type="file"
                  ref={ref}
                  hidden
                  multiple={true}
                  onChange={onChangeFiles(field)}
                  disabled={uploading || (aprobRetroDate && (campo.name ==campo2 || campo.name ==campo3)) }
                />
               
              </div>
            </React.Fragment>
          );
        }}
      />
      <div
        className="d-flex flex-column justify-content-end w-100 mt-3"
        style={{ textAlign: "right" }}
      >
        {value &&
          value.map((file, index) => (
            <div className="my-1" key={index}>
              <a
                className="mx-2 btn btn-aigpr-outlined text-truncate"
                rel="noreferrer"
                href={file}
                style={{ maxWidth: "100px" }}
                disabled={uploading || (aprobRetroDate && (campo.name ==campo2 || campo.name ==campo3))}
                target="_blank"
              >
                {file?.replace?.("https://aigpr-pdf.s3.amazonaws.com/", "")}
              </a>
              <button
                className="btn"
                type="button"
                onClick={() => {
                  const newFiles = value.filter((item) => item !== file);
                  setValue(campo.name, newFiles, { shouldValidate: true });
                }}
                disabled={uploading || (aprobRetroDate && (campo.name ==campo2 || campo.name ==campo3))}
              >
                <i className="pi pi-times" />
              </button>
            </div>
          ))}
      </div>
      {error &&
                  <small style={{color:'red'}}>{errorMessage}</small>
                }
    </React.Fragment>
  );
};

export default Archivos;
