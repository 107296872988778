// const urlDefault = "http://localhost/middlewareseguros/"; //middleware local
// const urlDefault = "http://localhost/aig-pr-midd/"; //middleware local
//const urlDefault = "http://middleware-des.us-east-1.elasticbeanstalk.com/"; //middleware pruebas internas sin https
// const urlDefault = "http://middleware-des.us-east-1.elasticbeanstalk.com/"; //UAT
 const urlDefault = "https://middleware74-uat.aiggopr.com/"; //UAT
 //const urlDefault = "https://middleware74-desarrollo.aiggopr.com/"; //UAT
// const urlDefault = "http://middleware-des.us-east-1.elasticbeanstalk.com/"; //UAT
// const urlDefault = "https://middleware74-produccion.aiggopr.com/"; //PRODUCCION

//MIDDLEWARE MEX
//const urlDefault ='https://middleware-aigmex-desarrollo-uat.nuvem.cloud/';

/**
 * URLS BASE MIDDLEWARE DE SERVICIOS
 */

/* const urlDefaultMiddServicios =
  "http://middlewaregeneralenv-env.eba-7u4yy78s.us-west-2.elasticbeanstalk.com/"; */
const urlDefaultMiddServicios =
  "https://servicios.nuvem.cloud/";

const urlBase = process.env.REACT_APP_URL_BASE || urlDefault;
const urlBaseMiddServicios = process.env.REACT_APP_URL_BASE_MIDD_SERVICIOS||urlDefaultMiddServicios;
export const controlador = "frontend/web/index.php?r=";

const ambienteDefault = "produccion";

export let ambienteAplicacion =
  process.env.REACT_APP_AMBIENTE || ambienteDefault; //Utilizado solo para produccion

export const empresa = "AIGPR";
export const plataforma = "WEB";

//OKTA
export const urlAutenticacionOkta = `${urlDefaultMiddServicios}api/okta/${empresa}/FRONT/ingreso`;


export const ambientes = {
  DEV: "desarrollo",
  PROD: "produccion",
};

export const isDev = () => ambienteAplicacion === ambientes.DEV;
export const isProd = () => ambienteAplicacion === ambientes.PROD;
//URLS
export const urlLogin = `${urlBase}${controlador}clientes/iniciar-sesion-aes`;
export const urlForgotPassword = `${urlBase}${controlador}clientes/recuperar-contrasenia`;
export const urlCatalogoHome = `${urlBase}${controlador}site/home`;
export const urlChangePassword = `${urlBase}${controlador}usuarios/validaciones-cambiarcontrasenia-mexico`;
export const urlCambiarContraseniaProceso = `${urlBase}${controlador}clientes/cambiar-contrasenia-proceso`;

//GeneradorPagina
export const urlGetPagina = `${urlBase}${controlador}multiramo/formulario/make`;
export const urlCatalogoPagina = `${urlBase}${controlador}multiramo/formulario/one`;
export const urlLayoutPagina = `${urlBase}${controlador}multiramo/layout/show`;

//Listado Cotizaciones
export const urlListCotizaciones = `${urlBase}${controlador}aigpr/cart/all-quotes-by-user`;

//Generar pdf
export const urlGenerarPDF = `${urlBase}${controlador}multiramo/cotizacion/catalogo`;
export const urlCatalogo = `${urlBase}${controlador}multiramo/cotizacion/catalogo`;

//Recuperar datosCotizacion
export const urlResumenCarrito = `${urlBase}${controlador}multiramo/cotizacion/resumen`;

//Catalogos
export const urlPlanes = `${urlBase}${controlador}multiramo/cotizacion/get-presuscritos-planes`;
export const urlResumen = `${urlBase}${controlador}multiramo/cotizacion/get-summary`;

//ACTUALIZAR CARRITO
export const urlActualizarCarrito = `${urlBase}${controlador}multiramo/cotizacion/actualizar`;

//Ubicaciones
export const urlGetCatalogoUbicacion = `${urlBase}${controlador}aigpr/location/get-collections-by-selected-type`;
export const urlGetCountries = `${urlBase}${controlador}aigpr/location/get-all-countries`;
export const urlGetLocationByZipCode = `${urlBase}${controlador}aigpr/location/get-total-location`;

//IDIOMA
export const urlLanguages = `${urlBase}${controlador}site/lista-idiomas-empresa`;
export const urlDiccionario = `${urlBase}${controlador}site/diccionario-labels`;
export const urlLanguagesFlags = `${urlBase}${controlador}site/lista-idiomas-empresa-icon`;

//CORREO
export const urlSendEmail = `${urlBase}${controlador}aigpr/cart/cotizacion-send-email`;
//PRODUCTOS
export const urlGetProducts = `${urlBase}${controlador}site/productos`;
export const urlUploadFile = `${urlBase}${controlador}app/upload-file&empresa=${empresa}`;
/**
 * POLIZA
 */
//CATALOGOS POLIZA
export const urlCatalogosPoliza = `${urlBase}${controlador}multiramo/poliza/catalogo`;
export const urlCambiarEstadoPoliza=`${urlBase}${controlador}multiramo/poliza/change-void`;
export const urlCambiarEstadoCotizacion=`${urlBase}${controlador}multiramo/cotizacion/change-void`
//ACTUALIZAR POLIZA
export const urlActualizarPoliza = `${urlBase}${controlador}multiramo/poliza/actualizar`;
//LISTAR POLIZAS
export const urlListarPolizas = `${urlBase}${controlador}multiramo/poliza/agent`;

//SFTP
export const urlSFTP = `${urlBase}${controlador}site/policy-sftp&empCode=${empresa}&id=`

//PLACE TO PAY
export const urlPreguntasFrecuentesP2p = `${urlBaseMiddServicios}api/placetopay/faq/AIGPR`;
export const urlSondaP2p = (id) =>
  `${urlBaseMiddServicios}api/placetopay/sonda/${empresa}/${id}/${empresa}`;

export const urlConsultarMisPagosP2p = (id) =>
  `${urlBaseMiddServicios}api/placetopay/pagos/${empresa}/${id}/${empresa}`;
/**
 * UTILS URLS
 */
export const urlGetMyIp = `${urlBase}${controlador}api/site/user-info&empresa=${empresa}`;

const urls = {
  urlLogin,
  urlForgotPassword,
  urlCatalogoHome,
  urlChangePassword,
  urlCambiarContraseniaProceso,
  urlGetPagina,
  urlGetCatalogoUbicacion,
  urlLanguages,
  urlDiccionario,
  urlGetProducts,
  urlGetCountries,
  urlGetLocationByZipCode,
  urlActualizarCarrito,
  urlCatalogoPagina,
  urlLayoutPagina,
  urlPlanes,
  urlResumen,
  urlResumenCarrito,
  urlListCotizaciones,
  urlSendEmail,
  urlGenerarPDF,
  urlCatalogosPoliza,
  urlActualizarPoliza,
  urlCatalogo,
  urlSFTP,
  urlCambiarEstadoPoliza
};

export default urls;
