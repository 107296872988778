import { useGeneradorPagina } from "../../../../pages/GeneradorPaginas/store";
import { DateCalendar, DropDownDate, NumberInput, SimpleCheckBoxHorizontalBasic, SimpleErrorMessage } from "components/Inputs/inputs";
import moment from "moment";
import React, { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { SimpleContainer } from "components/Inputs/containers";
import Campos from "../Campos";
import { InputNumber } from "primereact/inputnumber";
import { values } from "lodash";
import { InputText } from "primereact/inputtext";
import Select from "react-select";

const ID_ATTR = "UNIQUE_ID_FOR_INTERNAL_OPERATIONS";

/**
 *
 * @param {{campo:import("components/DynamicForm/typedefs").Campo}} props
 * @returns
 */
const TablaOptionsUmbrella = ({ campo }) => {





  const methods = useForm({ mode: "onChange" });




  const htmlMessage = (mssg) => {
    return (
      <div
        style={{
          // fontSize: "1rem",
          fontFamily: "Futura-Medium"
        }}
        dangerouslySetInnerHTML={{
          __html: mssg,
        }}
      />
    );
  };
  return (
    <Controller
      name={campo.name}
      defaultValue={[]}
      rules={campo.rules}
      render={({ field: { value, onChange, ref } }) => {

        console.log('newValue', value)



        const isDisabledRow = (index) => {

          return !(value[`radio-${index}`]?.checked)








        }

        return (
          <div className="col-12 my-5" style={{ fontSize: 12 }}>
            <div className="d-flex mb-3">
              <div className="col-md-8 col-sm-12">
                {htmlMessage(campo.label)}

              </div>
            </div>

            <table className="table table-bordered table-responsive-lx table-responsive-l table-responsive-md">
              <thead>
                <tr>
                  <th></th>
                  {campo?.options?.filter(o => o.value.tipo == 'columna').map((opcion) => {
                    return (
                      <React.Fragment>
                        <th className="thead-center" key={opcion.label} style={{ fontSize: 13 }}>{opcion.label}</th>
                      </React.Fragment>


                    )

                  })}



                </tr>
              </thead>
              <tbody>
                {campo?.options?.filter(o => o.value.tipo == 'fila').map((opcion, indexX) => {

                  let codigoOpcion = opcion.value.value.split('_').pop()

                  var subCampoEncontrado = campo.subCampos.filter(sv => sv.name == `g1-${codigoOpcion}`)

                  console.log('subCampoEncontrado', subCampoEncontrado)

                  return (
                    <tr key={opcion.label}>



                      {opcion.value.disabled != true &&
                        <th>{opcion.label}</th>
                      }

                      <FormProvider {...methods}>

                        <td>
                          <input
                            id={`radio-${indexX}`}
                            key={`radio-${indexX}`}
                            disabled={false}
                            type="checkbox"
                            name={'radioSelect'}
                            className="align-self-center"
                            checked={value[`radio-${indexX}`]?.checked}
                            value={value[`radio-${indexX}`]?.checked}
                            onChange={(e) => {

                              var valueAux = { ...value }


                              valueAux[`radio-${indexX}`] = {
                                checked: e.target.checked,
                              }

                              console.log('valueAux', valueAux)

                              if (indexX == 2) {
                                valueAux[`radio-0`] = {
                                  checked: false,
                                }
                                valueAux[`radio-1`] = {
                                  checked: false,
                                }
                              } else {
                                valueAux[`radio-2`] = {
                                  checked: false,
                                }
                              }


                              onChange(valueAux);



                            }}
                          />
                        </td>




                        <td className="">
                          <div style={{ width: '165px' }}>
                            {(subCampoEncontrado.length > 0 && subCampoEncontrado[0].type == 'checkbox-options-horizontal-basic') ?

                              <div
                                className={`col${'12' ? "-md-" + '12' : ""
                                  } col-sm-12 mt-2`}
                              >

                                <SimpleCheckBoxHorizontalBasic
                                  infoCoverageDesired={null}
                                  id={subCampoEncontrado[0].name}
                                  name={subCampoEncontrado[0].name}
                                  rules={subCampoEncontrado[0].rules}
                                  disabled={isDisabledRow(indexX)}
                                  label={subCampoEncontrado[0].label}
                                  options={subCampoEncontrado[0].options}
                                  valueMain={value[subCampoEncontrado[0].name]}
                                  onChangeMain={(valuesCheck) => {

                                    const valueAux = { ...value }

                                    valueAux[subCampoEncontrado[0].name] = valuesCheck


                                    console.log('valueAux', valueAux)





                                    onChange(valueAux);
                                  }}
                                />
                              </div>
                              //<Campos campos={campo.subCampos.filter(sv => sv.name == `g1-${codigoOpcion}`)} />
                              :
                              <p>UMBRELLA LIABILITY</p>

                            }
                          </div>

                        </td>








                        {campo?.subCampos?.filter(c => c.name != opcion.value.value).map((subCampo, indexY) => {





                          let codigoOpcion = opcion.value.value.split('_').pop()
                          let codigoSubcampo = subCampo.name.split('_').pop()
                          let sub = []

                          sub.push(subCampo)



                          // fecha actual
                          const hoy = new Date();

                          // fecha hace 100 años
                          const hace100anios = new Date();
                          hace100anios.setFullYear(hoy.getFullYear() - 100);

                          // fecha dentro de 100 años
                          const dentroDe100anios = new Date();
                          dentroDe100anios.setFullYear(hoy.getFullYear() + 100);



                          return (
                            codigoOpcion == codigoSubcampo ?
                              <td key={subCampo.name} className={"thead-center"} >
                                <div style={{ width: 135 }}>




                                  {subCampo.type == 'checkbox' &&

                                    <React.Fragment>


                                      <br></br>

                                      <input
                                        id={subCampo.name}
                                        type="checkbox"
                                        className="align-self-center"
                                        checked={value[subCampo.name] ? value[subCampo.name].checked : false}
                                        disabled={isDisabledRow(indexX)}
                                        value={isDisabledRow(indexX) ? null : value[subCampo.name] ? value[subCampo.name].checked : false}
                                        onChange={(e) => {

                                          const valueAux = { ...value }

                                          valueAux[subCampo.name] = {
                                            ...value[subCampo.name]?.value,
                                            checked: e.target.checked,
                                          }



                                          onChange(valueAux);



                                        }}
                                      />

                                    </React.Fragment>




                                  }

                                  {((subCampo.type == 'texto' || subCampo.type == 'fecha' || subCampo.type == 'numberDecimal' || subCampo.type == 'numberInt') && !isDisabledRow(indexX)) &&
                                    <SimpleContainer {...subCampo.containerProps} disabled={subCampo.name.includes('total_') || isDisabledRow(indexX)}>



                                      {(subCampo.type == 'fecha' && !isDisabledRow(indexX)) &&

                                        <React.Fragment>

                                          <p>From:</p>




                                          <DateCalendar rules={{ required: 'Please select a date' }} name={subCampo.name + '_from'}
                                            fechaFin={dentroDe100anios}
                                            initialDate={null}
                                            placeholder={'Select a date'}
                                            fechaInicio={hace100anios}
                                            dateFormat={'MM/dd/yyyy'}
                                            valueMain={value[subCampo.name + '_from']}
                                            onChangeMain={(valuesCheck) => {

                                              const valueAux = { ...value }

                                              valueAux[subCampo.name + '_from'] = valuesCheck

                                              let setOneYear = moment(valuesCheck).add(1,'year').toDate()
                                              valueAux[subCampo.name + '_to'] = setOneYear                                    

                                              onChange(valueAux);
                                            }}
                                          />

                                          <p>To:</p>




                                          <DateCalendar rules={{ required: 'Please select a date' }} name={subCampo.name + '_to'}
                                            fechaFin={dentroDe100anios}
                                            fechaInicio={hace100anios}
                                            initialDate={null}
                                            placeholder={'Select a date'}
                                            dateFormat={'MM/dd/yyyy'}
                                            valueMain={value[subCampo.name + '_to']}
                                            onChangeMain={(valuesCheck) => {

                                              const valueAux = { ...value }

                                              valueAux[subCampo.name + '_to'] = valuesCheck






                                              onChange(valueAux);
                                            }}
                                          />


                                        </React.Fragment>





                                      }

                                      {subCampo.type == 'texto' &&

                                        <React.Fragment>


                                          <InputText
                                            ref={ref}

                                            value={isDisabledRow(indexX) ? '' : value[subCampo.name]}
                                            disabled={isDisabledRow(indexX)}

                                            onChange={(e) => {
                                              const valueAux = { ...value }


                                              valueAux[subCampo.name] = e.currentTarget.value




                                              onChange(valueAux);
                                            }}
                                            name={subCampo.name}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              fontFamily: "SourceSansPro-Regular",
                                              width: '132px',
                                              fontSize: 12
                                            }}
                                            inputStyle={{

                                              width: '80px',
                                              backgroundColor: subCampo.name.includes('total_') ? '#dddddd' : null
                                            }}
                                            type={"text"}


                                          />

                                        </React.Fragment>


                                      }






                                      {(subCampo.type == 'numberDecimal' || subCampo.type == 'numberInt') &&

                                        <InputNumber
                                          className='input__mask__mex'
                                          value={isDisabledRow(indexX) ? null : value[subCampo.name]}
                                          disabled={(subCampo.name.includes('total_')) || isDisabledRow(indexX)}

                                          onChange={(e) => {

                                            const valueAux = { ...value }

                                            valueAux[subCampo.name] = e.value

                                            if (campo.name == 'staff') {


                                              const code = subCampo.name.split('_').pop()

                                              var suma = 0

                                              for (const key in valueAux) {

                                                const codeSubcampo = key.split('_').pop()
                                                if (codeSubcampo == code && !key.includes('total')) {
                                                  console.log('resttt', key, valueAux[key])
                                                  suma += parseFloat(valueAux[key] != null ? valueAux[key] : 0)
                                                }

                                              }

                                              valueAux[`total_${code}`] = suma

                                            }

                                            onChange(valueAux);
                                          }}
                                          name={subCampo.name}
                                          style={{
                                            // border: "none",
                                            outline: "none",
                                            fontFamily: "SourceSansPro-Regular",
                                            fontSize: 12

                                          }}
                                          inputStyle={{

                                            width: '80px',
                                            backgroundColor: subCampo.name.includes('total_') ? '#dddddd' : null,
                                            fontSize: 12
                                          }}

                                          // mode="decimal"

                                          inputId={subCampo.name}

                                        />
                                      }



                                    </SimpleContainer>
                                  }



                                  {subCampo.type == 'select' &&

                                    <React.Fragment>
                                      <Select
                                        ref={ref}
                                        className={'w-100 p-0 '}
                                        id={subCampo.name}
                                        defaultValue={null}

                                        isClearable={null}
                                        isSearchable={true}
                                        isDisabled={isDisabledRow(indexX)}
                                        isLoading={false}
                                        noOptionsMessage={() => "Select a option"}
                                        loadingMessage={() => {
                                          return "Buscando...";
                                        }}
                                        value={isDisabledRow(indexX) ? null : value[subCampo.name]}
                                        name={subCampo.name}
                                        options={subCampo.options}
                                        onChange={(evt) => {
                                          if (evt) {

                                            const valueAux = { ...value }

                                            valueAux[subCampo.name] = evt.value

                                            console.log('valueAux', valueAux)



                                            onChange(valueAux);

                                          } else {

                                          }
                                        }}
                                        placeholder={'Select a option'}

                                      />
                                    </React.Fragment>
                                  }


                                </div>
                              </td>
                              : null
                          )

                        })}
                      </FormProvider>


                    </tr>

                  )

                })}
              </tbody>
            </table>

            <div className="w-100">
              <SimpleErrorMessage name={campo.name} />
            </div>



          </div>
        )
      }}
    />
  );
};

export default TablaOptionsUmbrella;
