import { setUpI18next } from "features/i18next";
import i18next from "i18next";
import Layout from "layouts/Layout";
import ForgotPassword from "pages/ForgotPassword/ForgotPassword";
import React, { Suspense, useEffect } from "react";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ChangePassword from "../pages/ChangePassword/ChangePassword";
import TokenNoValido from "../pages/ChangePassword/TokenNoValido";
import GeneradorPaginas from "../pages/GeneradorPaginas";
import { BodyCotizador } from "../pages/Home/BodyCotizador";
import { ContainerListCotizaciones } from "../pages/ListadoCotizaciones/ContainerListCotizaciones";
import ListadoPolizas from "../pages/ListadoPolizas";
import Login from "../pages/Login/Login";
import PagoUnico from "../pages/PlaceToPay/PagoUnico";
import { urlsPages } from "./urlsPages";
import textosEN from '../utils/textos.json';
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
export const AppRoutes = () => {
  i18next
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
      detection: {
        order: ["htmlTag", "path", "cookie"],
        caches: ["cookie"],
      },
      initImmediate: false,
      interpolation: {
        escapeValue: false,
      },
      fallbackLng:'en',
      resources: {
        en: {
          translation: textosEN,
        },
        es:{
          translation: textosEN,
        }
      }
    });
  

  useEffect(() => {
    setUpI18next();
  }, []);

  return (
    <I18nextProvider i18n={i18next}>
      <Suspense fallback={<div>loading...</div>}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<BodyCotizador />} />
              <Route path="login" element={<Login />} />

              <Route
                path="actualizar-contrasenia"
                element={<ChangePassword />}
              />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="invalid-token" element={<TokenNoValido />} />

              <Route
                path="generador/:producto/:pagina"
                element={<GeneradorPaginas />}
              />
              <Route
                path={urlsPages.listQuotes}
                element={<ContainerListCotizaciones />}
              />
              <Route
                path={urlsPages.listPolicies}
                element={<ListadoPolizas />}
              />
              <Route path="place-to-pay" element={<PagoUnico />} />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </I18nextProvider>
  );
};
