export const emailRegex = RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export const integerRegex = RegExp(/^[0-9\b]+$/);

export const inputNumericoEnteros = (evt) => {
    if (
        evt.key === 'e' ||
        evt.key === 'E' ||
        evt.key === '.' ||
        evt.key === '+' ||
        evt.key === '-'
    ) {
        evt.preventDefault();
    }
};

export const nombreFormRegex = (message = 'Enter a valid name') => (value) => {
    const isValid = RegExp(/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ \-\w&.]+$/).test(value);
    if (!isValid) {
        return message;
    }
    return true;
};

export const validarContraseniaNueva = (
    validaciones,
    setShowError,
    passUnoName = 'passUno',
    passDosName = 'passDos',
) => (watch) => (value) => {
    const passUno = watch(passUnoName);
    const passDos = watch(passDosName);

    const {
        caracteresUnicode,
        minusculas,
        mayusculas,
        numeros,
        caracteresEspeciales,
        longitudMinima,
    } = validaciones;
    let grupos = 0;

    if (RegExp(`[${caracteresUnicode}]`).test(value)) {
        grupos++;
    }
    if (RegExp(`[${minusculas}]`).test(value)) {
        grupos++;
    }
    if (RegExp(`[${mayusculas}]`).test(value)) {
        grupos++;
    }
    if (RegExp(`[${numeros}]`).test(value)) {
        grupos++;
    }
    for (let i = 0; i < caracteresEspeciales.length; i++) {
        if (value.includes(caracteresEspeciales[i])) {
            grupos++;
            break;
        }
    }

    setShowError(passUno !== passDos);

    if (grupos >= 3 && value.length >= longitudMinima) {
        return true;
    } else {
        return 'Enter a valid password';
    }
};

export const processRules = (rules) => {
    if (rules.pattern) {
        rules.pattern.value = new RegExp(rules.pattern.value);
    }

    return rules;
};


export const required = (message = 'Required') => (value) => {
    if (value && String(value).trim() !== '') {
        return true;
    }
    return message;
};