
import rsa from "./crypto/rsa";
import sha512 from './crypto/sha512'


const publicKey = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA8Df40sRBUL+PbE+GJbJG
1d1zNPbnkgyg2f3ax1TSxsrKZD6l6IxO7bkdLrGB9e5wYRa8oqIUXEKo7qe9N+dt
zaOg0PAmEyDVcLk/iXjlaj73uyjnIQEbh/TcoEk8UnZPmOytg0QnZwWcV+tQG6hV
Q2VdEHOSQDLyINs9i5NXvncfx6UPi8SoK7DrLtNeKBPhGfCpc5fLZBuzPvhmUx4J
KItdSieUhKhdfkOz3ZkTAfCU64/XkeTYxhWi25L06i+cr5CUo4OJ1Ai/fOxTA5O/
cR+/akEty697Tw+cGXhnHEzV41fW6v/9NMd5l4KEbjaBFjBaEFuuMOw99ViTX0s/
meF64WgEjKxSruGlrwM+3fdKTkPkoVx7YauHH5zGNoozJXit4i4+YkYIvNNY8qbE
fH4D3pRyh4n7QyisYaHJapcHr8JwtZxpjYxm6mNY1d4qCx9aGFJGUcwYRvi8JSW/
aiHSrjSBTZ3UKf+rzmHYw8uAnunKJqQKYUvxQSeYd1tXkZyGOwM6PKxpPUtd+vq+
I0jVG3HRo1aSBjkrp86ej+Y6/GI7RHR1jfekxMDduJb7Rsol5VdqVHBalIS4Wg6T
5n2UKEe60nBj4zXr/6Arc/fI8b7r3dD2STuKdwyYwGKZbzxk/E5BznL79TOLvGC5
qnadWKZdWmI+rtvVireF6GkCAwEAAQ==
-----END PUBLIC KEY-----`

const privateKey =
  `-----BEGIN RSA PRIVATE KEY-----
MIIJKQIBAAKCAgEAugvbAIBaxnic84LDTNZ6w3AqvRSZcFVl/XTodTRtrTASyaay
4JxwjTDSofa0XtNPgKPLFLImrayvoNvILXJzjczJEO5H3ho1u5yj/4eFhN0fcTzY
lDuurpg9mQQFvmJM+un7oF46krO7/m+3c4bRHF5aUHpYF5uKK8dZeICj6hqB261a
PDqbQarn8XR2D3xoNeSoJ21RJHA43o8XQk+bi52grnKUoG5bVbZPDSZI5RY5+I23
pkzOmZXQkfdDEZ9CtvkNpM3Xt7RNQ/A8Oxd42E4M68apKoIX6f8o5wvzlQSAV/1R
4wbkxr4rRMf7kk0w49levmc5IWocFSDDmQah06D/9pprYzO+hdmUkoOptV6NOrv8
xayVGGUTcj6zT11InqKWvFLXFaBik3Uxp/cV4iGSGoRsqLAcaQ2RnLwEK217nKaE
upegDVx7bG4wi/r2RLK3HZqAwS7KECH88XNckMxrSngZQsKnhTGK0ZeJaMeIecOp
kWOWC2UCsPLUOVo8CG//5CQFksq4fD0TJfHDiTycbcLc4fKenQ2KoLGvKTTDjBPT
Lcbsb0LjO63bruTi+b4WRbX9knPfQNo+YxR1bOQBGj8mlew8TDoH4U5G4G9ykKxa
KxM0GZPvfTd6oRbaOF6mXvb/N6SyMqWZ8DPCtP+6A/igpD2rF+7R3DqCTUMCAwEA
AQKCAgBa+N0Xu6unjObCM9inw+C3nCtp1Z7JzRhX/UiFtyNCIlWtgPPK3PQ0NgFo
7Sd5jr47Wtw8vHgpSF2AvLd/cY2Lcu2Gd91wf0Ry/r86fdwOAcoUJvqIUT6zZQIJ
xmvAknSt5KgD3M+rWHO+kIYV2YB67LAg1hIpSQchLRNU1yJPoLKK5pNPDCFK1PUh
nh70p5KVd7+5t7yLosxMF4/5/s9CT8t4QdLnN881dDXnaicOzfV+0l1Q1hHYC+e2
2D9VaJjIu7OuOHEZBPMbG2d4RZvzuQaca8HgZUioRPqNL8zluxO+8sGNz5sii27A
7UDYYyJa57lqRTMsYaRluGWseA+8hHNpK/R0V9+V6pKBplxjPKhD6aIdgieGYnHW
FFFMkVXXGkxda3y7mPDAyOtgoCwrnpA/pNx8NiU7Q3s+s8T/mfEoLpy02j+hpEMN
6qzVIxRN3Y2ZV3Mu0dGgeKfbxOP0watgBD8HN/E/RDnQ2n42XNOOxJwr5YxOjhAg
U7mnZzG42C49T1r9mcTRIPu6SpgUalfpcYHtXEfsRD7KUrqAvp2+g8QkHTwKRQOj
JTnW5HbpiOF5EY9nGt/3ufA0HC4Fg1/K2reBzuwzKC6HOtT24ManySXjTR7KCaPL
5E0dwTWFzfpRsEhLnpBe9Kx5btHFw4I4TOAlmAXwz6bvVUHmMQKCAQEA3YGhAoUB
O4BI+bwaaLw9kKJ9mkZsMCOHvF16vQPA4L8Z9elFVfUq5vr27KfTQFvZM24tZMCW
IzbewOq/EU0/VTwRiyYfkwpFy56CtRHP6A7ZtMjhlN6K5hbeFbZ+EVkhaMdEzo40
STogMb4f/vBFOjYIGkg8bDnnlHvSlGSifMx6gmIazgCgm76NIDksC+ylTpt7vX5L
y5V3qhevm4HImFRNDuc8HbWYkib1tuRPkttqcJF7tUYg3VIX/xI+Aj+r7nqcap7B
2BmkxJvcb1dqzkx8CJMpHEMj/8GqcMJ7dyuGpeVxXh7PPiURHbm6cckUDieUta8z
FFQXVbddyo0njwKCAQEA1wSbvNNqYxDtuRDh3cBR8kje9LRsVjv5SvjHUZFih1GD
6BGH/RKvpM66u4kp2+Yd/4IoOtkaZ/I7EElZ0PdIYvNlVswJWQD8f6ZuDA0Eg7C4
k9juD8p0vwW27TqsGGjP7OoRZHdURS2/k8UtCDy4i6k8BoC5BESKfvmAL3rDpE8S
olsaNwmWdAqruSHIYeXqqytNXcaCnGSL2QwoLeWEU6e/dma6ezcQ91a0vhienncY
b3Ik75HH0F0comwuLMtx7O9tRh/YgDhNaLkEXVwvfg0KRbD0rf1IyKYeQD+huxKj
cTUdoqOBD/CbmlmOASeXBxPFLV7db70GZqZpspaFDQKCAQEAtRD10N1weHcRMDO3
Us4tdUGecrIGVgN1PWEcp82dqRoN2pro9q8GdvHHTwSRBxcNoaD6slVLZVXBGOnL
bhU7JC2KGbj8LnJDQ4M7TVBWJ1ecVs+utsaDvDoNKKqYuw2k5cWeVUqhKwq8rmzA
MuGtdTwdu345Bi7/Qr1FEdCBkoqDynjveF3C7A0Dx8d0Xfr/1hX7PJc01iJEQ2JB
Rlj85KhkpT4YQiDjqvT1/p76/Q0nIQWLnk7JJqGow/Thg20Q2kyX9//2bPPpYF4X
/1vyDtkuusOw401u6+4fqTURQATweEPqPAnrVt1t9GKSKdk476kjQrEIjCGBQaw2
mxBvuQKCAQBjgwt80a/MF5qd6xVNpuOGKXmecR4uruUKcKb2tj2OeQh+UBbK5moJ
8W90ud94mUDK7vclfTL+Zpo1SG1cDAYhXZpKGHY/DfjOLz2BMEkllZVnP5+2CTIM
8l0d3+ikB2YrtIzGHYAP4ENo5XmY9VlHsdRJAiwesIYUi+5qivuewMXUBCvWywYp
wIKHVP5DaMA55fmRa/0o2lPq6PhzfWTHvrwa1SJ7QhoFDsjprcVDe8UAVyiHA8Yh
ReapuT2Jl8KrMIg3SyfM98HDtNUzTCXixbA6XtJu7+lOi6s2evyj8tVX4IhNrDRg
JzAsuEPDfwojyV+ZRu1e+HHM8jm64HTFAoIBAQC8ClP3IqS7PFjjf9bb+il+3ED9
t/W8xr/rpXBeSZpcnDmPkO1S+TwPa+rkdX4izR+TKjOQV3RIJ7Wm7QX3PWppVMTB
9gWBebp+EIdHV+4YLyGLddFrlspawnvSKgUW0mduwGaivJJ3I33zl2CpdhneEtTB
NzqC/Q5GH53GB2ixHP5SjktkH9LqtSY15vp64k18SEsU1dRty5dWR+BpcTIXiWgw
kLfiB6EsSUON4ipi5dx6zcRX9HNfyekaDtnfTw4sfKU40h8T3cjjAAY+D+x1Y9jY
F8NmqM0NNBiJkqaAPTSqo4V1v60B/UcOcD8BEK1SULAV3wX125YDnY+ZsdQF
-----END RSA PRIVATE KEY-----`

export function encriptarTrama(tramaEntrada) {
  var rsa_ = new rsa();
  rsa_.setPublicCert(publicKey);
  return rsa_.encrypt_b64(tramaEntrada);
}

export function encriptarContrasenia(contrasenia) {
  return sha512(contrasenia);
}

export const desencriptarRsa = trama => {
  try {
    var rsa_ = new rsa();
    rsa_.setPrivateCert(privateKey);
    return rsa_.decrypt(trama);
  } catch (error) {
    return "error";
  }
};

export function desencriptarTramaAES(tramaEntrada, key, iv) {
  try {
    let llave = desencriptarRsa(key);
    let cuatro = desencriptarRsa(iv);
    llave = llave.replace(new RegExp('"', "g"), "");
    cuatro = cuatro.replace(new RegExp('"', "g"), "");
    var noencriptado = decryptAES(tramaEntrada, llave, cuatro);
  } catch (error) {
    console.log(error);
  }
  return JSON.parse(noencriptado);
}


function decryptAES(data, key, iv) {
  var crypto = require("crypto");
  var decipher = crypto.createDecipheriv("aes-256-cbc", key, iv),
    buffer = Buffer.concat([
      decipher.update(Buffer.from(data, "base64")),
      decipher.final()
    ]);
  return buffer.toString();
}


