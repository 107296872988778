import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';

const ShowOnSeccionWrapper = ({ children, name, showOn }) => {
  const values = useWatch({ name: showOn?.fields });

  const [isShowing, setIsShowing] = useState(false);
  useEffect(() => {
    try {
      if (typeof showOn?.custom === 'function') {
        setIsShowing(showOn.custom(values));
      } else if (values) {
        if (typeof values === 'object') {
          setIsShowing(
            Object.entries(values)
              .map(([fieldName, value]) =>
                _.isEqual(showOn?.entries?.[fieldName], value),
              )
              .every((value) => value === true),
          );
        }
      }
    } catch (error) {}
  }, [name, showOn, showOn.custom, showOn.entries, values]);

  return isShowing ? children : null;
};

export default ShowOnSeccionWrapper;
