import { HeaderLogo } from "components/Header/HeaderLogo";
import { TextInput } from "components/Inputs/inputs";
import { emailRegex } from "components/Inputs/validaciones";
import withValidarCambioContrasenia from "HOC/withValidarCambioContrasenia";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { isLoading, stopLoading } from "redux/actions/uiActions";
import { consumirServicioEncirptacion } from "services/api";
import { ambienteAplicacion, urlForgotPassword } from "services/urls";
import { getQueryParams } from "utils/functions";
import "./forgot-password.scss";

const ForgotPassword = () => {
  const methods = useForm({ mode: "onChange" });

  const history = useNavigate();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { expires } = getQueryParams();

  const onSubmit = async ({ email }) => {
    dispatch(isLoading());

    const Data = {
      cliEmail: email,
      ambiente: ambienteAplicacion,
      from: "front",
    };
    try {
      const result = await consumirServicioEncirptacion(
        urlForgotPassword,
        Data
      );
      if (result && result.transaccion) {
        addToast(t("I_FRONT_36"), {
          appearance: "success",
        });
        history("/");
      } else {
        addToast(result?.mensaje || t("I_FRONT_46"), {
          appearance: "warning",
        });
      }
    } catch (err) {
      addToast(err, {
        appearance: "error",
      });
    }
    dispatch(stopLoading());
  };

  const onSubmitError = (values) => {
    //Submit error
  };
  useEffect(() => {
    expires &&
      addToast(t("I_FRONT_37"), {
        appearance: "warning",
      });
  }, [expires, addToast, t]);

  return (
    <>
      <div className="row mx-3 mt-3 ">
        <HeaderLogo />
      </div>
      <div className="d-flex justify-content-center recover-page mt-3">
        <div className="container mb-5 card-recover">
          <div className="p-5 border">
            <div className="row">
              <center>
                <div className="col-12">
                  <h3>{t("I_FRONT_4")}</h3>
                </div>
                <div className="col-12">{t("I_FRONT_7")}</div>
              </center>
            </div>
            <FormProvider {...methods}>
              <form
                noValidate
                autoComplete="off"
                onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}
              >
                <div className="row mt-5">
                  <h5>{t("Email")}</h5>
                  <TextInput
                    extrasClasses={"login-input input-login-form"}
                    inputProps={{
                      type: "email",
                      name: "email",
                      placeholder: "juan.ma@aig.com",
                      autoComplete: "off",
                    }}
                    rules={{
                      required: t("W_FRONT_12"),
                      pattern: {
                        value: emailRegex,
                        message: t("E_FRONT_63"),
                      },
                    }}
                  />
                </div>
                <div className="col-12 d-flex justify-content-center mt-3">
                  <button
                    id="btn-login-ingresar"
                    className="btn btn-primary recover-btn"
                    type="submit"
                  >
                    {t("I_FRONT_8")}
                  </button>
                </div>
              </form>
            </FormProvider>
            <div className="col-12 d-flex justify-content-center mt-1">
              <Link to="/login">{t("I_FRONT_9")}</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withValidarCambioContrasenia(ForgotPassword);
