import React from "react";

import logo from "../../assets/img/logo-page.png";

export default ({ mainText = '', secondaryText = ''}) =>
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 50, marginBottom: 50 }}>

    {/*     <div style={{ margin: 'auto' }}>
            <img
            style={{width:200}}
                id="d"
                alt="Aig"
                src={logo}
            />
        </div> */}
        <div style={{ textAlign: 'center', marginTop: 50 }}>
            <h3>
                {mainText}
                {/* Lo sentimos!! No hemos podido encontrar la página que está buscando. */}
        </h3>
            <h4> {secondaryText} </h4>
        </div>

    </div>;


