import { Navbar } from "components/Navbar/Navbar";
import useHistory from "hooks/useHistory";
import React, { useEffect, useRef } from "react";
import IdleTimer from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router";
import { logout } from "redux/actions/loginActions";
import { Loading } from "../pages/Loading/Loading";

const NO_LOGIN_PAGES = ["/forgot-password", "/invalid-token","/actualizar-contrasenia"];

const Layout = () => {
  const { dataUsuario } = useSelector((state) => state.login);
  const navigate = useHistory();
  const location = useLocation();
  const { loading, timeSession } = useSelector((state) => state.ui);
  const { isLoading } = useSelector((state) => state.product);
  const idleTimerSession = useRef(null);
  const dispatch = useDispatch();

  const onIddle = () => {
    if (dataUsuario?.userIsLoggedIn) {
      dispatch(logout());
    }
  };

  const pathname = location.pathname;
  useEffect(() => {
    if (!dataUsuario?.userIsLoggedIn && !NO_LOGIN_PAGES.includes(pathname)) {
      navigate("/login");
    }
    if (pathname === "/login" && dataUsuario?.userIsLoggedIn) {
      navigate("/");
    }
  }, [dataUsuario?.userIsLoggedIn, pathname, navigate]);

  return (
    <React.Fragment>
      <IdleTimer
        ref={idleTimerSession}
        timeout={timeSession * 1000}
        onIdle={onIddle}
      >
        {!isLoading && <Navbar />}
        {loading && <Loading />}
        {!loading && <Outlet />}
      </IdleTimer>
    </React.Fragment>
  );
};

export default Layout;
