import React from 'react'
import { Link } from 'react-router-dom';
import imgLogo from '../../assets/img/logo-page.png';


export const HeaderLogo = () => {
    return (
        <div className="row mt-3 col-12">
            <div className="col-12">
                <Link to="/">
                    <img src={imgLogo} alt="" style={{ width: '250px' }} />
                </Link>
            </div>
        </div>
    )
}
