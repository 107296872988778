import React from 'react'
import { Button, Modal } from 'react-bootstrap';


export const Confirmation = ({ showConfirmacion, setShowConfirmacion, texto, onContinuar }) => {
    return (
        <Modal show={showConfirmacion} centered >
            <Modal.Header>
                <Modal.Title className="w-100">
                    <div className="d-flex justify-content-lg-between justify-content-center flex-wrap">
                        <div className="my-auto">Confirmation</div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{texto}</Modal.Body>
            <Modal.Footer className="col-12">
                <div className="row col-12 d-flex justify-content-center">
                    <Button className="mx-3 col-4" variant="primary" onClick={() => onContinuar()}>
                        YES
                    </Button>
                    <Button className="mx-3 col-4" variant="primary" onClick={() => setShowConfirmacion(false)}>
                        NO
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
