/* eslint-disable react-hooks/exhaustive-deps */
import { SimpleErrorMessage } from "components/Inputs/inputs";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import useCustomToasts from "../../../../hooks/useCustomToast";
import Campos from "../Campos";
import _ from "lodash";
import useCustomRef from "hooks/useCustomRef";
import { InputNumber } from "primereact/inputnumber";
import { SimpleContainer } from "components/Inputs/containers";
import { InputText } from "primereact/inputtext";
export const TextoQuestion = ({
  extrasClasses,
  placeHolder,
  containerProps,
  preload = false,
  name = "",
  rules,
  isUpper = false,
  onChangeFn,
  tipoProducto = '',
  type = "text",
  label = "",
  subCampos,
  disabled = false,
  options = [],
  defaultValue,
  ...rest
}) => {


  const { t } = useTranslation();

  const methods = useFormContext({ mode: "onChange" });
  const selected = methods.watch(name);
  const [typePassword, setTypePassword] = useState(true);
  const { addWarningToast } = useCustomToasts();

  useEffect(() => {
    if (rules?.isValue) {
      if (selected && rules?.isValue?.value === "true") {
        addWarningToast(rules?.isValue.message, "warning");
      }
    }
  }, [selected, t]);

  const value = methods.watch(name);
  const newSubCampos = useMemo(
    () =>
      subCampos
        ?.filter?.((item) => item?.whenValue === value)
        ?.map?.((item) => ({
          ...item,
          name: `${name}Fields.${item.name}`,
          containerProps: {
            ...(item?.containerProps || {}),
            name: `${name}Fields.${item.name}`,
          },
        })) || [],
    [value]
  );

  const htmlMessage = (mssg) => {
    return (
      <div
        style={{
          // fontSize: "1rem",
          fontFamily: "Futura-Medium",
          opacity: disabled && "0.6",
        }}
        dangerouslySetInnerHTML={{
          __html: mssg,
        }}
      />
    );
  };
  let yes = "Yes";
  let no = "No";
  if (options.length > 0) {
    yes = options[0].label ?? "Yes";
    no = options[1].label ?? "No";
  }
  const { control } = useFormContext();
  const { focusRef, setRef } = useCustomRef();


  return (
    <div className="col-md-12 d-flex flex-wrap mt-5 justify-content-around">
      <div className="col-md-8 col-sm-12">
        {htmlMessage(label)}
      </div>

      <div className="col-md-4 col-sm-12">
        <SimpleContainer {...containerProps} label="">

          <Controller
            control={control}
            name={name}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, ref } }) => (
              <div className=' row col-12 d-flex'>
                <InputText
                  ref={ref}
                  className={`${extrasClasses} col${type !== "password" ? "-12" : "-8"
                    } shadow-none`}
                  value={value || ""}
                  placeholder={placeHolder}
                  onChange={(e) => {
                    if (isUpper) {
                      onChange(e.currentTarget.value.toUpperCase());
                    } else {
                      onChange(e.currentTarget.value);
                    }
                    onChangeFn && onChangeFn(e.currentTarget.value);
                  }}
                  name={name}
                  style={{
                    border: "none",
                    outline: "none",
                    fontFamily: "SourceSansPro-Regular",
                  }}
                  type={typePassword ? type : "text"}
                  {...rest}
                  disabled={tipoProducto === 'fastTrack' ? false : disabled}
                />
                {type === "password" && (
                  <div className='p-0 col-4 d-flex justify-content-end align-items-center'>
                    <Button
                      type='button'
                      style={{ borderRadius: "25%" }}
                      className='p-button-text p-button-plain font-20px shadow-none mx-3'
                      onClick={() => {
                        setTypePassword(!typePassword);
                      }}>
                      <i
                        className={typePassword ? "pi pi-eye" : "pi pi-eye-slash"}
                        style={{ fontSize: "1rem" }}
                      />
                    </Button>
                  </div>
                )}
              </div>
            )}
          />

        </SimpleContainer>
      </div>



      {newSubCampos && newSubCampos?.length > 0 && (
        <div className="col-12">
          <div className="row justify-content-center">
            <div className="col-11">
              <div className="row">
                <Campos campos={newSubCampos} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
