import { useState } from 'react';
import { consumirServicioEncirptacion } from 'services/api';
import { urlGetCatalogoUbicacion } from 'services/urls';

const useFindMunicipalities = () => {
    const [municipalities, setMunicipalities] = useState([]);

    const findMunicipalities = async (codCountry, setLoading) => {
        if (codCountry) {
            setLoading(true);
            const Data = {
                selectedType: 'country',
                params: {
                    paiCodigoCore: codCountry
                },
            };
            try {
                console.log(Data);
                const res = await consumirServicioEncirptacion(urlGetCatalogoUbicacion, Data, false, true);
                const data = res?.data?.collections?.map?.(municipality => {
                    return {
                        value: municipality,
                        label: municipality?.provNombre
                    }
                }) || [];
                setMunicipalities(data);
                setLoading(false);
                return data;
            } catch (error) {
                setLoading(false);
            }
        }
        return [];
    };

    return { findMunicipalities, municipalities, setMunicipalities };
};

export default useFindMunicipalities;
