/* eslint-disable react-hooks/exhaustive-deps */
import { SimpleErrorMessage } from "components/Inputs/inputs";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import normalizeText from "normalize-text";
import useCustomToasts from "../../../../hooks/useCustomToast";
import Campos from "../Campos";
import _ from "lodash";
import useCustomRef from "hooks/useCustomRef";
import { InputNumber } from "primereact/inputnumber";
import { SimpleContainer } from "components/Inputs/containers";
import { InputText } from "primereact/inputtext";
import { ComboDatePicker } from "components/Inputs/ComboDatePicker";
export const DateQuestion = ({
  extrasClasses,
  placeHolder,
  containerProps,
  preload = false,
  name = "",
  rules,
  isUpper = false,
  onChangeFn,
  tipoProducto = '',
  type = "text",
  label = "",
  subCampos,
  disabled = false,
  options = [],
  outFormat = "yyyy-MM-DD",
  defaultValue = null,
  maxDate = new Date(),
  minDate = null,
  
  ...rest
}) => {


  const { t } = useTranslation();

  const methods = useFormContext({ mode: "onChange" });
  const selected = methods.watch(name);
  const [typePassword, setTypePassword] = useState(true);
  const { addWarningToast } = useCustomToasts();
  const [, setInternal] = useState(null);

  useEffect(() => {
    if (rules?.isValue) {
      if (selected && rules?.isValue?.value === "true") {
        addWarningToast(rules?.isValue.message, "warning");
      }
    }
  }, [selected, t]);

  const value = methods.watch(name);
  const newSubCampos = useMemo(
    () =>
      subCampos
        ?.filter?.((item) => item?.whenValue === value)
        ?.map?.((item) => ({
          ...item,
          name: `${name}Fields.${item.name}`,
          containerProps: {
            ...(item?.containerProps || {}),
            name: `${name}Fields.${item.name}`,
          },
        })) || [],
    [value]
  );

  const htmlMessage = (mssg) => {
    return (
      <div
        style={{
          // fontSize: "1rem",
          fontFamily: "Futura-Medium",
          opacity: disabled && "0.6",
        }}
        dangerouslySetInnerHTML={{
          __html: mssg,
        }}
      />
    );
  };
  let yes = "Yes";
  let no = "No";
  if (options.length > 0) {
    yes = options[0].label ?? "Yes";
    no = options[1].label ?? "No";
  }
  const { control } = useFormContext();
  const { focusRef, setRef } = useCustomRef();


  return (
    <div className="col-md-12 d-flex flex-wrap mt-5 justify-content-around">
      <div className="col-md-9 col-sm-12">
        {htmlMessage(label)}
      </div>

      <div className="col-md-3 col-sm-12">
        <SimpleContainer {...containerProps} label="">

          <Controller
            control={control}
            name={name}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, ref } }) => (
              <div className=' row col-12 d-flex'>
                <ComboDatePicker
                  id={name}
                  name={name}
                  date={value ? new Date(value):null}
                  maxDate={maxDate}
                  months={`${t("January")},${t("February")},${t("March")},${t("April")},${t(
                    "May"
                  )},${t("June")},${t("July")},${t("August")},${t("September")},${t(
                    "October"
                  )},${t("November")},${t("December")}`}
                  placeholder={[t("Year"), t("Month"), t("Day")]}
                  order='mdy'
                  subref={setRef}
                  onChange={(_picker, date) => {
                    setInternal(date);
                    if (
                      "invalid date" !== normalizeText(String(date)).toLowerCase() &&
                      "fecha invalida" !== normalizeText(String(date)).toLowerCase() &&
                      date !== null &&
                      date !== "" &&
                      date !== undefined
                    ) {
                      onChange(date);
                    } else {
                      onChange(null);
                    }
                  }}
                  attrsDate={{ disabled: rest?.disabled }}
                  attrsMonth={{ disabled: rest?.disabled }}
                  attrsYear={{ disabled: rest?.disabled }}
                  {...rest}
                />
              </div>
            )}
          />

        </SimpleContainer>
      </div>



      {newSubCampos && newSubCampos?.length > 0 && (
        <div className="col-12">
          <div className="row justify-content-center">
            <div className="col-11">
              <div className="row">
                <Campos campos={newSubCampos} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
