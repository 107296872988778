export const htmlMessage = (mssg, disabled = false) => {
  return (
    <div
      style={{
        // fontSize: "1rem",
        fontFamily: "Futura-Medium",
        opacity: disabled && "0.6",
      }}
      dangerouslySetInnerHTML={{
        __html: mssg,
      }}
    />
  );
};
