import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import queryString from 'query-string';
import { TextInput } from 'components/Inputs/inputs';
import { emailRegex } from 'components/Inputs/validaciones';
import { format } from 'helpers/functions';
import { useFormContext } from "react-hook-form";
import useCustomToast from 'hooks/useCustomToast';
import { ambienteAplicacion, urlSendEmail, urlGenerarPDF, empresa } from 'services/urls'
import { consumirServicioEncriptacionGCM } from 'services/api'
import { useLocation } from 'react-router';
import { DynamicSummary } from 'components/DynamicSummary/DynamicSummary';


export const Summary = ({ product = {}, products = null, payment = {}, urlPdf = '', onChange, onClick: onSubmit, planSel = null }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const qParams = queryString.parse(location.search);
    const [loading, setLoading] = useState(false);
    const { addWarningToast, addSuccessToast } = useCustomToast();
    const [generarPDF, setGenerarPDF] = useState(false)
    const methods = useFormContext();
    const email = methods.watch('email');

    const downloadPDF = async (idProduct = product?.prodCodigoCore) => {
        setGenerarPDF(true);
        try {
            const body = {
                empresa,
                prodCodigoCore: idProduct || '',
                formCodigoCore: "default",
                pasoCodigo: "generate_pdf_quote",
                idCarrito: qParams?.id

            }
            debugger;
            const resp = await consumirServicioEncriptacionGCM(urlGenerarPDF, body, true, true);
            if (!!resp?.transaccion && resp?.pdfPolicy) {
                window.open(resp?.pdfPolicy, '_blank');
            } else {
                addWarningToast(t('I_FRONT_45'));
            }
        } catch (error) {

        }
        setGenerarPDF(false)
    }

    const sendEmail = async (idCarrito = qParams?.id) => {
        debugger;
        setLoading(true);
        try {
            if (methods?.formState?.errors?.email?.message) {
                addWarningToast(methods?.formState?.errors?.email?.message);
            } else if (email?.length <= 3) {
                addWarningToast(t('E_FRONT_63'))
            } else {
                const bodyPDF = {
                    empresa,
                    prodCodigoCore: product?.prodCodigoCore || '',
                    formCodigoCore: "default",
                    pasoCodigo: "generate_pdf_quote",
                    idCarrito: idCarrito

                }
                const respPDF = await consumirServicioEncriptacionGCM(urlGenerarPDF, bodyPDF, true, true);
                if (!!respPDF?.transaccion && respPDF?.pdfPolicy) {

                    const body = {
                        ambiente: ambienteAplicacion,
                        idCotizacion: qParams?.id || null,
                        url: respPDF?.pdfPolicy || urlPdf,
                        destinatario: email
                    }
                    const resp = await consumirServicioEncriptacionGCM(urlSendEmail, body, true, false);
                    if (resp?.transaccion) {
                        addSuccessToast(t('I_FRONT_64'));
                    } else {
                        addWarningToast(t('I_FRONT_46'));
                    }
                } else {
                    addWarningToast(t('I_FRONT_46'));
                }
            }
        } catch (error) {

        }
        setLoading(false);
    }
    return (
        <div className="col-lg-12 col-md-12 col-sm-12 d-flex flex-wrap justify-content-between py-1">
            {products ? <DynamicSummary catalogoService={products} onSubmit={onSubmit} downloadPDF={downloadPDF} sendEmail={sendEmail} onChange={onChange} planSelected={planSel} /> : <><div className=" row col-lg-6 col-md-12 col-sm-12  d-flex flex-wrap justify-content-center">
                <h4 className="text-center h-0">{t('I_FRONT_47') + ':'}</h4>
                <div className="col-12 border py-3 p-0">
                    <div className="col-12 font-20 text-center bg-lightblue ">
                        {t(product?.planNombre || 'Option1')}
                    </div>
                    <h4 className="text-center text-aigpr">{format(product?.planPrimaAnual) || '$0'}</h4>
                </div>
                <div className="row col-12 d-flex justify-content-center border pt-2 mt-3">
                    <div className="col-12 font-bolder p-2 border-bottom">
                        {t('I_FRONT_48')}
                    </div>
                    <div className="col-12 row d-flex border-bottom p-2">
                        <div className="col-4 d-flex justify-content-start">
                            {t('I_FRONT_38')}
                        </div>
                        <div className="col-4">
                            <div className="col-12 d-flex justify-content-center">
                                {t('I_FRONT_42')}
                            </div>
                            <div className="col-12 d-flex justify-content-center">
                                {t('I_FRONT_44')}
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="col-12 d-flex justify-content-end">
                                {format(product?.planLimitesResposabilidad[0]?.inferior)}
                            </div>
                            <div className="col-12 d-flex justify-content-end">
                                {format(product?.planLimitesResposabilidad[0]?.superior)}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 row d-flex border-bottom pb-1">
                        <div className="col-4 d-flex justify-content-start">
                            {t('I_FRONT_39')}
                        </div>
                        <div className="col-4 d-flex justify-content-center">

                        </div>
                        <div className="col-4 d-flex justify-content-end">
                            {format(product?.planDeducibles)}
                        </div>
                    </div>
                    <div className="col-12 row d-flex border-bottom pb-1">
                        <div className="col-4 d-flex justify-content-start">
                            {t('I_FRONT_40')}
                        </div>
                        <div className="col-4 d-flex justify-content-center">

                        </div>
                        <div className="col-4 d-flex justify-content-end">
                            {format(product?.planPrimaAnual)}
                        </div>
                    </div>
                    <div className="col-12 row d-flex border-bottom pb-1">
                        <div className="col-4 d-flex justify-content-start">
                            {t('I_FRONT_41')}
                        </div>
                        <div className="col-4 d-flex justify-content-center">

                        </div>
                        <div className="col-4 d-flex justify-content-end">
                            {format(product?.planAssesmentFee)}
                        </div>
                    </div>
                </div>
            </div>

                <div className=" row col-lg-6 col-md-10 col-sm-12 border  d-flex flex-wrap justify-content-center p-3">
                    <h4 className="text-darkblue text-left">{t('I_FRONT_49')}</h4>
                    <div className=" col-12 d-flex justify-content-center pt-2 mt-3 ">
                        <div className="col-4 d-flex justify-content-start">
                            {t('I_FRONT_40')}
                        </div>
                        <div className="col-4 d-flex justify-content-center">

                        </div>
                        <div className="col-4 d-flex justify-content-end">
                            {format(payment?.premium)}
                        </div>
                    </div>
                    <div className=" col-12 d-flex justify-content-center pt-2 mt-3 ">
                        <div className="col-4 d-flex justify-content-start">
                            {t('I_FRONT_41')}
                        </div>
                        <div className="col-4 d-flex justify-content-center">

                        </div>
                        <div className="col-4 d-flex justify-content-end">
                            {format(payment?.assessment_fee)}
                        </div>
                    </div>
                    <div className=" col-12 d-flex justify-content-center pt-2 mt-3 mb-5 ">
                        <div className="col-4 d-flex justify-content-start">
                            <h4 className="text-darkblue text-left">{t('I_FRONT_50')}</h4>
                        </div>
                        <div className="col-4 d-flex justify-content-center">

                        </div>
                        <div className="col-4 d-flex justify-content-end">
                            <h4>{format(payment?.total)}</h4>
                        </div>
                    </div>
                    <div className=" col-12 d-flex justify-content-center pt-2 mb-3 ">
                        <div className="col-6 p-1" >

                            <Button
                                className={`mx-auto text-center w-100 outline-aigpr col`}
                                onClick={() => { downloadPDF() }}
                                disabled={generarPDF}
                            >
                                <span className="text-wrap">{(generarPDF) ? t('I_FRONT_52') : t('I_FRONT_51')}</span>
                            </Button>
                        </div>

                    </div>
                    <div className="text-darkblue col-12 d-flex justify-content-between pt-2 mb-3 ">
                        {t('I_FRONT_76')}
                    </div>
                    <div className="text-darkblue col-12 d-flex justify-content-between pt-2 mb-3 ">
                        <TextInput
                            extrasClasses={'col-6'}
                            inputProps={{
                                type: 'email',
                                name: 'email',
                                autoComplete: 'off',
                            }}
                            rules={{
                                required: t('W_FRONT_62'),
                                pattern: {
                                    value: emailRegex,
                                    message: t('E_FRONT_63'),
                                },
                            }}

                        /><Button
                            className={`mx-auto text-center  w-100 btn-aigpr col`}
                            onClick={() => sendEmail()}
                            disabled={loading}
                        >
                            <span className="text-wrap">{(loading) ? t('I_FRONT_55') : t('I_FRONT_54')}</span>
                        </Button>
                    </div>
                </div></>}

        </div>
    )
}
