import { useGeneradorPagina } from "../../../../pages/GeneradorPaginas/store";
import { NumberInput, SimpleErrorMessage } from "components/Inputs/inputs";
import moment from "moment";
import React, { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { SimpleContainer } from "components/Inputs/containers";
import Campos from "../Campos";
import { InputNumber } from "primereact/inputnumber";
import { values } from "lodash";
import { InputText } from "primereact/inputtext";
import { Col, Row } from "react-bootstrap";

const ID_ATTR = "UNIQUE_ID_FOR_INTERNAL_OPERATIONS";

/**
 *
 * @param {{campo:import("components/DynamicForm/typedefs").Campo}} props
 * @returns
 */
const TablaOptionsGrossLawyers = ({ campo }) => {
  const [showModal, setShowModal] = useState(false);
  const { pageState } = useGeneradorPagina();
  const toggle = () => {
    methods.reset({
      [ID_ATTR]: null,
      name: pageState?.["policyFormData"]?.value?.usuNombre || "No Name",
    });
    setShowModal(!showModal);
  };

  const methods = useForm({ mode: "onChange" });

  const opciones = [
    {
      inputA: `1. Negligence plainiff's attorney`,
      valueA: `NEGLIGENCE_PLAINIFFS_ATTORNEY`,
      inputB: "11. S.E.C / Securities",
      valueB: "SEC_SECURITIES",
    },
    {
      inputA: `2. Negligence defendant's attorney`,
      valueA: "Negligence_defendant_s_attorney",
      inputB: "12. Domestic Relations",
      valueB: "Domestic_Relations",
    },
    {
      inputA: "3. Entertainment",
      valueA: "Entertainment",
      inputB: "13. International Law",
      valueB: "International_Law",
    },
    {
      inputA: "4. Anti-trust",
      valueA: "Anti_trust",
      inputB: "14. Tax",
      valueB: "Tax",
    },
    {
      inputA: "5. Real estate",
      valueA: "Real_estate",
      inputB: "15. Criminal",
      valueB: "Criminal",
    },
    {
      inputA: "6. Estates & Trusts",
      valueA: "Estates_&_Trusts",
      inputB: "16. Bankruptcy",
      valueB: "Bankruptcy",
    },
    {
      inputA: "7. Patents, trademarks and copyright",
      valueA: "Patents_trademarks_and_copyright",
      inputB: "17. Admiralty",
      valueB: "Admiralty",
    },
    {
      inputA: "8. Corporation Law",
      valueA: "Corporation_Law",
      inputB: "18. Labor",
      valueB: "Labor",
    },
    {
      inputA: "9. Municipal Law",
      valueA: "Municipal_Law",
      inputB: "19. Syndication",
      valueB: "Syndication",
    },
    {
      inputA: "10. Commercial Matter",
      valueA: "Commercial_Matter",
      inputB: "20. Other",
      valueB: "OTHER",
    },
  ];

  const _onSubmit = (field) => (formData) => {
    Object.entries(formData).forEach(([key, value]) => {
      if (typeof value === "object" && value instanceof Date) {
        formData[key] = moment(value).format("MM/DD/YYYY");
      }
      return true;
    });
    if (formData[ID_ATTR]) {
      field.value = field.value.filter(
        (item) => item[ID_ATTR] !== formData[ID_ATTR]
      );
      formData[ID_ATTR] = uuidv4();
      field.value.push(formData);
    } else {
      formData[ID_ATTR] = uuidv4();
      field.value.push(formData);
    }
    field.onChange(field.value);
    toggle();
  };

  const htmlMessage = (mssg) => {
    return (
      <div
        style={{
          // fontSize: "1rem",
          fontFamily: "Futura-Medium",
        }}
        dangerouslySetInnerHTML={{
          __html: mssg,
        }}
      />
    );
  };
  return (
    <Controller
      name={campo.name}
      defaultValue={[]}
      rules={campo.rules}
      render={({ field: { value, onChange, ref } }) => (
        <div className="col-12 my-5">
          <div className="d-flex mb-3">
            <div className="col-md-8 col-sm-12">{htmlMessage(campo.label)}</div>
          </div>

          <table className="table table-bordered table-responsive-lx table-responsive-l table-responsive-md">
            <tbody>
              {opciones.map((opcion) => {
                return (
                  <tr>
                    <th className="thead-center">{opcion.inputA}</th>
                    <th className="thead-center">
                      <Row>
                        <Col md={10}>
                          <SimpleContainer
                            {...campo.subCampos[0].containerProps}
                            disabled={false}
                          >
                            <InputNumber
                              ref={ref}
                              value={value[opcion.valueA]}
                              onChange={(e) => {
                                const valueAux = { ...value };

                                valueAux[opcion.valueA] = e.value;

                                var suma = 0;

                                for (const key in valueAux) {
                                  if (key != "TOTAL") {
                                    suma += valueAux[key];
                                  }
                                }

                                valueAux["TOTAL"] = suma;

                                onChange(valueAux);
                              }}
                              name={opcion.valueA}
                              style={{
                                border: "none",
                                outline: "none",
                                fontFamily: "SourceSansPro-Regular",
                              }}
                              type={"text"}
                            />
                          </SimpleContainer>
                        </Col>
                        <Col md={2} className="thead-center">
                          <br />%
                        </Col>
                      </Row>
                    </th>
                    <th className="thead-center">{opcion.inputB}</th>
                    <th>
                      <Row>
                        <Col md={10}>
                          <SimpleContainer
                            {...campo.subCampos[0].containerProps}
                            disabled={opcion.disabled}
                          >
                            <InputNumber
                              ref={ref}
                              disabled={opcion.disabled}
                              value={value[opcion.valueB]}
                              onChange={(e) => {
                                const valueAux = { ...value };

                                valueAux[opcion.valueB] = e.value;

                                var suma = 0;

                                for (const key in valueAux) {
                                  if (key != "TOTAL") {
                                    suma += valueAux[key];
                                  }
                                }

                                valueAux["TOTAL"] = suma;

                                onChange(valueAux);
                              }}
                              name={opcion.valueB}
                              style={{
                                border: "none",
                                outline: "none",
                                fontFamily: "SourceSansPro-Regular",
                              }}
                              type={"text"}
                            />
                          </SimpleContainer>
                        </Col>
                        <Col md={2} className="thead-center">
                          <br />%
                        </Col>
                      </Row>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className="w-100">
            <SimpleErrorMessage name={campo.name} />
          </div>
        </div>
      )}
    />
  );
};

export default TablaOptionsGrossLawyers;
