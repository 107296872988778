import { combineReducers } from "redux";
import { loginReducer } from "./loginReducer";
import { productsReducer } from "./productsReducer";
import { uiReducer } from "./uiReducer";

/**
 *
 * @param {object} asyncReducers
 * @returns {object}
 */
export const createReducer = (asyncReducers) => {
  return combineReducers({
    login: loginReducer,
    ui: uiReducer,
    product: productsReducer,
    ...asyncReducers,
  });
};
