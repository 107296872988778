import { SimpleErrorMessage } from "components/Inputs/inputs";
import { Button } from "primereact/button";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller } from "react-hook-form";

/**
 *
 * @param {import("components/DynamicForm/typedefs").Campo} props
 * @returns
 */
const TermsConditions = (props) => {
  const [isShow, setShowModal] = useState(false);

  return (
    <React.Fragment>
      <div className='d-flex flex-row mt-3'>
        <Controller
          name={props.name}
          rules={props.rules}
          render={({ field }) => (
            <input
              type='checkbox'
              name={props.name}
              id={props.name}
              value={field.value}
              checked={field.value}
              className='align-self-center'
              onChange={(e) => {
                e.target.checked && !field.value && props.info!='' && setShowModal(true);
                field.onChange(e.target.checked);
              }}
            />
          )}
        />
        <label htmlFor={props.name} className='align-self-center ms-2'>
          {props.label}
        </label>

        <Modal
          show={isShow}
          onHide={() => setShowModal(false)}
          size='md'
          aria-labelledby='contained-modal-title-vcenter'
          centered>
          <Modal.Body>
            <Button
              className='float-end p-button-sm p-button-text p-button-secondary'
              icon='pi pi-times'
              onClick={() => setShowModal(false)}
            />
            <div className='m-4'>
              <div dangerouslySetInnerHTML={{ __html: props.info }} />
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div className='w-100'>
        <SimpleErrorMessage name={props.name} />
      </div>
    </React.Fragment>
  );
};

export default TermsConditions;
