import { types } from "../types/types";

export const dataUsuarioVacio = {
    correo: '',
    cedula: '',
    nombres: '',
    apellidos: '',
    sucursal: '',
    errorDescripcion: '',
    cliCodigoCore: '', //agregado cuando se necesito hacer publica la pagina de confirmacion final de pago
    userIsLoggedIn: false,
    isSuscriptor: false
};

const initialState = {
    dataUsuario: dataUsuarioVacio,
}

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOGIN:
            return {
                ...state,
                dataUsuario: { ...state.dataUsuario, ...action.payload },
                loginError: false

            }
        case types.LOGOUT:
            return {
                ...state,
                dataUsuario: { ...state.dataUsuario, userIsLoggedIn: false, cambioContrasenia: false },
                loginError: false

            }

        default:
            return state
    }
}